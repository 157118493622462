(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome
   * @description
   *
   */
  angular
    .module('itecTcqihome', [
      'ui.router',
      'itecApi',
      'itecTcqihome.obres',
      'itecTcqihome.compensacions',
      'itecTcqihome.configuracio',
      'itecTcqihome.api',
      'itecTcqihome.shared',
      'itecLocale',
      'itecGuicomponents.layout',
      'itecGuicomponents.commonControls',
      'itecGuicomponents.commonTcq',
      'ngDraggable',
      'ngCookies',
      'ngFileSaver',
      'sharedData'
    ])
    .config(function ($translatePartialLoaderProvider, $urlRouterProvider, $httpProvider) {
      $httpProvider.defaults.useXDomain = true;

      $translatePartialLoaderProvider.addPart('resources');
      $translatePartialLoaderProvider.addPart('vendor/itec-guicomponents/dist/resources/common-controls/resources');
   })
    .config(function($httpProvider) {
      $httpProvider.interceptors.push('LocaleInterceptorFactory');
      $httpProvider.interceptors.push('EntitatInterceptorFactory');
    })
	 .run(function($rootScope, LocalStorage, SessionStorage, DataFactory, ApiLayoutConstant, $mdDialog, $document, PreventUnsavedDataService, $state, ConfigService, PermisosFactory, AppFactory, $timeout, LocaleService, BrowserDetectorFactory){
    
      //Configuració per itec-guicomponents
      LocalStorage.setBasePath('itecTcqihome');
      SessionStorage.setBasePath('itecTcqihome');
      $rootScope.prefixItecGuicomponents = 'vendor/itec-guicomponents/dist/';
      //SessionStorage.save('idEntitatCompartida','');
      ConfigService.setEntitat('');
      //ConfigService.setEntitatcompartida('');
      var errorState = 'pagenotfound';

      PermisosFactory.setApiParameters(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'permisos', '');
      DataFactory.setApiParameters('tcqihome', ApiLayoutConstant.SERVER_VERSION_TCQIHOME, 'authtcqihome','');

      
      //Inicializamos el service para comprobar si en formularios tenemos
      //cambios sin guardar.
      PreventUnsavedDataService.init();

      var iniciCarrega;

      //Pedimos permisos (y creamos sesion y desbloqueamos la api)
      PermisosFactory.load().then(function(response){
        $rootScope.permisos = response.data.permisos;
        $rootScope.usuari = response.data.usuari;
        SessionStorage.save('idEntitat', response.data.idEntitat);
        ConfigService.setEntitat(response.data.idEntitat);
        LocaleService.setLocaleByIdioma($rootScope.usuari.idioma);
        BrowserDetectorFactory.check(response.data.browsers);
      }).catch(function(error){
        console.log(error);
        $state.go(errorState);
      }).finally(function(){
        DataFactory.unblockApi();
        AppFactory.setExistsSession(true);
      });

      DataFactory.blockApi();


      $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams, options) {
        //Consultamos si existe algún cambio en un formulario
        //pendiente sin guardar
        var dirty = PreventUnsavedDataService.existDirty();

        if(dirty){
          //Si existe algún cambio sin guardar se avisa al usuario
          //Cancelamos el evento
          e.preventDefault();

          //Consulto si ya tengo algún mensaje en progreso (en el caso que
          // me encuentre en un sub-estado de x niveles que provoca que se llame
          // a este evento x veces)
          if(!PreventUnsavedDataService.isInProgress()){
            PreventUnsavedDataService.enableInProgress();
            $rootScope.$broadcast('$scheduleDeactivateLoader');
            $mdDialog.show({
              controller: function DialogController($scope, $mdDialog, parametres, Notification, $translate, $rootScope, PreventUnsavedDataService) {
                var vm = this;
                vm.title = parametres.title;
                vm.text = parametres.text;

                PreventUnsavedDataService.enableInProgress();

                $scope.accept = function() {
                  //El usuario no quiere guardar los cambios

                  //Cierro el dialog
                  $mdDialog.hide();
                  //Vuelvo a activar el loader
                  $rootScope.$broadcast('$scheduleActivateLoader');
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();

                  //Limpiamos los formularios ya que no queremos guardar ningún cambio
                  PreventUnsavedDataService.cleanForms();

                  if (toState.name !== errorState) {
                    //Voy al estado que quería
                    $state.go(toState.name);
                  }

                };
                $scope.hide = function() {
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();
                  $mdDialog.cancel();
                };
                $scope.cancel = function() {
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();
                  $mdDialog.cancel();
                };
              },
              controllerAs: 'ctrl',
              templateUrl: 'common-tcq/save-changes/save-changes-dialog.tpl.html',
              parent: angular.element($document.body),
              clickOutsideToClose: false,
              locals: {'parametres': {title: 'DEFAULT_CANVIS_SENSE_GUARDAR_TITLE', text: 'DEFAULT_CANVIS_SENSE_GUARDAR_TEXT'}}
            });
          }
        }

      });

    });
}());
