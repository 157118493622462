<md-dialog  aria-label="{{'DEFAULT_IMPORTAR_TCQI' | translate}}">
  <form>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>{{'DEFAULT_IMPORTAR_TCQI' | translate}}</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="hide()">
          <md-icon aria-label="{{'DEFAULT_TANCAR' | translate}}">close</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content>
      <div class="md-dialog-content">
        <div class="mdl-grid" ng-show="ctrl.mostrarOriginal">

          <div class="mdl-cell mdl-cell--12-col">
            <itec-error-block errors="ctrl.errors['']"></itec-error-block>
          </div>

          <div class="mdl-cell mdl-cell--12-col">
            <md-tooltip md-z-index="999" md-direction="top">{{'VALIDATION_CARACTERES_NO_PERMESOS_CODI_OBRA' | translate}}
            </md-tooltip>
          	<itec-input-text
            	name="codi"
            	ng-model="ctrl.codi"
            	label="{{'DEFAULT_CODI' | translate}}"
            	ng-required="true"
  	        	ng-minlength="1"
  	        	ng-maxlength="50"
            	validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
            	validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
          	</itec-input-text>
          </div>

          <div class="mdl-cell mdl-cell--12-col">
          <itec-input-text
            name="codi"
            ng-model="ctrl.descripcio"
            label="{{'DEFAULT_DESCRIPCIO' | translate}}"
            ng-required="true"
  	        ng-minlength="1"
  	        ng-maxlength="2000"
            validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
            validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
          </itec-input-text>
          </div>

          <div class="mdl-grid mdl-cell--12-col"
            id="inputTextArbolTipologiasTCQiHome"
            ng-if="ctrl.mostrarArbolTipologias">
            <itec-input-text
              class="mdl-cell mdl-cell--6-col"
              name="tipologiaEDC"
              ng-model="ctrl.obra.tipologia.nom"
              label="{{'DEFAULT_TIPOLOGIA' | translate}}"
              validation-message-required="{{::'VALIDATION_ERROR_NotEmpty' | translate}}"
              ng-required="true"
              ng-readonly="true">
            </itec-input-text>

            <itec-button
              class="mdl-cell mdl-cell--6-col"
              ng-click-function="ctrl.selectTipologia()"
              class="icons-size"
              icon-left="{'class' : 'icon-llista'}"
              show-only-icon='true'
              label="{{'DEFAULT_ARBRE_TIPOLOGIES' | translate}}"
              css-classes="itec-button-squared">
            </itec-button>
          </div>

      </div>

      <div class="mdl-grid" ng-show="!ctrl.mostrarOriginal">
        <arbre-tipologies
            tipologies="ctrl.tipologies"
            tipologia-selected="ctrl.tipologiaSelected"
            submit-function="ctrl.confirmFunction"
            cancel-function="ctrl.cancelFunction()">
        </arbre-tipologies>

      </div>


    </md-dialog-content>
    <md-dialog-actions id="dialog-actions-importar" layout="row" ng-if="ctrl.mostrarOriginal">
      <itec-button
        class="itec-float-right"
        label="{{'DEFAULT_CANCELAR' | translate}}"
        ng-click-function="hide()">
      </itec-button>
      <itec-button
        class="itec-float-right"
        label="{{'DEFAULT_ACCEPTAR' | translate}}"
        ng-click-function="accept()">
      </itec-button>
    </md-dialog-actions>
  </form>
</md-dialog>
