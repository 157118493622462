<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">

  <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">
      <itec-button class="itec-float-right tcqi--margin-top-10" label="DEFAULT_TORNAR" ng-click-function="ctrl.back()"
        icon-left="ctrl.backButton"></itec-button>
  </div>

  <div class="mdl-cell--12-col tcqi--margin-bottom-20">
    <itec-group-form
        name="ctrl.formulari.nom"
        fields="ctrl.formulari.grups"
        ng-model="ctrl.configuracio"
        ng-submit-function="ctrl.formulari.func()"
        errors="ctrl.errors"
        backbutton="false">
      <div transclude-to="configuracio-copydrag-block">
        <itec-checkbox-group
          list="ctrl.configuracioList"
          ng-model="ctrl.configuracio.copyDragConf"
          ng-disabled="false"
          label="DEFAULT_CONFIGURACIO_COPYDRAG"
          simple="true">
        </itec-checkbox-group>
      </div>
    </itec-group-form>
  </div>

</div>
