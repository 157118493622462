(function () {
  'use strict';

  angular
    .module('itecTcqihome.compensacions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('compensacions', {
        url: '/compensacions',
        views: {
          'wrapper': {
            templateUrl: 'compensacions/compensacions.tpl.html',
            controller: 'CompensacionsCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          entitatActual: function(EntitatFactory) {
            return EntitatFactory.getActual().catch(function(){});
          },
          allEntitats: function(EntitatFactory) {
            return EntitatFactory.getAll().then(
              function(response){
                if (angular.isDefined(response) && angular.isDefined(response.data)) {
                  response = response.data;
                  return response;
                }
              });
          },
          dadesGeneralsData: function(SisquilloFactory) {
            return SisquilloFactory.getDadesGenerals().catch(function(){});
          },
          compensacionsData: function(SisquilloFactory) {
            return SisquilloFactory.getCompensacionsEntitat().catch(function(){});
          },
          obresCompartidesTotesEntitats: function(SisquilloFactory,entitatActual) {
             return SisquilloFactory.getSisquillosCompartitsByEntitat(entitatActual.data.id).
             then(function(response) {
                 if (angular.isDefined(response) && angular.isDefined(response.data)) {
                   return response.data;
                 }
              });
          }
        }
      });
  }
}());
