<md-dialog  aria-label="{{'DEFAULT_EXPORTAR_FIE' | translate}}">
  <form>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>{{'DEFAULT_COPIAR_OBRA' | translate}}</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="hide()">
          <md-icon aria-label="{{'DEFAULT_TANCAR' | translate}}">close</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content style="max-width:600px;max-height:810px; ">
      <div class="md-dialog-content">
        <div class="mdl-grid">

        <div class="mdl-cell mdl-cell--12-col" ng-if="ctrl.errors.length > 0">
          <itec-error-block errors="ctrl.errors"></itec-error-block>
        </div>
    
          <div class="mdl-cell mdl-cell--12-col">
            <md-tooltip md-z-index="999" md-direction="top">{{'VALIDATION_CARACTERES_NO_PERMESOS_CODI_OBRA' | translate}}
            </md-tooltip>
          	<itec-input-text
            	name="codi"
            	ng-model="ctrl.copiar_codi"
            	label="{{'DEFAULT_COPIAR_NEW_CODI' | translate}}"
            	ng-required="true"
  	        	ng-minlength="1"
  	        	ng-maxlength="50"
            	validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
            	validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
          	</itec-input-text>
          </div>

          <div class="mdl-cell mdl-cell--12-col">
          <itec-input-text
            name="descripcio"
            ng-model="ctrl.copiar_descripcio"
            label="{{'DEFAULT_DESCRIPCIO' | translate}}"
            ng-required="true"
  	        ng-minlength="1"
  	        ng-maxlength="2000"
            validation-message-required="{{::'DEFAULT_VALIDATION_MESSAGE_REQUIRED'}}"
            validation-message-maxlength="{{::'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH'}}" >
          </itec-input-text>
          </div>
    
    
        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.copiar_bolBanc"
     		ng-disabled ="!ctrl.copiar_teBanc">
		</itec-checkbox>
		{{'DEFAULT_COPIAR_BANC' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.copiar_bolPressupost"
     		ng-disabled ="!ctrl.copiar_tePressupost">
		</itec-checkbox>
		{{'DEFAULT_COPIAR_PRESSUPOST' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
        		<itec-checkbox
        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
             		ng-model="ctrl.copiar_bolSeguiment"
             		ng-disabled ="!ctrl.copiar_teSeguiment"
             		ng-change-function="ctrl.copiarCertificacio()">
             		>
        		</itec-checkbox>
        		{{'DEFAULT_COPIAR_CERTIFICACIO' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
        		<itec-checkbox
        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
             		ng-model="ctrl.copiar_bolPlaTreballs"
             		ng-disabled ="!ctrl.copiar_tePlaTreballs">
        		</itec-checkbox>
        		{{'DEFAULT_COPIAR_PLATREBALLS' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
            <itec-checkbox
              class="tcqi--margin-left-20 tcqi--margin-bottom-0"
                ng-model="ctrl.copiar_bolMA"
                ng-disabled ="!ctrl.copiar_teMA" 
                ng-change-function="ctrl.copiarMA()">
            </itec-checkbox>
            {{'DEFAULT_COPIAR_MEDIAMBIENT' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
            <itec-checkbox
              class="tcqi--margin-left-20 tcqi--margin-bottom-0"
                ng-model="ctrl.copiar_bolQualitat">
            </itec-checkbox>
            {{'DEFAULT_COPIAR_QUALITAT' | translate }}
        </div>

        <div class="mdl-cell mdl-cell--12-col">
        		<itec-checkbox
        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
             		ng-model="ctrl.copiar_bolPreusZero"
             		ng-change-function="ctrl.preusZero()">
        		</itec-checkbox>
        		{{'DEFAULT_COPIAR_PREUS_ZERO' | translate }}
        </div>
        <div class="mdl-cell mdl-cell--12-col">
        		<itec-checkbox
        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
             		ng-model="ctrl.copiar_bolFixarPreus"
             		ng-change-function="ctrl.fixarPreus()">
        		</itec-checkbox>
        		{{'DEFAULT_COPIAR_FIXAR_PREUS' | translate }} 
        </div>
        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.copiar_bolDesFixarPreus"
     		ng-change-function="ctrl.desFixarPreus()">
		</itec-checkbox>
		{{'DEFAULT_COPIAR_DESFIXAR_PREUS' | translate }}
        </div>
        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.copiar_bolSenseCT">
		</itec-checkbox>
		{{'DEFAULT_COPIAR_SENSE_CT' | translate }}
        </div>
        <div class="mdl-cell mdl-cell--12-col">
		<itec-checkbox
			class="tcqi--margin-left-20 tcqi--margin-bottom-0"
     		ng-model="ctrl.copiar_bolSenseGrafics">
		</itec-checkbox>
		{{'DEFAULT_COPIAR_SENSE_GRAFICS' | translate }}
        </div>





        
      </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">
       {{'DEFAULT_CANCELAR' | translate}}
      </md-button>
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">
        {{'DEFAULT_ACCEPTAR' | translate}}
      </md-button>
    </md-dialog-actions>
  </form>
</md-dialog>