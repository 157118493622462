(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuLinks
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuLinks', {
        '0': {
          'name': 'DEFAULT_TCQI',
          'state': 'obres',
          'onclick': ''
        },
        '1':{
          'name': 'DEFAULT_COMPENSACIONS',
          'state': 'compensacions',
          'onclick': ''
        }
    });
}());
