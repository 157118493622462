(function () {
  'use strict';

  angular
    .module('itecTcqihome')
    .config(config);

  function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('', '/obres');
    $urlRouterProvider.otherwise('/');

    $stateProvider
    .state('pagenotfound', {
      url: '/',
      views: {
        'wrapper': {
          template: '<itec-notfound initial-state="obres"></itec-notfound>',
        }
      },
    });
  }
}());
