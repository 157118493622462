(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.obres.filter:obraByCodiAndDescripcio
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres')
    .filter('obraByCodiAndDescripcio', obraByCodiAndDescripcioFilter);

  function obraByCodiAndDescripcioFilter($log) {
  return function (items, search) {
      var result = [];
      angular.forEach(items, function (value, key) {
        if (value.codi !== null && value.descripcio !== null) {
          var codi = value.codi.replace(/\s+/g, ' ').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          var descripcio = value.descripcio.replace(/\s+/g, ' ').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          search = search.replace(/\s+/g, ' ').toLowerCase(); // Eliminar los espacios en blanco de la cadena de búsqueda
          if (codi.indexOf(search) !== -1 || descripcio.indexOf(search) !== -1) {
              result.push(value);
          }
        }
      });
      return result;
  }
}
}());