(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome
   * @description
   *
   */
  angular
    .module('itecTcqihome', [
      'ui.router',
      'itecApi',
      'itecTcqihome.obres',
      'itecTcqihome.compensacions',
      'itecTcqihome.configuracio',
      'itecTcqihome.api',
      'itecTcqihome.shared',
      'itecLocale',
      'itecGuicomponents.layout',
      'itecGuicomponents.commonControls',
      'itecGuicomponents.commonTcq',
      'ngDraggable',
      'ngCookies',
      'ngFileSaver',
      'sharedData'
    ])
    .config(["$translatePartialLoaderProvider", "$urlRouterProvider", "$httpProvider", function ($translatePartialLoaderProvider, $urlRouterProvider, $httpProvider) {
      $httpProvider.defaults.useXDomain = true;

      $translatePartialLoaderProvider.addPart('resources');
      $translatePartialLoaderProvider.addPart('vendor/itec-guicomponents/dist/resources/common-controls/resources');
   }])
    .config(["$httpProvider", function($httpProvider) {
      $httpProvider.interceptors.push('LocaleInterceptorFactory');
      $httpProvider.interceptors.push('EntitatInterceptorFactory');
    }])
	 .run(["$rootScope", "LocalStorage", "SessionStorage", "DataFactory", "ApiLayoutConstant", "$mdDialog", "$document", "PreventUnsavedDataService", "$state", "ConfigService", "PermisosFactory", "AppFactory", "$timeout", "LocaleService", "BrowserDetectorFactory", function($rootScope, LocalStorage, SessionStorage, DataFactory, ApiLayoutConstant, $mdDialog, $document, PreventUnsavedDataService, $state, ConfigService, PermisosFactory, AppFactory, $timeout, LocaleService, BrowserDetectorFactory){
    
      //Configuració per itec-guicomponents
      LocalStorage.setBasePath('itecTcqihome');
      SessionStorage.setBasePath('itecTcqihome');
      $rootScope.prefixItecGuicomponents = 'vendor/itec-guicomponents/dist/';
      //SessionStorage.save('idEntitatCompartida','');
      ConfigService.setEntitat('');
      //ConfigService.setEntitatcompartida('');
      var errorState = 'pagenotfound';

      PermisosFactory.setApiParameters(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'permisos', '');
      DataFactory.setApiParameters('tcqihome', ApiLayoutConstant.SERVER_VERSION_TCQIHOME, 'authtcqihome','');

      
      //Inicializamos el service para comprobar si en formularios tenemos
      //cambios sin guardar.
      PreventUnsavedDataService.init();

      var iniciCarrega;

      //Pedimos permisos (y creamos sesion y desbloqueamos la api)
      PermisosFactory.load().then(function(response){
        $rootScope.permisos = response.data.permisos;
        $rootScope.usuari = response.data.usuari;
        SessionStorage.save('idEntitat', response.data.idEntitat);
        ConfigService.setEntitat(response.data.idEntitat);
        LocaleService.setLocaleByIdioma($rootScope.usuari.idioma);
        BrowserDetectorFactory.check(response.data.browsers);
      }).catch(function(error){
        console.log(error);
        $state.go(errorState);
      }).finally(function(){
        DataFactory.unblockApi();
        AppFactory.setExistsSession(true);
      });

      DataFactory.blockApi();


      $rootScope.$on('$stateChangeStart', function(e, toState, toParams, fromState, fromParams, options) {
        //Consultamos si existe algún cambio en un formulario
        //pendiente sin guardar
        var dirty = PreventUnsavedDataService.existDirty();

        if(dirty){
          //Si existe algún cambio sin guardar se avisa al usuario
          //Cancelamos el evento
          e.preventDefault();

          //Consulto si ya tengo algún mensaje en progreso (en el caso que
          // me encuentre en un sub-estado de x niveles que provoca que se llame
          // a este evento x veces)
          if(!PreventUnsavedDataService.isInProgress()){
            PreventUnsavedDataService.enableInProgress();
            $rootScope.$broadcast('$scheduleDeactivateLoader');
            $mdDialog.show({
              controller: ["$scope", "$mdDialog", "parametres", "Notification", "$translate", "$rootScope", "PreventUnsavedDataService", function DialogController($scope, $mdDialog, parametres, Notification, $translate, $rootScope, PreventUnsavedDataService) {
                var vm = this;
                vm.title = parametres.title;
                vm.text = parametres.text;

                PreventUnsavedDataService.enableInProgress();

                $scope.accept = function() {
                  //El usuario no quiere guardar los cambios

                  //Cierro el dialog
                  $mdDialog.hide();
                  //Vuelvo a activar el loader
                  $rootScope.$broadcast('$scheduleActivateLoader');
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();

                  //Limpiamos los formularios ya que no queremos guardar ningún cambio
                  PreventUnsavedDataService.cleanForms();

                  if (toState.name !== errorState) {
                    //Voy al estado que quería
                    $state.go(toState.name);
                  }

                };
                $scope.hide = function() {
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();
                  $mdDialog.cancel();
                };
                $scope.cancel = function() {
                  //Indico que ya no está en progreso el mensaje
                  PreventUnsavedDataService.disableInProgress();
                  $mdDialog.cancel();
                };
              }],
              controllerAs: 'ctrl',
              templateUrl: 'common-tcq/save-changes/save-changes-dialog.tpl.html',
              parent: angular.element($document.body),
              clickOutsideToClose: false,
              locals: {'parametres': {title: 'DEFAULT_CANVIS_SENSE_GUARDAR_TITLE', text: 'DEFAULT_CANVIS_SENSE_GUARDAR_TEXT'}}
            });
          }
        }

      });

    }]);
}());

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/tcq-obra-form-upload/tcq-obra-form-upload-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <itec-form-file-upload\n' +
    '    name="form"\n' +
    '    class="mdl-cell mdl-cell--12-col"\n' +
    '    button-class="itec-float-right"\n' +
    '    files="vm.files"\n' +
    '    err-files="vm.errFiles"\n' +
    '    funcio-seleccionar="vm.uploadFile()"\n' +
    '    select-files-text="{{selectFilesText}}"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    pattern="[\'.tcqcsv\', \'.tcqi\']"\n' +
    '    show-only-icon="true"\n' +
    '    icon-left="vm.iconLeft">\n' +
    '  </itec-form-file-upload>\n' +
    '\n' +
    '  <!--<div class="mdl-cell mdl-cell--12-col" ng-show="vm.objFile.status == \'uploading\' || vm.objFile.status == \'done\'">\n' +
    '    {{vm.objFile.file.name}}\n' +
    '    <md-button ng-show="vm.objFile.progress==100" class="md-icon-button">\n' +
    '      <md-icon>done</md-icon>\n' +
    '    </md-button>\n' +
    '    <span flex></span>\n' +
    '    <md-progress-linear md-mode="determinate" value="{{vm.objFile.progress}}">\n' +
    '    </md-progress-linear>\n' +
    '  </div>-->\n' +
    '\n' +
    '  <itec-form-file-upload-progress ng-if="vm.objFile !== undefined" file="vm.objFile"></itec-form-file-upload-progress>\n' +
    '\n' +
    '  <itec-form-file-upload-errors\n' +
    '    err-files="vm.errFiles"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    max-size-exceeded-text={{maxSizeExceededText}}>\n' +
    '  </itec-form-file-upload-errors>\n' +
    '\n' +
    '  <itec-error-block \n' +
    '    errors="vm.errors[\'\']" \n' +
    '    class="validation-messages mdl-cell mdl-cell--12-col">\n' +
    '  </itec-error-block>\n' +
    '\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/tcq-bc3-form-upload/tcq-bc3-form-upload-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <itec-form-file-upload\n' +
    '    name="form"\n' +
    '    class="mdl-cell mdl-cell--12-col"\n' +
    '    button-class="itec-float-right"\n' +
    '    files="vm.files"\n' +
    '    err-files="vm.errFiles"\n' +
    '    funcio-seleccionar="vm.selectFile()"\n' +
    '    select-files-text="{{selectFilesText}}"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    pattern="[\'.bc3\', \'.zip\']"\n' +
    '    icon-left="vm.iconLeft">\n' +
    '  </itec-form-file-upload>\n' +
    '\n' +
    '  <!--<div class="mdl-cell mdl-cell--12-col" ng-show="vm.objFile.status == \'uploading\' || vm.objFile.status == \'done\'">\n' +
    '    {{vm.objFile.file.name}}\n' +
    '    <md-button ng-show="vm.objFile.progress==100" class="md-icon-button">\n' +
    '      <md-icon>done</md-icon>\n' +
    '    </md-button>\n' +
    '    <span flex></span>\n' +
    '    <md-progress-linear md-mode="determinate" value="{{vm.objFile.progress}}">\n' +
    '    </md-progress-linear>\n' +
    '  </div>-->\n' +
    '\n' +
    '  <itec-form-file-upload-progress ng-if="vm.objFile !== undefined" file="vm.objFile"></itec-form-file-upload-progress>\n' +
    '\n' +
    '  <itec-form-file-upload-errors\n' +
    '    err-files="vm.errFiles"\n' +
    '    max-size="{{vm.maxSize}}"\n' +
    '    max-size-exceeded-text={{maxSizeExceededText}}>\n' +
    '  </itec-form-file-upload-errors>\n' +
    '\n' +
    '  <itec-error-block \n' +
    '    errors="vm.errors[\'\']" \n' +
    '    class="validation-messages mdl-cell mdl-cell--12-col">\n' +
    '  </itec-error-block>\n' +
    '\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/submain/submain.tpl.html',
    '<div ui-view="content"></div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/pdf-viewer/mnt-pdf-viewer-directive.tpl.html',
    '<div class="mdl-grid mdl-grid--no-spacing">\n' +
    '  <div class="mdl-cell mdl-cell--12-col">\n' +
    '    <form name="formulari" novalidate>\n' +
    '      <div id="itec-left-filter-pdf" class="mdl-grid mdl-grid--no-spacing">\n' +
    '        <div id="buscador" ng-repeat="group in fields" ng-if="ctrl.expandedSearch" class="mdl-cell mdl-cell--12-col group-div">\n' +
    '          <div class="mdl-grid">\n' +
    '            <div class="mdl-cell mdl-cell--{{group.columns ? group.columns : 12}}-col">\n' +
    '              <div class="mdl-grid" ng-hide="group.collapsed">\n' +
    '\n' +
    '                <div ng-repeat="field in group.camps" class="mdl-cell mdl-cell--12-col">\n' +
    '                  <div ng-include="\'common-controls/directives/itec-form-fields.tpl.html\'"></div>\n' +
    '                  <div ng-if="field.type === \'multiTransclude\'" ng-transclude transclude-from="{{field.block}}" name="{{field.name}}"></div> \n' +
    '                </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '  <div id="itec-content-filter-pdf" class="mdl-cell mdl-cell--12-col">\n' +
    '    <form id="pdfButtons">\n' +
    '      <itec-pdf-buttons\n' +
    '        pre-function="preFunction()"\n' +
    '        go-pdf-url="ctrl.goPdfUrl()"\n' +
    '        doc-url="ctrl.pdfUrl"\n' +
    '        download-doc="ctrl.downloadDoc()"\n' +
    '        download-xls="ctrl.downloadXls()"\n' +
    '        doc-button="docButton"\n' +
    '        xls-button="xlsButton"\n' +
    '        go-transclude="ctrl.goTransclude()"\n' +
    '        transclude-button="ctrl.havingTranscludeOp"\n' +
    '        transclude-text="{{transcludeButtonText}}"\n' +
    '        >\n' +
    '      </itec-pdf-buttons>\n' +
    '    </form>\n' +
    '    <div ng-show="ctrl.showPdfLayout">\n' +
    '      <iframe type="text/html" ng-src="{{trustSrc(ctrl.pdfUrl)}}" style="height: 900px;" class="mdl-cell mdl-cell--12-col"></iframe>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/header-submenu/headerSubmenu.tpl.html',
    '<tcqi-header-submenu ng-if="vm.attr.header.submenu.show" attr="vm.attr"></tcqi-header-submenu>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('shared/arbre-tipologies/arbre-tipologies-directive.tpl.html',
    '<div class="arbol-tipologias-scroll">\n' +
    '  <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '    <itec-tree\n' +
    '      items="vm.tipologies"\n' +
    '      item-name="node"\n' +
    '      expanded-collection="vm.expanded"\n' +
    '      children="node.children"\n' +
    '      has-children="node.hasChildren"\n' +
    '      show-button="true"\n' +
    '      order-by="nom">\n' +
    '      <itec-checkbox\n' +
    '        ng-disabled="false"\n' +
    '        ng-model="node.checked"\n' +
    '        ng-click="vm.changeCheck(node)">\n' +
    '      </itec-checkbox>\n' +
    '      {{node.nom}}\n' +
    '    </itec-tree>\n' +
    '  </div>\n' +
    '</div>\n' +
    '<div class="arbol-tipologias-buttons mdl-cell mdl-cell--12-col tcqi--margin-top-20">\n' +
    '  <itec-button\n' +
    '    label="{{\'DEFAULT_TORNAR\' | translate}}"\n' +
    '    ng-click-function="vm.cancelFunction()"\n' +
    '    icon-left="vm.backButton">\n' +
    '  </itec-button>\n' +
    '  <itec-button\n' +
    '    label="{{\'DIALOG_CONFIRM\' | translate}}"\n' +
    '    ng-click-function="vm.guardarCanvisTipologia()">\n' +
    '  </itec-button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/vincular-a-tcqicities/vincular-a-tcqicities-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_VINCULAR_PROJECTE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_VINCULAR_PROJECTE\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content  style="min-width:800px;max-width:1000px;max-height:810px; ">\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-error-block errors="ctrl.errors"></itec-error-block>\n' +
    '        \n' +
    '        <div class="mdl-grid mdl-grid--no-spacing tcqi--with-scroll">\n' +
    '          <itec-simple-list\n' +
    '            class="mdl-cell mdl-cell--12-col"\n' +
    '            show-add-button="false"\n' +
    '            show-edit-button="false"\n' +
    '            show-delete-button="false"\n' +
    '            items="ctrl.listItems"\n' +
    '            fields="ctrl.fields"\n' +
    '            show-text-wrapped="true"\n' +
    '            click-field-checkbox="true"\n' +
    '            formatter-function="ctrl.formatter"\n' +
    '            >\n' +
    '          </itec-simple-list>\n' +
    '          <div ng-if="ctrl.showNoProjects">\n' +
    '            <span>{{\'DEFAULT_NO_PROJECTES_TCQICITIES\' | translate}}</span>\n' +
    '          </div >\n' +
    '        </div>\n' +
    '        </div>\n' +
    '\n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/importar-obra/importar-obra-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_IMPORTAR_TCQI\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_IMPORTAR_TCQI\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content>\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid" ng-show="ctrl.mostrarOriginal">\n' +
    '\n' +
    '          <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <itec-error-block errors="ctrl.errors[\'\']"></itec-error-block>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <md-tooltip md-z-index="999" md-direction="top">{{\'VALIDATION_CARACTERES_NO_PERMESOS_CODI_OBRA\' | translate}}\n' +
    '            </md-tooltip>\n' +
    '          	<itec-input-text\n' +
    '            	name="codi"\n' +
    '            	ng-model="ctrl.codi"\n' +
    '            	label="{{\'DEFAULT_CODI\' | translate}}"\n' +
    '            	ng-required="true"\n' +
    '  	        	ng-minlength="1"\n' +
    '  	        	ng-maxlength="50"\n' +
    '            	validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '            	validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '          	</itec-input-text>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-input-text\n' +
    '            name="codi"\n' +
    '            ng-model="ctrl.descripcio"\n' +
    '            label="{{\'DEFAULT_DESCRIPCIO\' | translate}}"\n' +
    '            ng-required="true"\n' +
    '  	        ng-minlength="1"\n' +
    '  	        ng-maxlength="2000"\n' +
    '            validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '            validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '          </itec-input-text>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-grid mdl-cell--12-col"\n' +
    '            id="inputTextArbolTipologiasTCQiHome"\n' +
    '            ng-if="ctrl.mostrarArbolTipologias">\n' +
    '            <itec-input-text\n' +
    '              class="mdl-cell mdl-cell--6-col"\n' +
    '              name="tipologiaEDC"\n' +
    '              ng-model="ctrl.obra.tipologia.nom"\n' +
    '              label="{{\'DEFAULT_TIPOLOGIA\' | translate}}"\n' +
    '              validation-message-required="{{::\'VALIDATION_ERROR_NotEmpty\' | translate}}"\n' +
    '              ng-required="true"\n' +
    '              ng-readonly="true">\n' +
    '            </itec-input-text>\n' +
    '\n' +
    '            <itec-button\n' +
    '              class="mdl-cell mdl-cell--6-col"\n' +
    '              ng-click-function="ctrl.selectTipologia()"\n' +
    '              class="icons-size"\n' +
    '              icon-left="{\'class\' : \'icon-llista\'}"\n' +
    '              show-only-icon=\'true\'\n' +
    '              label="{{\'DEFAULT_ARBRE_TIPOLOGIES\' | translate}}"\n' +
    '              css-classes="itec-button-squared">\n' +
    '            </itec-button>\n' +
    '          </div>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-grid" ng-show="!ctrl.mostrarOriginal">\n' +
    '        <arbre-tipologies\n' +
    '            tipologies="ctrl.tipologies"\n' +
    '            tipologia-selected="ctrl.tipologiaSelected"\n' +
    '            submit-function="ctrl.confirmFunction"\n' +
    '            cancel-function="ctrl.cancelFunction()">\n' +
    '        </arbre-tipologies>\n' +
    '\n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions id="dialog-actions-importar" layout="row" ng-if="ctrl.mostrarOriginal">\n' +
    '      <itec-button\n' +
    '        class="itec-float-right"\n' +
    '        label="{{\'DEFAULT_CANCELAR\' | translate}}"\n' +
    '        ng-click-function="hide()">\n' +
    '      </itec-button>\n' +
    '      <itec-button\n' +
    '        class="itec-float-right"\n' +
    '        label="{{\'DEFAULT_ACCEPTAR\' | translate}}"\n' +
    '        ng-click-function="accept()">\n' +
    '      </itec-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/exportar-a-fie/importar-de-fie-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_IMPORTAR_FIE\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content style="max-width:600px;max-height:810px; ">\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-error-block errors="ctrl.errors[\'\']"></itec-error-block>\n' +
    '        </div>\n' +
    '    \n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        <itec-input-text\n' +
    '          name="codi"\n' +
    '          ng-model="ctrl.codi"\n' +
    '          label="{{\'DEFAULT_CODI\' | translate}}"\n' +
    '          ng-required="true"\n' +
    '	      ng-minlength="1"\n' +
    '	      ng-maxlength="50"\n' +
    '          validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '          validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '        </itec-input-text>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        <itec-input-text\n' +
    '          name="codi"\n' +
    '          ng-model="ctrl.descripcio"\n' +
    '          label="{{\'DEFAULT_DESCRIPCIO\' | translate}}"\n' +
    '          ng-required="true"\n' +
    '	      ng-minlength="1"\n' +
    '	      ng-maxlength="2000"\n' +
    '          validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '          validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '        </itec-input-text>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '            {{\'DEFAULT_IMPORTAR_RECODIFICAR\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <itec-checkbox\n' +
    '              class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '                ng-model="ctrl.copycodidesc">\n' +
    '            </itec-checkbox>\n' +
    '            {{\'DEFAULT_IMPORTAR_COPIAR_CODI_DESCRIPCIO\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '      </div>\n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_EXPORTAR_FIE\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content  style="max-width:600px;max-height:810px; ">\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-error-block errors="ctrl.errors[\'\']"></itec-error-block>\n' +
    '        </div>\n' +
    '    \n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.export_plecs">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_EXPORT_FIE_PLECS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.export_grafics">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_EXPORT_FIE_GRAFICS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.export_amid">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_EXPORT_FIE_AMID\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <itec-checkbox\n' +
    '              class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '                ng-model="ctrl.export_codisoriginals">\n' +
    '            </itec-checkbox>\n' +
    '            {{\'DEFAULT_EXPORT_FIE_CODIS_ORIGINALS\' | translate }}\n' +
    '        </div>\n' +
    '        \n' +
    '\n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/dades/tipologies.tpl.html',
    '<arbre-tipologies\n' +
    '    tipologies="ctrl.tipologies"\n' +
    '    tipologia-selected="ctrl.tipologiaSelected"\n' +
    '    submit-function="ctrl.confirm"\n' +
    '    cancel-function="ctrl.cancel()">\n' +
    '</arbre-tipologies>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/dades/dades.tpl.html',
    '<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--with-scroll">\n' +
    '  <div class="obres-content">\n' +
    '\n' +
    '    <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '        <itec-button class="itec-float-right" label="DEFAULT_TORNAR" ng-click-function="ctrl.back()"\n' +
    '          icon-left="ctrl.backButton"></itec-button>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Botón de subir la imagen -->\n' +
    '    <div id="tcqihome--obres-dades-image-button" class="mdl-cell--12-col">\n' +
    '      <tcq-imatge-form-upload\n' +
    '        ng-if="!ctrl.isNew"\n' +
    '        select-files-text="DEFAULT_IMATGE"\n' +
    '        save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '        max-size="{{ctrl.maxUploadSize}}"\n' +
    '        max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '        show-only-icon="false"\n' +
    '        id-container="ctrl.obra.id"\n' +
    '        ng-model="ctrl.obra.imatge"\n' +
    '        icon-left="ctrl.iconLeft"\n' +
    '        server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_TCQIHOME}}"\n' +
    '        server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '        app-name="{{ctrl.ApiLayoutConstant.APP_NOM_TCQIHOME}}"\n' +
    '        auth-name="{{ctrl.TcqihomeConstant.AUTH_TCQIHOME}}"\n' +
    '        request-headers="ctrl.headers"\n' +
    '        can-delete="true"\n' +
    '        on-delete-function="ctrl.deleteImage">\n' +
    '      </tcq-imatge-form-upload>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="mdl-cell--12-col tcqi--margin-bottom-20">\n' +
    '      <itec-group-form\n' +
    '          name="ctrl.formulari.nom"\n' +
    '          fields="ctrl.formulari.grups"\n' +
    '          ng-model="ctrl.obra"\n' +
    '          ng-submit-function="ctrl.formulari.func()"\n' +
    '          errors="ctrl.errors"\n' +
    '          backbutton="false"\n' +
    '          submit-button="!ctrl.obra.teSeguiment">\n' +
    '\n' +
    '        <div transclude-to="tipologia-block" >\n' +
    '          <div class="tipologia-block" ng-if="$root.permisos.TIPOLOGIA.read">\n' +
    '            <div class="mdl-grid">\n' +
    '              <itec-input-text\n' +
    '                class="mdl-cell mdl-cell--4-col"\n' +
    '                name="tipologiaEDC"\n' +
    '                ng-model="ctrl.obra.tipologia.nom"\n' +
    '                label="{{\'DEFAULT_TIPOLOGIA\' | translate}}"\n' +
    '                validation-message-required="{{\'VALIDATION_ERROR_NotEmpty\' | translate}}"\n' +
    '                ng-required="true"\n' +
    '                ng-readonly="true"\n' +
    '                ng-disabled="ctrl.obra.teSeguiment">\n' +
    '              </itec-input-text>\n' +
    '\n' +
    '              <itec-button\n' +
    '                class="mdl-cell mdl-cell--2-col"\n' +
    '                ng-click-function="ctrl.selectTipologia()"\n' +
    '                class="icons-size"\n' +
    '                icon-left="{\'class\' : \'icon-llista\'}"\n' +
    '                show-only-icon=\'true\'\n' +
    '                ng-disabled="ctrl.obra.teSeguiment"\n' +
    '                css-classes="itec-button-squared">\n' +
    '              </itec-button>\n' +
    '              <!-- fill up the line -->\n' +
    '              <div class="mdl-cell mdl-cell--6-col"></div>\n' +
    '\n' +
    '              <itec-input-numeric ng-form="form" ng-repeat="pregunta in ctrl.obra.listPreguntes"\n' +
    '                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Numeric"\n' +
    '                class="mdl-cell mdl-cell--3-col"\n' +
    '                cut-label="true"\n' +
    '                ng-model="pregunta.valorNumeric"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                decimal-places="ctrl.TcqihomeConstant.Decimals"\n' +
    '                ng-required="false">\n' +
    '              </itec-input-numeric>\n' +
    '\n' +
    '              <itec-input-text\n' +
    '                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Cadena"\n' +
    '                class="mdl-cell mdl-cell--3-col"\n' +
    '                cut-label="true"\n' +
    '                ng-model="pregunta.valorCadena"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                ng-required="false">\n' +
    '              </itec-input-text>\n' +
    '\n' +
    '              <itec-checkbox\n' +
    '                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Boolea"\n' +
    '                class="mdl-cell mdl-cell--3-col"\n' +
    '                cut-label="true"\n' +
    '                ng-model="pregunta.valorBoolea"\n' +
    '                label="{{ctrl.getDescPregunta(pregunta)}}"\n' +
    '                ng-required="false">\n' +
    '              </itec-checkbox>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '      </itec-group-form>\n' +
    '\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/copiar-obra/copiar-obra-dialog.tpl.html',
    '<md-dialog  aria-label="{{\'DEFAULT_EXPORTAR_FIE\' | translate}}">\n' +
    '  <form>\n' +
    '    <md-toolbar>\n' +
    '      <div class="md-toolbar-tools">\n' +
    '        <h2>{{\'DEFAULT_COPIAR_OBRA\' | translate}}</h2>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-icon-button" ng-click="hide()">\n' +
    '          <md-icon aria-label="{{\'DEFAULT_TANCAR\' | translate}}">close</md-icon>\n' +
    '        </md-button>\n' +
    '      </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content style="max-width:600px;max-height:810px; ">\n' +
    '      <div class="md-dialog-content">\n' +
    '        <div class="mdl-grid">\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col" ng-if="ctrl.errors.length > 0">\n' +
    '          <itec-error-block errors="ctrl.errors"></itec-error-block>\n' +
    '        </div>\n' +
    '    \n' +
    '          <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <md-tooltip md-z-index="999" md-direction="top">{{\'VALIDATION_CARACTERES_NO_PERMESOS_CODI_OBRA\' | translate}}\n' +
    '            </md-tooltip>\n' +
    '          	<itec-input-text\n' +
    '            	name="codi"\n' +
    '            	ng-model="ctrl.copiar_codi"\n' +
    '            	label="{{\'DEFAULT_COPIAR_NEW_CODI\' | translate}}"\n' +
    '            	ng-required="true"\n' +
    '  	        	ng-minlength="1"\n' +
    '  	        	ng-maxlength="50"\n' +
    '            	validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '            	validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '          	</itec-input-text>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-cell mdl-cell--12-col">\n' +
    '          <itec-input-text\n' +
    '            name="descripcio"\n' +
    '            ng-model="ctrl.copiar_descripcio"\n' +
    '            label="{{\'DEFAULT_DESCRIPCIO\' | translate}}"\n' +
    '            ng-required="true"\n' +
    '  	        ng-minlength="1"\n' +
    '  	        ng-maxlength="2000"\n' +
    '            validation-message-required="{{::\'DEFAULT_VALIDATION_MESSAGE_REQUIRED\'}}"\n' +
    '            validation-message-maxlength="{{::\'DEFAULT_VALIDATION_MESSAGE_MAXLENGTH\'}}" >\n' +
    '          </itec-input-text>\n' +
    '          </div>\n' +
    '    \n' +
    '    \n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.copiar_bolBanc"\n' +
    '     		ng-disabled ="!ctrl.copiar_teBanc">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_COPIAR_BANC\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.copiar_bolPressupost"\n' +
    '     		ng-disabled ="!ctrl.copiar_tePressupost">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_COPIAR_PRESSUPOST\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.copiar_bolSeguiment"\n' +
    '             		ng-disabled ="!ctrl.copiar_teSeguiment"\n' +
    '             		ng-change-function="ctrl.copiarCertificacio()">\n' +
    '             		>\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_COPIAR_CERTIFICACIO\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.copiar_bolPlaTreballs"\n' +
    '             		ng-disabled ="!ctrl.copiar_tePlaTreballs">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_COPIAR_PLATREBALLS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <itec-checkbox\n' +
    '              class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '                ng-model="ctrl.copiar_bolMA"\n' +
    '                ng-disabled ="!ctrl.copiar_teMA" \n' +
    '                ng-change-function="ctrl.copiarMA()">\n' +
    '            </itec-checkbox>\n' +
    '            {{\'DEFAULT_COPIAR_MEDIAMBIENT\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '            <itec-checkbox\n' +
    '              class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '                ng-model="ctrl.copiar_bolQualitat">\n' +
    '            </itec-checkbox>\n' +
    '            {{\'DEFAULT_COPIAR_QUALITAT\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.copiar_bolPreusZero"\n' +
    '             		ng-change-function="ctrl.preusZero()">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_COPIAR_PREUS_ZERO\' | translate }}\n' +
    '        </div>\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '        		<itec-checkbox\n' +
    '        			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '             		ng-model="ctrl.copiar_bolFixarPreus"\n' +
    '             		ng-change-function="ctrl.fixarPreus()">\n' +
    '        		</itec-checkbox>\n' +
    '        		{{\'DEFAULT_COPIAR_FIXAR_PREUS\' | translate }} \n' +
    '        </div>\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.copiar_bolDesFixarPreus"\n' +
    '     		ng-change-function="ctrl.desFixarPreus()">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_COPIAR_DESFIXAR_PREUS\' | translate }}\n' +
    '        </div>\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.copiar_bolSenseCT">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_COPIAR_SENSE_CT\' | translate }}\n' +
    '        </div>\n' +
    '        <div class="mdl-cell mdl-cell--12-col">\n' +
    '		<itec-checkbox\n' +
    '			class="tcqi--margin-left-20 tcqi--margin-bottom-0"\n' +
    '     		ng-model="ctrl.copiar_bolSenseGrafics">\n' +
    '		</itec-checkbox>\n' +
    '		{{\'DEFAULT_COPIAR_SENSE_GRAFICS\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        \n' +
    '      </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">\n' +
    '       {{\'DEFAULT_CANCELAR\' | translate}}\n' +
    '      </md-button>\n' +
    '      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">\n' +
    '        {{\'DEFAULT_ACCEPTAR\' | translate}}\n' +
    '      </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '  </form>\n' +
    '</md-dialog>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('templates/notifications-template-tpl.html',
    '<div  class="contractes-content">\n' +
    '  <div class="mdl-grid mdl-grid--no-spacing">\n' +
    '    <div class="mdl-cell mdl-cell--12-col">\n' +
    '      <md-card>\n' +
    '        <md-card-title>{{title | translate}}</md-card-title>\n' +
    '        <md-card-content>\n' +
    '          <table class="itec-table">\n' +
    '            <thead>\n' +
    '              <tr>\n' +
    '                <th>{{ \'DEFAULT_TIPUS_MANTENIMENT\'  | translate }}</th>\n' +
    '                <th>{{ \'DEFAULT_DESCRIPCIO\'         | translate }}</th>\n' +
    '                <th>{{ \'DEFAULT_ACCIONS\'            | translate }}</th>\n' +
    '              </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '              <tr ng-repeat="row in notificationsDades" class="{{row.llegit?\'\':\'bold\'}}">\n' +
    '                <td>\n' +
    '                  <span ng-if="!angular.isUndefined(row.tipusMant)">\n' +
    '                    {{row.tipusMant}}<br/>\n' +
    '                  </span>\n' +
    '                  {{row.dmy}}\n' +
    '                </td>\n' +
    '              <td>\n' +
    '                <pre>\n' +
    '                  {{row.descripcio}}\n' +
    '                </pre>\n' +
    '              </td>\n' +
    '              <td>\n' +
    '                <button ng-if="!row.llegit" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" ng-click="notificationsFunctions[0](row.id)">\n' +
    '                  <i class="material-icons">markunread</i>\n' +
    '                  <!--_OK_READ_ACTION_-->\n' +
    '                </button>\n' +
    '                <button ng-if="row.llegit" ng-click="notificationsFunctions[1](row.id)" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored">\n' +
    '                <i class="material-icons">delete</i>\n' +
    '                <!--_OK_DELETE_MESSAGE_ACTION_-->\n' +
    '                </button>\n' +
    '                <a  ng-if="row.fileName !== null" \n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored" \n' +
    '                    href="{{notificationsFunctions[2](row.id,row.fileName)}}" \n' +
    '                    target="_self">\n' +
    '                  <i class="material-icons">file_download</i>\n' +
    '                  <!--_OK_DOWNLOAD_MESSAGE_ANNEX_-->\n' +
    '                </a>\n' +
    '              </td>\n' +
    '              </tr>\n' +
    '            </tbody>\n' +
    '          </table>\n' +
    '        </md-card-content>\n' +
    '      </md-card>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/obres.tpl.html',
    '<!-- <img src="vendor/itec-icons/images/tcqi-icona-ban.png"/> -->\n' +
    '<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <!-- Botones -->\n' +
    '  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-30">\n' +
    '    <div class="tcqihome--obres-title">\n' +
    '      <i class="material-icons">folder</i>\n' +
    '      <span class="bold">{{::\'OBRES_TOTES_OBRES\' | translate}}</span>\n' +
    '    </div>\n' +
    '\n' +
    '    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button\n' +
    '        mdl-button--icon mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode"\n' +
    '        ng-if="(ctrl.obres.length > 0 || ctrl.obrescompartides.length>0 || ctrl.searchMode)">\n' +
    '\n' +
    '      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}">\n' +
    '\n' +
    '      </i>\n' +
    '      <i ng-if="!ctrl.listMode" class="material-icons"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}">\n' +
    '        format_list_bulleted\n' +
    '      </i>\n' +
    '    </button>\n' +
    '\n' +
    '    <tcq-bim-form-upload\n' +
    '      ng-if="$root.permisos.SISQUILLO_IMPORTAR_BIM.read || true"\n' +
    '      is-new = "true"\n' +
    '      dades-generals="ctrl.dadesGeneralsData"\n' +
    '      select-files-text="DEFAULT_IMPORTAR_BIM"\n' +
    '      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '      max-size="{{ctrl.maxObraSize}}"\n' +
    '      show-only-icon="false"\n' +
    '      on-finish-function="ctrl.viewDades"\n' +
    '      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '      class="itec-float-right"\n' +
    '      icon-left-content="cloud_download"\n' +
    '      title="{{\'BIM_ARXIUS_ADD_TITLE\' | translate}}"\n' +
    '      show-toolbar-dialog="true">\n' +
    '    </tcq-bim-form-upload>\n' +
    '\n' +
    '    <tcq-bc3-form-upload\n' +
    '      ng-if="$root.permisos.SISQUILLO_IMPORTAR_FIE.read"\n' +
    '      select-files-text="DEFAULT_IMPORTAR_FIE"\n' +
    '      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '      max-size="{{ctrl.maxObraSize}}"\n' +
    '      show-only-icon="false"\n' +
    '      on-finish-function="ctrl.viewDades"\n' +
    '      icon-left="ctrl.iconLeft"\n' +
    '      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '      class="itec-float-right">\n' +
    '    </tcq-bc3-form-upload>\n' +
    '\n' +
    '    <tcq-obra-form-upload\n' +
    '      ng-if="$root.permisos.SISQUILLO_IMPORTAR_TCQI.read"\n' +
    '      select-files-text="DEFAULT_IMPORTAR_TCQI"\n' +
    '      save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '      max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '      max-size="{{ctrl.maxObraSize}}"\n' +
    '      show-only-icon="false"\n' +
    '      on-finish-function="ctrl.viewDadesUpload"\n' +
    '      server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '      server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '      class="itec-float-right">\n' +
    '    </tcq-obra-form-upload>\n' +
    '\n' +
    '    <itec-button ng-if="$root.permisos.SISQUILLO.add" class="itec-float-right tcqi--margin-left-20"\n' +
    '        label="DEFAULT_NOU" ng-click-function="ctrl.addSisquillo()" icon-left="ctrl.addButton">\n' +
    '    </itec-button>\n' +
    '\n' +
    '    <form ng-if="$root.permisos.SISQUILLO.read"\n' +
    '        class="itec-float-right">\n' +
    '            <div ng-show="ctrl.expanded">\n' +
    '                <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                 ng-click="ctrl.closeSearchSisquillos()">\n' +
    '            <i class="material-icons" title="{{::\'DEFAULT_TANCAR\' | translate}}"\n' +
    '                aria-label="{{::\'DEFAULT_TANCAR\' | translate}}">closeround</i>\n' +
    '          </label>\n' +
    '\n' +
    '          <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                 ng-click="ctrl.searchSisquillos()">\n' +
    '            <i class="material-icons" title="{{::\'DEFAULT_CERCAR\' | translate}}"\n' +
    '                aria-label="{{::\'DEFAULT_CERCAR\' | translate}}">search</i>\n' +
    '          </label>\n' +
    '\n' +
    '          <div class="tcqihome-search itec-float-left mdl-textfield mdl-js-textfield">\n' +
    '              <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText"\n' +
    '              ng-keydown="ctrl.escucharEnterSearch($event)" />\n' +
    '              <label class="mdl-textfield__label" for="search-expandable" ng-hide="ctrl.searchText">\n' +
    '                {{::\'DEFAULT_CERCA_CODI_O_TITOL\' | translate}}\n' +
    '              </label>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '        <div ng-show="!ctrl.expanded">\n' +
    '           <label class="itec-float-left mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                 ng-click="ctrl.searchSisquillos()">\n' +
    '            <i class="material-icons" title="{{::\'DEFAULT_CERCAR\' | translate}}"\n' +
    '                aria-label="{{::\'DEFAULT_CERCAR\' | translate}}">search</i>\n' +
    '          </label>\n' +
    '        </div>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Módulos "TCQi" -->\n' +
    '  <div ng-if="ctrl.obres.length === 0 && ctrl.obrescompartides.length===0 && !ctrl.searchMode" id="tcqihome--obres-no-items">\n' +
    '    <span>{{::\'DEFAULT_NO_ITEM\' | translate}}</span>\n' +
    '    <div class="clear"></div>\n' +
    '\n' +
    '    <div class="tcqihome--obres-title">\n' +
    '      <i class="icon-icono-grups-blanc"></i>\n' +
    '      <span class="bold">{{::\'DEFAULT_MODULS\' | translate}}</span>\n' +
    '    </div>\n' +
    '    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">\n' +
    '      <div ng-repeat="tcqiApp in ctrl.tcqiApps track by $index" class="tcqihome--obres-apps mdl-cell--2-col">\n' +
    '        <div ng-class="{\'tcqiApps--app-disabled\': !$root.acces[tcqiApp.id].acces}"\n' +
    '             ng-click="ctrl.openObra(undefined, tcqiApp.app, $root.acces[tcqiApp.id].acces)">\n' +
    '          <a>\n' +
    '            <img ng-src="{{tcqiApp.image}}"><br>\n' +
    '            <span class="itec-float-left tcqi--margin-top-10">\n' +
    '              {{::tcqiApp.title | translate | uppercase}}\n' +
    '            </span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="clear"></div>\n' +
    '        <span>{{::tcqiApp.descr}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Obras propias del usuario en sesión -->\n' +
    '  <div ng-if="ctrl.obres.length === 0 && $root.permisos.SISQUILLO.read && !ctrl.searchMode" ng-show="ctrl.showObras">\n' +
    '    {{::\'OBRES_EMPTY_LIST\' | translate}}\n' +
    '  </div>\n' +
    '\n' +
    '  <div ng-if="(ctrl.obres.length > 0 && $root.permisos.SISQUILLO.read) || ctrl.searchMode" ng-show="ctrl.showObras"\n' +
    '      id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">\n' +
    '\n' +
    '    <!-- Cabecera -->\n' +
    '    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing tcqi--padding-bottom-5">\n' +
    '\n' +
    '      <div></div>\n' +
    '\n' +
    '      <div class="mdl-cell--1-col">\n' +
    '        <a ng-click="ctrl.sortType = \'codi\'; ctrl.sortReverse = !ctrl.sortReverse; ctrl.showPagesOrdered(ctrl.sortType, ctrl.sortReverse)" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_CODI\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--3-col">\n' +
    '        <a ng-click="ctrl.sortType = \'descripcio\'; ctrl.sortReverse = !ctrl.sortReverse; ctrl.showPagesOrdered(ctrl.sortType, ctrl.sortReverse)" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_TITOL\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--1-col tcqi--center-text">\n' +
    '        <a ng-click="ctrl.sortType = \'dataCreacio\'; ctrl.sortReverse = !ctrl.sortReverse; ctrl.showPagesOrdered(ctrl.sortType, ctrl.sortReverse)" class="sortable">\n' +
    '          {{::\'DEFAULT_DATA\' | translate}}\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && !ctrl.sortReverse" class="icon-caret-down"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-down tcqi-two-icon-carets"></span>\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && ctrl.sortReverse" class="icon-caret-up"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-up tcqi-two-icon-carets"></span>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--4-col tcqi--center-text"><span>{{::\'DEFAULT_APLICACIONS\' | translate}}</span></div>\n' +
    '      <div class="mdl-cell--2-col tcqi--center-text"><span>{{::\'DEFAULT_ACCIONS\' | translate}}</span></div>\n' +
    '    </div>\n' +
    '\n' +
    '<!-- \n' +
    '    |\n' +
    '    orderBy:ctrl.sortType:ctrl.sortReverse track by $index -->\n' +
    '\n' +
    '    <!-- Ítems. Formato estándar. -->\n' +
    '    <div ng-if="ctrl.listMode" id="tcqihome--obres-list-tbody"\n' +
    '         class="tcqi--table-tbody tcqi--margin-top-10 tcqi--with-scroll"\n' +
    '         ng-class="{\'tcqi--not-recalculate-height\': ctrl.obrescompartides === null}"\n' +
    '         itec-infinite-scroll="(ctrl.searchText === \'\' ? ctrl.loadSisquillos(true) : ctrl.loadSearchSisquillos(true))" threshold="1000" can-load="ctrl.canLoad"\n' +
    '         ng-scroll="ctrl.handleScroll()">\n' +
    '\n' +
    '\n' +
    '      <div id="obra{{obra.id}}" ng-init="ctrl.renderComplete($last, obra.id)" ng-repeat="obra in ctrl.obres"\n' +
    '           class="mdl-grid mdl-grid--no-spacing " ng-class="{\'tcqihome--subobres-expand\': obra.isSubObraExpand}"\n' +
    '>\n' +
    '          <div ng-include="\'obres/obra.tpl.html\'" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "></div>\n' +
    '            <div ng-repeat="obra in obra.subSisquillos"\n' +
    '                 class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "  ng-show="obra.visible"\n' +
    '                  ng-include="\'obres/obra.tpl.html\'">\n' +
    '            </div>\n' +
    '          </div> \n' +
    '        </div>\n' +
    '   \n' +
    '\n' +
    '    <!-- Ítems. Formato cuadrícula -->\n' +
    '    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">\n' +
    '      <div ng-repeat="obra in ctrl.obres track by $index"\n' +
    '          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">\n' +
    '\n' +
    '        <div class="tcqihome-gallery-element">\n' +
    '          <div class="tcqihome-gallery-imatge">\n' +
    '            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"\n' +
    '              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">\n' +
    '\n' +
    '            <img ng-if="!obra.imatge" class="ng-thumb"\n' +
    '                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"\n' +
    '                 ng-click="ctrl.viewDades(obra.id)">\n' +
    '          </div>\n' +
    '\n' +
    '          <div><span>{{obra.descripcio}}</span></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Obras compartidas -->\n' +
    '  <div ng-if="(ctrl.entitats.length > 0) && $root.permisos.SISQUILLO.read"\n' +
    '    ng-show="ctrl.showObras"\n' +
    '      id="tcqihome--obrescompartides" class="tcqi-table mdl-cell--12-col">\n' +
    '\n' +
    '\n' +
    '    <!-- Ítems. Formato estándar. -->\n' +
    '    <div class="tcqihome--obres-title tcqi--margin-left-30 tcqi--margin-bottom-40">\n' +
    '\n' +
    '      <i class="material-icons itec-float-left">folder</i>\n' +
    '      <span class="bold">{{::\'OBRES_COMPARTIDES\' | translate}}</span>\n' +
    '\n' +
    '\n' +
    '      <!-- Entidades -->\n' +
    '      <div ng-if="ctrl.entitats.length > 0 && $root.permisos.SISQUILLO.read" id="tcqihome--entitats"\n' +
    '          class="itec-float-right tcqi--margin-right-30">\n' +
    '        <form>\n' +
    '          <itec-select\n' +
    '              name="entitat"\n' +
    '              label="{{::\'DEFAULT_ENTITAT\' | translate}}"\n' +
    '              list="ctrl.entitats"\n' +
    '              property-label="nom"\n' +
    '              ng-model="ctrl.entitatSelected"\n' +
    '              property-id="id"\n' +
    '              ng-change-function="ctrl.showObresByEntitat(true)"\n' +
    '              max-chars="25">\n' +
    '          </itec-select>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div id="tcqihome--obrescompartides-list">\n' +
    '\n' +
    '      <!-- Cabecera -->\n' +
    '      <div ng-if="(ctrl.listMode && ctrl.obres.length > 0) || ctrl.searchMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing\n' +
    '          tcqi--margin-bottom-10 tcqi--padding-bottom-5 tcqi--all-width">\n' +
    '\n' +
    '        <div class="tcqi--center-text"></div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'codi\';\n' +
    '                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '\n' +
    '            <span>{{::\'DEFAULT_CODI\' | translate}}</span>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'codi\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'codi\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'codi\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'codi\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--3-col">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'descripcio\';\n' +
    '              ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '\n' +
    '            <span>{{::\'DEFAULT_TITOL\' | translate}}</span>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'descripcio\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'descripcio\'"\n' +
    '                class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'descripcio\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'descripcio\'"\n' +
    '                class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col tcqi--center-text">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'dataCreacio\';\n' +
    '                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '            {{::\'DEFAULT_DATA\' | translate}}\n' +
    '\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas === \'dataCreacio\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></span>\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas !== \'dataCreacio\'"\n' +
    '                class="icon-caret-down tcqi-two-icon-carets"></span>\n' +
    '\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas === \'dataCreacio\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></span>\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas !== \'dataCreacio\'"\n' +
    '                class="icon-caret-up tcqi-two-icon-carets"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--4-col tcqi--center-text"><span>{{::\'DEFAULT_APLICACIONS\' | translate}}</span></div>\n' +
    '        <div class="mdl-cell--2-col tcqi--center-text"><span>{{::\'DEFAULT_ACCIONS\' | translate}}</span></div>\n' +
    '      </div>\n' +
    '\n' +
    '      <!-- Ítems -->\n' +
    '      <div class="tcqi--table-tbody">\n' +
    '        <div ng-repeat="obra in ctrl.obrescompartides | \n' +
    '                        orderBy:ctrl.sortTypeObrasCompartidas:ctrl.sortReverseObrasCompartidas track by $index"\n' +
    '             class="mdl-grid mdl-grid--no-spacing" itec-on-finish-render="ngRepeatFinished">\n' +
    '          <div class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres ">\n' +
    '              <div class="tcqi--center-text">\n' +
    '                <i ng-if="obra.isSisquilloCompartit" class="material-icons">person</i>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"\n' +
    '                  ng-click="ctrl.viewDadesCompartides(obra.id)" title="{{::obra.codi}}">\n' +
    '                <span>{{obra.codi}}</span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"\n' +
    '                  ng-click="ctrl.viewDadesCompartides(obra.id)">\n' +
    '                <span>{{obra.descripcio}}</span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-20"\n' +
    '                  ng-click="ctrl.viewDadesCompartides(obra.id,obra.dadesgenerals)">\n' +
    '                <span ng-if="obra.dataCreacio !== \'DEFAULT_NOW\'" class="tcqi--padding-left-15">\n' +
    '                  {{obra.dataCreacio | date: \'itecDate\'}}\n' +
    '                </span>\n' +
    '                <span ng-if="obra.dataCreacio === \'DEFAULT_NOW\'" class="tcqi--padding-left-15">\n' +
    '                  {{obra.dataCreacio | translate}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-apps">\n' +
    '                <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '\n' +
    '                <!-- banc -->\n' +
    '                  <button ng-if ="false" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ban-color"\n' +
    '                          ng-class="{\n' +
    '                          \'tcqi-ban-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && !obra.tebanc,\n' +
    '                          \'tcqi-ban-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces)\n' +
    '                           && obra.tebanc,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                          ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_BANC,\n' +
    '                          ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC)\n' +
    '                          && $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces,true)"\n' +
    '                          ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC)\n' +
    '                                      || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces">\n' +
    '                    <i class="icon-tcqi-icona-ban" title="{{ctrl.titlemodul(obra.tebanc,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_BANC) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces)}}"></i>\n' +
    '                  </button>\n' +
    '\n' +
    '                  <!-- pressupost -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"\n' +
    '                          ng-class="{\'tcqi-pre-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)\n' +
    '                          && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && !obra.tepressupost,\n' +
    '                          \'tcqi-pre-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)\n' +
    '                          || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces) && obra.tepressupost}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST)\n' +
    '                                    || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces">\n' +
    '                  <i class="icon-tcqi-icona-pre" title="{{ctrl.titlemodul(obra.tepressupost,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- certificacio -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"\n' +
    '                ng-class="{\n' +
    '                \'tcqi-seg-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && !obra.teSeguiment,\n' +
    '                \'tcqi-seg-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces)\n' +
    '                 && obra.teSeguiment,\n' +
    '                 \'tcqihome--hidden\': obra.idpare!==null && obra.tipusSubSisquillo===2}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces">\n' +
    '                  <i class="icon-tcqi-icona-cer-1" title="{{ctrl.titlemodul(obra.teSeguiment,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- planificacio -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-pla-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && !obra.tepladetreballs,\n' +
    '                        \'tcqi-pla-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces)\n' +
    '                         && obra.tepladetreballs,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces">\n' +
    '                  <i class="icon-tcqi-icona-pla"  title="{{ctrl.titlemodul(obra.tepladetreballs,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- qualitat -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-gcq-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && !obra.tequalitat,\n' +
    '                        \'tcqi-gcq-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces)\n' +
    '                        && obra.tequalitat,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces">\n' +
    '                  <i class="icon-tcqi-icona-gcq" title="{{ctrl.titlemodul(obra.tequalitat,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_QUALITAT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- mediambient -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-gma-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,\n' +
    '                        \'tcqi-gma-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces)\n' +
    '                        && obra.temediambient,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces">\n' +
    '                  <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- seguretat i salut -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ess-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-ess-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && !obra.teseguretatisalut,\n' +
    '                        \'tcqi-ess-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces)\n' +
    '                         && obra.teseguretatisalut,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT)\n' +
    '                         || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces">\n' +
    '                  <i class="icon-tcqi-icona-ess" title="{{ctrl.titlemodul(obra.teseguretatisalut,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- control de costos -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces,true)"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-cdc-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && !obra.tecontrolcostos,\n' +
    '                        \'tcqi-cdc-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces)\n' +
    '                         && obra.tecontrolcostos,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces">\n' +
    '                  <i class="icon-tcqi-icona-gcc"  title="{{ctrl.titlemodul(obra.tecontrolcostos,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces)}}"></i>\n' +
    '                </button>\n' +
    '\n' +
    '                <!-- estimacio de costos -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-edc-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-edc-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && !obra.teestimaciocostos,\n' +
    '                        \'tcqi-edc-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces)\n' +
    '                         && obra.teestimaciocostos,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces">\n' +
    '                  <i class="icon-tcqi-icona-edc" title="{{ctrl.titlemodul(obra.teestimaciocostos,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces)}}"></i>\n' +
    '                </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">\n' +
    '                <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '                  <button\n' +
    '                      ng-disabled="!obra.dadesgenerals || !$root.permisos.SISQUILLO.edit"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                      title="{{::\'DEFAULT_DADES_GENERALS\' | translate}}"\n' +
    '                      ng-click="ctrl.viewDadesCompartides(obra.id,obra.dadesgenerals)">\n' +
    '                    <i class="material-icons">description</i>\n' +
    '                  </button>\n' +
    '\n' +
    '                  <button ng-disabled="!obra.eliminarobra || !$root.permisos.SISQUILLO.delete"\n' +
    '                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                          title="{{::\'DEFAULT_ESBORRAR_OBRA\' | translate}}"\n' +
    '                          itec-dialog-confirm\n' +
    '                          itec-dialog-confirm-title="OBRES_DELETE_TITLE"\n' +
    '                          itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"\n' +
    '                          itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"\n' +
    '                          itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"\n' +
    '                          itec-dialog-confirm-function-ok="ctrl.esborrar(obra,true)">\n' +
    '                    <i class="material-icons">delete_forever</i>\n' +
    '                  </button>\n' +
    '\n' +
    '                  <tcq-bim-form-upload\n' +
    '                    ng-if="false && obra.importarbim && $root.permisos.SISQUILLO_CARREGAR_BIM.read"\n' +
    '                    is-new = "false"\n' +
    '                    obra-value = "obra"\n' +
    '                    dades-generals="ctrl.dadesGeneralsData"\n' +
    '                    save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '                    max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '                    max-size="10MB"\n' +
    '                    show-only-icon="true"\n' +
    '                    on-finish-function="ctrl.viewDadesCompartides"\n' +
    '                    server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '                    server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '                    class="itec-float-right"\n' +
    '                    icon-left-class="icon-home-importar-bim"\n' +
    '                    title="{{\'BIM_ARXIUS_ADD_TITLE\' | translate}}"\n' +
    '                    show-toolbar-dialog="true">\n' +
    '                  </tcq-bim-form-upload>\n' +
    '\n' +
    '                  <button ng-disabled="!obra.exportartcqi || !$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"\n' +
    '                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                          title="{{::\'DEFAULT_EXPORTAR\' | translate}}"\n' +
    '                          ng-click="ctrl.exportarObra(obra.id,true)">\n' +
    '                    <i class="icon-home-export-tcqi"></i>\n' +
    '                  </button>\n' +
    '\n' +
    '                  <button ng-disabled="!obra.exportarfie || !$root.permisos.SISQUILLO_EXPORTAR_FIE.read"\n' +
    '                          id="fie"\n' +
    '                          class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                          title="{{::\'DEFAULT_EXPORTAR_FIE\' | translate}}"\n' +
    '                          ng-click="ctrl.exportarObraFIE(obra.id,true)">\n' +
    '                     <i class="icon-home-export-fie"></i>\n' +
    '                  </button>\n' +
    '\n' +
    '                  <button\n' +
    '                      ng-disabled="!obra.tornaracompartir || !$root.permisos.OBRES_COMPARTIR.read"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqihome--share-button"\n' +
    '                      title="{{::\'DEFAULT_COMPARTIR_OBRA\' | translate}}"\n' +
    '                      itec-dialog\n' +
    '                      itec-dialog-title="{{::\'DEFAULT_COMPARTIR_OBRA\' | translate}}"\n' +
    '                      itec-dialog-template-url="obres/obres-compartir.tpl.html"\n' +
    '                      itec-dialog-controller="ctrl"\n' +
    '                      itec-dialog-click-outside-to-close="false"\n' +
    '                      itec-dialog-function-init="ctrl.showDialogCompartirObra(obra.id,true)">\n' +
    '                    <!-- <i class="icon-gma-formacio-ozo-aire-1X"></i> -->\n' +
    '                    <i class="material-icons">share</i>\n' +
    '                  </button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/obres-compartir.tpl.html',
    '<div id="compartir-obra-form" ng-cloak>\n' +
    '\n' +
    '  <!-- Formulario con el nombre del usuario -->\n' +
    '  <div ng-if="!ctrl.showCompartirModulos">\n' +
    '    <itec-form\n' +
    '      ng-if="$root.permisos.SISQUILLO.add"\n' +
    '      name="ctrl.formulari.nom"\n' +
    '      fields="ctrl.formulari.camps"\n' +
    '      ng-model="ctrl.obraCompartidaObj"\n' +
    '      ng-submit-function="ctrl.formulari.func()"\n' +
    '      errors="ctrl.errors"\n' +
    '      submit-button-text="DEFAULT_CONTINUE"\n' +
    '      submit-button-icon-right="ctrl.continueButton">\n' +
    '    </itec-form>\n' +
    '\n' +
    '    <p ng-if ="ctrl.sisquillosCompartits.compartidaperuser != null" id="compartir-obra-form--compartitPer-title"\n' +
    '        class="tcqi--margin-left-30 tcqi--margin-bottom-20">\n' +
    '      <span>Obra compartida per {{ctrl.sisquillosCompartits.compartidaperuser}}.</span>\n' +
    '    </p>\n' +
    '\n' +
    '    <md-card ng-show="ctrl.sisquillosCompartits.listsisquillocompartit.length > 0 && $root.permisos.SISQUILLO.read"\n' +
    '        id="list-sisquillo-compartit" class="tcqi--margin-top-10 tcqi--margin-10">\n' +
    '\n' +
    '      <md-card-content>\n' +
    '        <div ng-repeat="sisquilloCompartit in ctrl.sisquillosCompartits.listsisquillocompartit track by $index"\n' +
    '             class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '          <div class="mdl-cell--10-col">{{sisquilloCompartit.username}}</div>\n' +
    '\n' +
    '          <div class="mdl-cell--2-col">\n' +
    '            <button ng-if="$root.permisos.SISQUILLO.delete"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"\n' +
    '                    title="{{::\'DEFAULT_ESBORRAR_OBRA_COMPARTIDA\' | translate}}"\n' +
    '                    itec-dialog-confirm\n' +
    '                    itec-dialog-confirm-title="OBRES_DELETE_COMPARTIT_TITLE"\n' +
    '                    itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT_COMPARTIT"\n' +
    '                    itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"\n' +
    '                    itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"\n' +
    '                    itec-dialog-confirm-function-ok=\n' +
    '                        "ctrl.esborrarSisquilloCompartit(sisquilloCompartit.id, $index,sisquilloCompartit.identitatcompartida)">\n' +
    '              <i class="material-icons">delete_forever</i>\n' +
    '            </button>\n' +
    '\n' +
    '            <button ng-if="$root.permisos.SISQUILLO.edit"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"\n' +
    '                    title="{{::\'DEFAULT_EDITAR_OBRA_COMPARTIDA\' | translate}}"\n' +
    '                    ng-click="ctrl.editarSisquilloCompartit(sisquilloCompartit.id,sisquilloCompartit.username)">\n' +
    '                    <i class="material-icons">edit</i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </md-card-content>\n' +
    '    </md-card>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <!-- Formulario con los módulos y permisos -->\n' +
    '  <div ng-if="ctrl.showCompartirModulos" id="tcqihome--obres-permisos" class="tcqi--padding-30">\n' +
    '    <h5 class="bold"><span>{{ctrl.obraCompartidaObj.username}}</span></h5>\n' +
    '\n' +
    '    <itec-button class="itec-float-right" ng-click-function="ctrl.back()"\n' +
    '      label="{{\'DEFAULT_TORNAR\' | translate}}" icon-left="ctrl.backButton"></itec-button>\n' +
    '\n' +
    '\n' +
    '    <div id="tcqihome--obres-modulos" class="tcqi--padding-10 tcqi--padding-left-30">\n' +
    '      <div id="tcqihome--obres-modulos-title" class="tcqi--margin-bottom-20">\n' +
    '        {{\'obra.compartida.modulos\' | translate }}:\n' +
    '      </div>\n' +
    '\n' +
    '      <a ng-show="!ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=true;">\n' +
    '        {{\'obra.compartida.mostrar.permisos\' | translate}}\n' +
    '      </a>\n' +
    '      <a ng-show="ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=false;">\n' +
    '        {{\'obra.compartida.ocultar.permisos\' | translate}}\n' +
    '      </a>\n' +
    '\n' +
    '      <form>\n' +
    '        <div ng-repeat="permiso in ctrl.permisosModulsUsername track by $index"\n' +
    '            class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '          <div class="mdl-cell--4-col">\n' +
    '          <itec-checkbox ng-if="permiso.acces"\n' +
    '            label="{{permiso.nom}}"\n' +
    '            ng-model="permiso.id"\n' +
    '            ng-class="{\'bold\': permiso.id}">\n' +
    '          </itec-checkbox>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-show="ctrl.showrols" class="rols mdl-cell--8-col">\n' +
    '          <itec-select\n' +
    '            ng-if="permiso.acces"\n' +
    '            empty-option="true"\n' +
    '            name="rol"\n' +
    '            list="permiso.listrols"\n' +
    '            property-label="nom"\n' +
    '            ng-model="permiso.rol"\n' +
    '            property-id="id"\n' +
    '            class="itec-float-right">\n' +
    '          </itec-select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="compartir-obra-form--actions-to-share"\n' +
    '        class="mdl-grid mdl-grid--no-spacing tcqi--margin-top-10 tcqi--padding-10 tcqi--padding-left-30">\n' +
    '\n' +
    '      <div class="mdl-cell--6-col">\n' +
    '        <itec-checkbox label="SISQUILLO_COMPARTIT_AGAIN" ng-model="ctrl.checkAgain"></itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_DATOS_GENERALES" ng-model="ctrl.checkDatosGenerales"></itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_ELIMINAR_OBRA" ng-model="ctrl.checkRemoveObra"></itec-checkbox>\n' +
    '      </div>\n' +
    '      <div class="mdl-cell--6-col">\n' +
    '        <itec-checkbox ng-if="false" label="SISQUILLO_IMPORTAR_BIM" ng-model="ctrl.checkImportBim" >\n' +
    '        </itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_EXPORTAR_TCQI" ng-model="ctrl.checkExportTcqi" >\n' +
    '        </itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_EXPORTAR_FIE" ng-model="ctrl.checkExportFie" >\n' +
    '        </itec-checkbox>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <itec-button class="itec-float-right tcqi--margin-top-10 tcqi--margin-bottom-20"\n' +
    '      ng-click-function="ctrl.shareSisquillo()" label="{{\'DEFAULT_COMPARTIR\' | translate}}">\n' +
    '    </itec-button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('obres/obra.tpl.html',
    '\n' +
    '         <div class="mdl-cell--1-col tcqi--ellipsis tcqihome--expand-subsiquillos"\n' +
    '                              ng-class="{\'tcqi--text-align-right\': obra.isSubobra}">\n' +
    '\n' +
    '            <i ng-if="obra.isSisquilloCompartit" class="material-icons"\n' +
    '             ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra, \'tcqihome--subobres\':obra.isSubobra}">person</i>\n' +
    '\n' +
    '             <div ng-if="ctrl.tesubsisquillos(obra)" class="tcqi--text-align-right tcqihome--obres-table-tbody-td-subobres">\n' +
    '                 <a ng-if="!obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(true,obra);">\n' +
    '                   <i class="icon-gui-arrow-closed tcqi--text-align-right"></i>\n' +
    '                 </a>\n' +
    '                 <a  ng-if="obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(false,obra);">\n' +
    '                   <i class="icon-gui-arrow-open tcqi--text-align-right"></i>\n' +
    '                 </a>\n' +
    '              </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"\n' +
    '            ng-click="ctrl.viewDades(obra.id)" title="{{::obra.codi}}">\n' +
    '          <span  ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra}" >{{obra.codi}}</span>\n' +
    '        </div>\n' +
    '        <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"\n' +
    '            ng-click="ctrl.viewDades(obra.id)">\n' +
    '          <span ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra}">{{obra.descripcio}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '          <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-30"\n' +
    '              ng-click="ctrl.viewDades(obra.id)">\n' +
    '            <span ng-if="obra.dataCreacio !== \'DEFAULT_NOW\'">\n' +
    '              {{obra.dataCreacio | date: \'itecDate\'}}\n' +
    '            </span>\n' +
    '\n' +
    '            <span ng-if="obra.dataCreacio === \'DEFAULT_NOW\'">\n' +
    '              {{obra.dataCreacio | translate}}\n' +
    '            </span>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-apps">\n' +
    '            <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '                <!-- banc -->\n' +
    '                <button ng-if ="false" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ban-color"\n' +
    '                        ng-class="{\'tcqi-ban-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && !obra.tebanc,\n' +
    '                        \'tcqi-ban-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && obra.tebanc,\n' +
    '                         \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_BANC,\n' +
    '                          $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces,false)"\n' +
    '                        ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces">\n' +
    '                  <i class="icon-tcqi-icona-ban" title="{{ctrl.titlemodul(obra.tebanc,$root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces)}}" ></i>\n' +
    '                </button>\n' +
    '              <!-- </div> -->\n' +
    '\n' +
    '              <!-- pressupost -->\n' +
    '              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST,\n' +
    '                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces,false)"\n' +
    '                      ng-class="{\'tcqi-pre-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && !obra.tepressupost,\n' +
    '                      \'tcqi-pre-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && obra.tepressupost}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces">\n' +
    '                <i class="icon-tcqi-icona-pre" title="{{ctrl.titlemodul(obra.tepressupost,$root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- certificacio -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC,\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces,false)"\n' +
    '                        ng-class="{\'tcqi-seg-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && !obra.teSeguiment,\n' +
    '                        \'tcqi-seg-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && obra.teSeguiment,\n' +
    '                        \'tcqihome--hidden\': obra.isSubobra && obra.tipusSubSisquillo===2}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces">\n' +
    '                <i class="icon-tcqi-icona-cer-1" title="{{ctrl.titlemodul(obra.teSeguiment,$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- planificacio -->\n' +
    '              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO,\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces,false)"\n' +
    '                      ng-class="{\'tcqi-pla-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && !obra.tepladetreballs,\n' +
    '                      \'tcqi-pla-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && obra.tepladetreballs,\n' +
    '                     \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces">\n' +
    '                <i class="icon-tcqi-icona-pla" title="{{ctrl.titlemodul(obra.tepladetreballs,$root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- qualitat -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT,\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces,false)"\n' +
    '                      ng-class="{\'tcqi-gcq-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && !obra.tequalitat,\n' +
    '                      \'tcqi-gcq-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && obra.tequalitat,\n' +
    '                     \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces">\n' +
    '                <i class="icon-tcqi-icona-gcq"  title="{{ctrl.titlemodul(obra.tequalitat,$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- mediambient -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,\n' +
    '                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,false)"\n' +
    '                      ng-class="{\'tcqi-gma-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,\n' +
    '                      \'tcqi-gma-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && obra.temediambient,\n' +
    '                      \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces">\n' +
    '                <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- seguretat i salut -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ess-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT,\n' +
    '                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces,false)"\n' +
    '                      ng-class="{\'tcqi-ess-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && !obra.teseguretatisalut,\n' +
    '                      \'tcqi-ess-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && obra.teseguretatisalut,\n' +
    '                      \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces">\n' +
    '                <i class="icon-tcqi-icona-ess" title="{{ctrl.titlemodul(obra.teseguretatisalut,$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- control costos -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS,\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces,false)"\n' +
    '                      ng-class="{\'tcqi-cdc-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && !obra.tecontrolcostos,\n' +
    '                      \'tcqi-cdc-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && obra.tecontrolcostos,\n' +
    '                      \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces">\n' +
    '                <i class="icon-tcqi-icona-gcc" title="{{ctrl.titlemodul(obra.tecontrolcostos,$root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- estimaciocostos -->\n' +
    '              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-edc-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces,false)"\n' +
    '                      ng-class="{\'tcqi-edc-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && !obra.teestimaciocostos,\n' +
    '                      \'tcqi-edc-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && obra.teestimaciocostos,\n' +
    '                      \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces">\n' +
    '                <i class="icon-tcqi-icona-edc"  title="{{ctrl.titlemodul(obra.teestimaciocostos,$root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <!-- Mantenim Circular -->\n' +
    '               <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-circular-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, \'circular\',\n' +
    '                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_CIRCULAR].acces,false)"\n' +
    '                      ng-class="{\'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CIRCULAR}}].acces">\n' +
    '                <i class="icon-circular"  title="{{ctrl.titlemodulcircular($root.acces[ctrl.ApiLayoutConstant.APP_ID_CIRCULAR].acces)}}"></i>\n' +
    '              </button>\n' +
    '             </div>\n' +
    '          </div>\n' +
    '\n' +
    '          <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">\n' +
    '            <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '\n' +
    '              <button\n' +
    '                  ng-disabled="!$root.permisos.SISQUILLO.edit"\n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                  title="{{::\'DEFAULT_DADES_GENERALS\' | translate}}"\n' +
    '                  ng-click="ctrl.viewDades(obra.id)">\n' +
    '                <i class="material-icons">description</i>\n' +
    '              </button>\n' +
    '\n' +
    '              <button ng-disabled="!$root.permisos.SISQUILLO.delete"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                      title="{{::\'DEFAULT_ESBORRAR_OBRA\' | translate}}"\n' +
    '                      itec-dialog-confirm\n' +
    '                      itec-dialog-confirm-title="OBRES_DELETE_TITLE"\n' +
    '                      itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"\n' +
    '                      itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"\n' +
    '                      itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"\n' +
    '                      itec-dialog-confirm-function-ok="ctrl.esborrar(obra)">\n' +
    '                <i class="material-icons">delete_forever</i>\n' +
    '              </button>\n' +
    '              <button\n' +
    '                  ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"\n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                  title="{{::\'DEFAULT_ACCIONS_COPIAR\' | translate}}"\n' +
    '                  ng-click="ctrl.copiarObra(obra.id)">\n' +
    '                <i class="material-icons">file_copy</i>\n' +
    '              </button>\n' +
    '\n' +
    '              <tcq-bim-form-upload\n' +
    '                ng-if="$root.permisos.SISQUILLO_CARREGAR_BIM.read"\n' +
    '                is-new = "false"\n' +
    '                obra-value = "obra"\n' +
    '                dades-generals="ctrl.dadesGeneralsData"\n' +
    '                save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"\n' +
    '                max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"\n' +
    '                max-size="10MB"\n' +
    '                show-only-icon="true"\n' +
    '                on-finish-function="ctrl.viewDades"\n' +
    '                server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"\n' +
    '                server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"\n' +
    '                class="itec-float-right"\n' +
    '                icon-left-class="icon-home-importar-bim"\n' +
    '                title="{{\'BIM_ARXIUS_ADD_TITLE\' | translate}}"\n' +
    '                show-toolbar-dialog="true">\n' +
    '              </tcq-bim-form-upload>\n' +
    '\n' +
    '              <button ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                      title="{{::\'DEFAULT_EXPORTAR\' | translate}}"\n' +
    '                      ng-click="ctrl.exportarObra(obra.id)">\n' +
    '                <i class="icon-home-export-tcqi"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <button ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_FIE.read"\n' +
    '                      id="fie"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                      title="{{::\'DEFAULT_EXPORTAR_FIE\' | translate}}"\n' +
    '                      ng-click="ctrl.exportarObraFIE(obra.id)">\n' +
    '                 <i class="icon-home-export-fie"></i>\n' +
    '              </button>\n' +
    '\n' +
    '              <button\n' +
    '                  ng-disabled="!$root.permisos.OBRES_COMPARTIR.read"\n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqihome--share-button"\n' +
    '                  title="{{::\'DEFAULT_COMPARTIR_OBRA\' | translate}}"\n' +
    '                  itec-dialog\n' +
    '                  itec-dialog-title="{{::\'DEFAULT_COMPARTIR_OBRA\' | translate}} \'\'{{obra.codi}}\'\'"\n' +
    '                  itec-dialog-template-url="obres/obres-compartir.tpl.html"\n' +
    '                  itec-dialog-controller="ctrl"\n' +
    '                  itec-dialog-click-outside-to-close="false"\n' +
    '                  itec-dialog-function-init="ctrl.showDialogCompartirObra(obra.id,false)">\n' +
    '                <!-- <i class="icon-gma-formacio-ozo-aire-1X"></i> -->\n' +
    '                <i class="material-icons">share</i>\n' +
    '              </button>\n' +
    '              \n' +
    '              <!-- TCQi Cities: vincular Obres a projectes TCQi Cities -->\n' +
    '              <button \n' +
    '                      ng-if="$root.acces[ctrl.ApiLayoutConstant.APP_ID_POCITYF].acces"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '                      ng-class="{\'tcqi-cities-no-te-projectes-vinculats\': !obra.teProjectesTCQiCities}"\n' +
    '                      title="{{(obra.teProjectesTCQiCities ? \'DEFAULT_TE_PROJECTES_TCQICITIES\'  : \'DEFAULT_NO_TE_PROJECTES_TCQICITIES\') | translate}}"\n' +
    '                      ng-click="ctrl.vincularObraTCQiCities(obra)">\n' +
    '                 <i class="icon-IconCity2 icon-cities-size"></i>\n' +
    '              </button>\n' +
    '\n' +
    '            </div>\n' +
    '          </div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('configuracio/configuracio.tpl.html',
    '<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '      <itec-button class="itec-float-right tcqi--margin-top-10" label="DEFAULT_TORNAR" ng-click-function="ctrl.back()"\n' +
    '        icon-left="ctrl.backButton"></itec-button>\n' +
    '  </div>\n' +
    '\n' +
    '  <div class="mdl-cell--12-col tcqi--margin-bottom-20">\n' +
    '    <itec-group-form\n' +
    '        name="ctrl.formulari.nom"\n' +
    '        fields="ctrl.formulari.grups"\n' +
    '        ng-model="ctrl.configuracio"\n' +
    '        ng-submit-function="ctrl.formulari.func()"\n' +
    '        errors="ctrl.errors"\n' +
    '        backbutton="false">\n' +
    '      <div transclude-to="configuracio-copydrag-block">\n' +
    '        <itec-checkbox-group\n' +
    '          list="ctrl.configuracioList"\n' +
    '          ng-model="ctrl.configuracio.copyDragConf"\n' +
    '          ng-disabled="false"\n' +
    '          label="DEFAULT_CONFIGURACIO_COPYDRAG"\n' +
    '          simple="true">\n' +
    '        </itec-checkbox-group>\n' +
    '      </div>\n' +
    '    </itec-group-form>\n' +
    '  </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('compensacions/obres-compartir.tpl.html',
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('compensacions/obra.tpl.html',
    '\n' +
    '         <div class="mdl-cell--1-col tcqi--ellipsis tcqihome--expand-subsiquillos"\n' +
    '                              ng-class="{\'tcqi--text-align-right\': obra.isSubobra}">\n' +
    '\n' +
    '            <i ng-if="obra.isSisquilloCompartit" class="material-icons"\n' +
    '             ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra, \'tcqihome--subobres\':obra.isSubobra}">person</i>\n' +
    '\n' +
    '             <div ng-if="ctrl.tesubsisquillos(obra)" class="tcqi--text-align-right tcqihome--obres-table-tbody-td-subobres">\n' +
    '                 <a ng-if="!obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(true,obra);">\n' +
    '                   <i class="icon-gui-arrow-closed tcqi--text-align-right"></i>\n' +
    '                 </a>\n' +
    '                 <a  ng-if="obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(false,obra);">\n' +
    '                   <i class="icon-gui-arrow-open tcqi--text-align-right"></i>\n' +
    '                 </a>\n' +
    '              </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"\n' +
    '            title="{{::obra.codi}}">\n' +
    '          <span  ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra}" >{{obra.codi}}</span>\n' +
    '        </div>\n' +
    '        <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"\n' +
    '            >\n' +
    '          <span ng-class="{\'tcqihome--subobra-ident\': obra.isSubobra}">{{obra.descripcio}}</span>\n' +
    '        </div>\n' +
    '\n' +
    '          <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-30"\n' +
    '             >\n' +
    '            <span ng-if="obra.dataCreacio !== \'DEFAULT_NOW\'">\n' +
    '              {{obra.dataCreacio | date: \'itecDate\'}}\n' +
    '            </span>\n' +
    '\n' +
    '            <span ng-if="obra.dataCreacio === \'DEFAULT_NOW\'">\n' +
    '              {{obra.dataCreacio | translate}}\n' +
    '            </span>\n' +
    '          </div>\n' +
    '\n' +
    '          \n' +
    '          <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-acciones">\n' +
    '            <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '               <div style="width:12.5%">\n' +
    '                <button ng-if="!obra.isSubobra"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon "\n' +
    '                    title="{{::\'DEFAULT_FASE_PRODUCTE\' | translate}}">\n' +
    '                    <i class="icon-gma-fase-fabricacio color-fabricacio "></i>\n' +
    '                </button></br>\n' +
    '                <span>\n' +
    '                  {{obra.emisionsHome.fabricacio | number : 2}}\n' +
    '                </span>\n' +
    '               </div>\n' +
    '                <div style="width:12.5%">\n' +
    '                  <div ng-if="obra.emisions.compensadesFabricacio > 0">\n' +
    '                   <button ng-if="!obra.isSubobra"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                  </button></br>\n' +
    '                 {{obra.emisionsHome.compensadesFabricacio | number : 2}}\n' +
    '                    </div>\n' +
    '               </div>\n' +
    '               <div style="width:12.5%">\n' +
    '                <button ng-if="!obra.isSubobra"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                    title="{{::\'DEFAULT_FASE_CONSTRUCCIO\' | translate}}">\n' +
    '                    <i class="icon-gma-fase-construccio color-construccio"></i>\n' +
    '                </button></br>\n' +
    '                 <span>\n' +
    '                  {{obra.emisionsHome.construccio | number : 2}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '                 <div style="width:12.5%">\n' +
    '                   <div ng-if="obra.emisions.compensadesConstruccio > 0">\n' +
    '                 <button ng-if="!obra.isSubobra"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                  </button></br>\n' +
    '                 {{obra.emisionsHome.compensadesConstruccio | number : 2}}\n' +
    '                 </div>\n' +
    '                </div>\n' +
    '                   <div style="width:12.5%">\n' +
    '                  <button ng-if="!obra.isSubobra"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                      title="{{::\'DEFAULT_FASE_US\' | translate}}">\n' +
    '                      <i class="icon-gma-fase-us color-us"></i>\n' +
    '                  </button></br>\n' +
    '                  <span>\n' +
    '                  {{obra.emisionsHome.us | number : 2}}\n' +
    '                  </span>\n' +
    '              </div>\n' +
    '                 <div style="width:12.5%">\n' +
    '                  <div ng-if="obra.emisions.compensadesUs > 0">\n' +
    '                   <button ng-if="!obra.isSubobra"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                         <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                  </button></br>\n' +
    '                 {{obra.emisionsHome.compensadesUs | number : 2}}\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '              <div style="width:12.5%">\n' +
    '                <button ng-if="!obra.isSubobra"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                    title="{{::\'DEFAULT_FASE_FINAL_DE_VIDA\' | translate}}">\n' +
    '                    <i class="icon-gma-fase-final-de-vida color-final"></i>\n' +
    '                </button></br>\n' +
    '                <span>\n' +
    '                 {{obra.emisionsHome.finalDeVida | number : 2}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '                 <div style="width:12.5%">\n' +
    '                <div ng-if="obra.emisions.compensadesFinalDeVida > 0">\n' +
    '                <button ng-if="!obra.isSubobra"\n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                  </button></br>\n' +
    '                 {{obra.emisionsHome.compensadesFinalDeVida | number : 2}}\n' +
    '               </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '          </div>\n' +
    '\n' +
    '\n' +
    '          <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-apps">\n' +
    '            <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '               \n' +
    '              <!-- mediambient -->\n' +
    '              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"\n' +
    '                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,\n' +
    '                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,false)"\n' +
    '                      ng-class="{\'tcqi-gma-color-permisos-nodades\':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,\n' +
    '                      \'tcqi-gma-color-no-permisos-dades\':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && obra.temediambient,\n' +
    '                      \'tcqihome--hidden\':obra.isSubobra}"\n' +
    '                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces">\n' +
    '                <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>\n' +
    '              </button>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '          </div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('compensacions/compensacions.tpl.html',
    '<!-- <img src="vendor/itec-icons/images/tcqi-icona-ban.png"/> -->\n' +
    '<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '  <!-- Botones -->\n' +
    '  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-30">\n' +
    '    <div class="tcqihome--obres-title">\n' +
    '      <i class="material-icons">folder</i>\n' +
    '      <span class="bold">{{::\'OBRES_TOTES_OBRES\' | translate}}</span>\n' +
    '      <img class="co2-image" ng-src="images/resources/images/co2.png">\n' +
    '   \n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button\n' +
    '        mdl-button--icon mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode"\n' +
    '        ng-if="(ctrl.obres.length > 0 || ctrl.obrescompartides.length>0)">\n' +
    '\n' +
    '      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_ICONES\' | translate}}">\n' +
    '\n' +
    '      </i>\n' +
    '      <i ng-if="!ctrl.listMode" class="material-icons"\n' +
    '         title="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}"\n' +
    '         aria-label="{{::\'DEFAULT_ACCIONS_VISUALITZAR_LLISTA\' | translate}}">\n' +
    '        format_list_bulleted\n' +
    '      </i>\n' +
    '    </button>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <form ng-if="(ctrl.obrescompartides.length>0 || ctrl.obres.length > 0) && $root.permisos.SISQUILLO.read"\n' +
    '        class="itec-float-right">\n' +
    '\n' +
    '      <div ng-show="ctrl.expanded" class="tcqihome-search itec-float-right mdl-textfield mdl-js-textfield">\n' +
    '        <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText" />\n' +
    '        <label class="mdl-textfield__label" for="search-expandable">\n' +
    '          {{::\'DEFAULT_CERCA_CODI_O_TITOL\' | translate}}\n' +
    '        </label>\n' +
    '      </div>\n' +
    '\n' +
    '      <label class="itec-float-right mdl-button mdl-js-button mdl-button--icon mdl-button--colored"\n' +
    '             ng-click="ctrl.expanded=!ctrl.expanded">\n' +
    '        <i class="material-icons" title="{{::\'DEFAULT_CERCAR\' | translate}}"\n' +
    '            aria-label="{{::\'DEFAULT_CERCAR\' | translate}}">search</i>\n' +
    '      </label>\n' +
    '    </form>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Módulos "TCQi" -->\n' +
    '  <div ng-if="ctrl.obres.length === 0 && ctrl.obrescompartides.length===0" id="tcqihome--obres-no-items">\n' +
    '    <span>{{::\'DEFAULT_NO_ITEM\' | translate}}</span>\n' +
    '    <div class="clear"></div>\n' +
    '\n' +
    '    <div class="tcqihome--obres-title">\n' +
    '      <i class="icon-icono-grups-blanc"></i>\n' +
    '      <span class="bold">{{::\'DEFAULT_MODULS\' | translate}}</span>\n' +
    '    </div>\n' +
    '    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">\n' +
    '      <div ng-repeat="tcqiApp in ctrl.tcqiApps track by $index" class="tcqihome--obres-apps mdl-cell--2-col">\n' +
    '        <div ng-class="{\'tcqiApps--app-disabled\': !$root.acces[tcqiApp.id].acces}"\n' +
    '             ng-click="ctrl.openObra(undefined, tcqiApp.app, $root.acces[tcqiApp.id].acces)">\n' +
    '          <a>\n' +
    '            <img ng-src="{{tcqiApp.image}}"><br>\n' +
    '            <span class="itec-float-left tcqi--margin-top-10">\n' +
    '              {{::tcqiApp.title | translate | uppercase}}\n' +
    '            </span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="clear"></div>\n' +
    '        <span>{{::tcqiApp.descr}}</span>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Obras propias del usuario en sesión -->\n' +
    '  <div ng-if="ctrl.obres.length === 0 && $root.permisos.SISQUILLO.read" ng-show="ctrl.showObras">\n' +
    '    {{::\'OBRES_EMPTY_LIST\' | translate}}\n' +
    '  </div>\n' +
    '  <div ng-if="ctrl.obres.length > 0 && $root.permisos.SISQUILLO.read" ng-show="ctrl.showObras"\n' +
    '      id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">\n' +
    '\n' +
    '    <!-- Cabecera -->\n' +
    '    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing tcqi--padding-bottom-5">\n' +
    '\n' +
    '      <div></div>\n' +
    '\n' +
    '      <div class="mdl-cell--1-col">\n' +
    '        <a ng-click="ctrl.sortType = \'codi\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_CODI\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'codi\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'codi\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--3-col">\n' +
    '        <a ng-click="ctrl.sortType = \'descripcio\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          <span>{{::\'DEFAULT_TITOL\' | translate}}</span>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && !ctrl.sortReverse" class="icon-caret-down"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '          <i ng-show="ctrl.sortType === \'descripcio\' && ctrl.sortReverse" class="icon-caret-up"></i>\n' +
    '          <i ng-show="ctrl.sortType !== \'descripcio\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '\n' +
    '      <div class="mdl-cell--1-col tcqi--center-text">\n' +
    '        <a ng-click="ctrl.sortType = \'dataCreacio\'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">\n' +
    '          {{::\'DEFAULT_DATA\' | translate}}\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && !ctrl.sortReverse" class="icon-caret-down"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-down tcqi-two-icon-carets"></span>\n' +
    '\n' +
    '          <span ng-show="ctrl.sortType === \'dataCreacio\' && ctrl.sortReverse" class="icon-caret-up"></span>\n' +
    '          <span ng-show="ctrl.sortType !== \'dataCreacio\'" class="icon-caret-up tcqi-two-icon-carets"></span>\n' +
    '        </a>\n' +
    '      </div>\n' +
    '        <div class="mdl-cell--4-col tcqi--center-text"><span>{{::\'DEFAULT_EMISSIONS\' | translate}}</span></div>\n' +
    '      <div class="mdl-cell--2-col tcqi--center-text"><span>{{::\'DEFAULT_COMPENSAR\' | translate}}</span></div>\n' +
    '    \n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <!-- Ítems. Formato estándar. -->\n' +
    '    <div ng-if="ctrl.listMode" id="tcqihome--obres-list-tbody"\n' +
    '         class="tcqi--table-tbody tcqi--margin-top-10 tcqi--with-scroll"\n' +
    '         ng-class="{\'tcqi--not-recalculate-height\': ctrl.obrescompartides === null}"\n' +
    '         itec-infinite-scroll="ctrl.loadSisquillos(true)" threshold="100" can-load="ctrl.canLoad">\n' +
    '\n' +
    '\n' +
    '      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText |\n' +
    '                      orderBy:ctrl.sortType:ctrl.sortReverse track by $index"\n' +
    '           class="mdl-grid mdl-grid--no-spacing " ng-class="{\'tcqihome--subobres-expand\': obra.isSubObraExpand}"\n' +
    '           itec-on-finish-render="ngRepeatFinished" >\n' +
    '            <div ng-include="\'compensacions/obra.tpl.html\'" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "></div>\n' +
    '            <div ng-repeat="obra in obra.subSisquillos"\n' +
    '                 class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres " itec-on-finish-render="ngRepeatFinished" ng-show="obra.visible"\n' +
    '                  ng-include="\'compensacions/obra.tpl.html\'">\n' +
    '            </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- Ítems. Formato cuadrícula -->\n' +
    '    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">\n' +
    '      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText track by $index"\n' +
    '          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">\n' +
    '\n' +
    '        <div class="tcqihome-gallery-element">\n' +
    '          <div class="tcqihome-gallery-imatge">\n' +
    '            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"\n' +
    '              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">\n' +
    '\n' +
    '            <img ng-if="!obra.imatge" class="ng-thumb"\n' +
    '                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"\n' +
    '                 ng-click="ctrl.viewDades(obra.id)">\n' +
    '          </div>\n' +
    '\n' +
    '          <div><span>{{obra.descripcio}}</span></div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '  <!-- Obras compartidas -->\n' +
    '  <div ng-if="(ctrl.entitats.length > 0) && $root.permisos.SISQUILLO.read"\n' +
    '    ng-show="ctrl.showObras"\n' +
    '      id="tcqihome--obrescompartides" class="tcqi-table mdl-cell--12-col">\n' +
    '\n' +
    '\n' +
    '    <!-- Ítems. Formato estándar. -->\n' +
    '    <div class="tcqihome--obres-title tcqi--margin-left-30 tcqi--margin-bottom-40">\n' +
    '\n' +
    '      <i class="material-icons itec-float-left">folder</i>\n' +
    '      <span class="bold">{{::\'OBRES_COMPARTIDES\' | translate}}</span>\n' +
    '       <img class="co2-image" ng-src="images/resources/images/co2.png">\n' +
    '\n' +
    '      <!-- Entidades -->\n' +
    '      <div ng-if="ctrl.entitats.length > 0 && $root.permisos.SISQUILLO.read" id="tcqihome--entitats"\n' +
    '          class="itec-float-right tcqi--margin-right-30">\n' +
    '        <form>\n' +
    '          <itec-select\n' +
    '              name="entitat"\n' +
    '              label="{{::\'DEFAULT_ENTITAT\' | translate}}"\n' +
    '              list="ctrl.entitats"\n' +
    '              property-label="nom"\n' +
    '              ng-model="ctrl.entitatSelected"\n' +
    '              property-id="id"\n' +
    '              ng-change-function="ctrl.showObresByEntitat(true)"\n' +
    '              max-chars="25">\n' +
    '          </itec-select>\n' +
    '        </form>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div id="tcqihome--obrescompartides-list">\n' +
    '\n' +
    '      <!-- Cabecera -->\n' +
    '      <div ng-if="ctrl.listMode && ctrl.obres.length > 0" class="tcqi--table-header mdl-grid mdl-grid--no-spacing\n' +
    '          tcqi--margin-bottom-10 tcqi--padding-bottom-5 tcqi--all-width">\n' +
    '\n' +
    '        <div class="tcqi--center-text"></div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'codi\';\n' +
    '                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '\n' +
    '            <span>{{::\'DEFAULT_CODI\' | translate}}</span>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'codi\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'codi\'" class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'codi\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'codi\'" class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--3-col">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'descripcio\';\n' +
    '              ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '\n' +
    '            <span>{{::\'DEFAULT_TITOL\' | translate}}</span>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'descripcio\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'descripcio\'"\n' +
    '                class="icon-caret-down tcqi-two-icon-carets"></i>\n' +
    '\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas === \'descripcio\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></i>\n' +
    '            <i ng-show="ctrl.sortTypeObrasCompartidas !== \'descripcio\'"\n' +
    '                class="icon-caret-up tcqi-two-icon-carets"></i>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="mdl-cell--1-col tcqi--center-text">\n' +
    '          <a ng-click="ctrl.sortTypeObrasCompartidas = \'dataCreacio\';\n' +
    '                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">\n' +
    '            {{::\'DEFAULT_DATA\' | translate}}\n' +
    '\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas === \'dataCreacio\' && !ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-down"></span>\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas !== \'dataCreacio\'"\n' +
    '                class="icon-caret-down tcqi-two-icon-carets"></span>\n' +
    '\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas === \'dataCreacio\' && ctrl.sortReverseObrasCompartidas"\n' +
    '                class="icon-caret-up"></span>\n' +
    '            <span ng-show="ctrl.sortTypeObrasCompartidas !== \'dataCreacio\'"\n' +
    '                class="icon-caret-up tcqi-two-icon-carets"></span>\n' +
    '          </a>\n' +
    '        </div>\n' +
    '\n' +
    '          <div class="mdl-cell--4-col tcqi--center-text"><span>{{::\'DEFAULT_EMISSIONS\' | translate}}</span></div>\n' +
    '        <div class="mdl-cell--2-col tcqi--center-text"><span>{{::\'DEFAULT_COMPENSAR\' | translate}}</span></div>\n' +
    '      \n' +
    '      </div>\n' +
    '\n' +
    '\n' +
    '      <!-- Ítems -->\n' +
    '      <div class="tcqi--table-tbody">\n' +
    '        <div ng-repeat="obra in ctrl.obrescompartides | obraByCodiAndDescripcio:ctrl.searchText |\n' +
    '                        orderBy:ctrl.sortTypeObrasCompartidas:ctrl.sortReverseObrasCompartidas track by $index"\n' +
    '             class="mdl-grid mdl-grid--no-spacing" itec-on-finish-render="ngRepeatFinished">\n' +
    '          <div class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres ">\n' +
    '              <div class="tcqi--center-text">\n' +
    '                <i ng-if="obra.isSisquilloCompartit" class="material-icons">person</i>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"\n' +
    '                  ng-click="ctrl.viewDadesCompartides(obra.id)" title="{{::obra.codi}}">\n' +
    '                <span>{{obra.codi}}</span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"\n' +
    '                  ng-click="ctrl.viewDadesCompartides(obra.id)">\n' +
    '                <span>{{obra.descripcio}}</span>\n' +
    '              </div>\n' +
    '\n' +
    '              <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-20"\n' +
    '                  >\n' +
    '                <span ng-if="obra.dataCreacio !== \'DEFAULT_NOW\'" class="tcqi--padding-left-15">\n' +
    '                  {{obra.dataCreacio | date: \'itecDate\'}}\n' +
    '                </span>\n' +
    '                <span ng-if="obra.dataCreacio === \'DEFAULT_NOW\'" class="tcqi--padding-left-15">\n' +
    '                  {{obra.dataCreacio | translate}}\n' +
    '                </span>\n' +
    '              </div>\n' +
    '\n' +
    '            <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-acciones">\n' +
    '              <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '               \n' +
    '                <div style="width:12.5%">\n' +
    '                <button \n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                    title="{{::\'DEFAULT_FASE_PRODUCTE\' | translate}}">\n' +
    '                    <i class="icon-gma-fase-fabricacio color-fabricacio"></i>\n' +
    '                </button> </br>\n' +
    '                <span>\n' +
    '                     {{obra.emisionsHome.fabricacio | number : 2}}\n' +
    '                </span>\n' +
    '                </div>\n' +
    '                <div style="width:12.5%">\n' +
    '                  <div ng-if="obra.emisionsHome.compensadesFabricacio> 0">\n' +
    '                   <button \n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                  </button></br>\n' +
    '                 {{obra.emisionsHome.compensadesFabricacio | number : 2}}\n' +
    '                 </div>\n' +
    '               </div>\n' +
    '                <div style="width:12.5%">\n' +
    '                <button \n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                  title="{{::\'DEFAULT_FASE_CONSTRUCCIO\' | translate}}">\n' +
    '                  <i class="icon-gma-fase-construccio color-construccio"></i>\n' +
    '                </button> </br>\n' +
    '                <span>\n' +
    '                  {{obra.emisionsHome.construccio | number : 2}}\n' +
    '                </span>\n' +
    '                </div>\n' +
    '                  <div style="width:12.5%">\n' +
    '                    <div ng-if="obra.emisionsHome.compensadesConstruccio> 0">\n' +
    '                    <button \n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                    </button></br>\n' +
    '                    {{obra.emisionsHome.compensadesConstruccio | number : 2}}\n' +
    '                  </div>\n' +
    '                  </div>\n' +
    '                <div style="width:12.5%">\n' +
    '                <button \n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                  title="{{::\'DEFAULT_FASE_US\' | translate}}">\n' +
    '                  <i class="icon-gma-fase-us color-us"></i>\n' +
    '                </button> </br>\n' +
    '                <span>\n' +
    '                 {{obra.emisionsHome.us | number : 2}}\n' +
    '                </span>\n' +
    '                </div>\n' +
    '                  <div style="width:12.5%">\n' +
    '                     <div ng-if="obra.emisionsHome.compensadesUs > 0">\n' +
    '                    <button \n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                    </button></br>\n' +
    '                    {{obra.emisionsHome.compensadesUs | number : 2}}\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '                <div style="width:12.5%">\n' +
    '                <button \n' +
    '                  class="mdl-button mdl-js-button mdl-button--icon"\n' +
    '                  title="{{::\'DEFAULT_FASE_FINAL_DE_VIDA\' | translate}}">\n' +
    '                  <i class="icon-gma-fase-final-de-vida color-final"></i>\n' +
    '                </button> </br>\n' +
    '                <span>\n' +
    '                {{obra.emisionsHome.finalDeVida | number : 2}}\n' +
    '                </span>\n' +
    '                </div>  \n' +
    '                  <div style="width:12.5%">\n' +
    '                    <div ng-if="obra.emisionsHome.compensadesFinalDeVida > 0">\n' +
    '                    <button \n' +
    '                      class="mdl-button mdl-js-button mdl-button--icon">\n' +
    '                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>\n' +
    '                    </button></br>\n' +
    '                    {{obra.emisionsHome.compensadesFinalDeVida | number : 2}}\n' +
    '                    </div>\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '            </div>\n' +
    '\n' +
    '     \n' +
    '              <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-apps">\n' +
    '                <div class="tcqi--padding-left-15 tcqi--center-text">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <!-- mediambient -->\n' +
    '                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"\n' +
    '                        ng-class="{\n' +
    '                        \'tcqi-gma-color-permisos-nodades\':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,\n' +
    '                        \'tcqi-gma-color-no-permisos-dades\':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces)\n' +
    '                        && obra.temediambient,\'tcqihome--hidden\':obra.idpare!==null}"\n' +
    '                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,\n' +
    '                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)\n' +
    '                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,true)"\n' +
    '                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)\n' +
    '                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces">\n' +
    '                  <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>\n' +
    '                </button>\n' +
    '                </div>\n' +
    '              </div>\n' +
    '\n' +
    '         \n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('compensacions/compensacions-compartir.tpl.html',
    '<div id="compartir-obra-form" ng-cloak>\n' +
    '\n' +
    '  <!-- Formulario con el nombre del usuario -->\n' +
    '  <div ng-if="!ctrl.showCompartirModulos">\n' +
    '    <itec-form\n' +
    '      ng-if="$root.permisos.SISQUILLO.add"\n' +
    '      name="ctrl.formulari.nom"\n' +
    '      fields="ctrl.formulari.camps"\n' +
    '      ng-model="ctrl.obraCompartidaObj"\n' +
    '      ng-submit-function="ctrl.formulari.func()"\n' +
    '      errors="ctrl.errors"\n' +
    '      submit-button-text="DEFAULT_CONTINUE"\n' +
    '      submit-button-icon-right="ctrl.continueButton">\n' +
    '    </itec-form>\n' +
    '\n' +
    '    <p ng-if ="ctrl.sisquillosCompartits.compartidaperuser != null" id="compartir-obra-form--compartitPer-title"\n' +
    '        class="tcqi--margin-left-30 tcqi--margin-bottom-20">\n' +
    '      <span>Obra compartida per {{ctrl.sisquillosCompartits.compartidaperuser}}.</span>\n' +
    '    </p>\n' +
    '\n' +
    '    <md-card ng-show="ctrl.sisquillosCompartits.listsisquillocompartit.length > 0 && $root.permisos.SISQUILLO.read"\n' +
    '        id="list-sisquillo-compartit" class="tcqi--margin-top-10 tcqi--margin-10">\n' +
    '\n' +
    '      <md-card-content>\n' +
    '        <div ng-repeat="sisquilloCompartit in ctrl.sisquillosCompartits.listsisquillocompartit track by $index"\n' +
    '             class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '          <div class="mdl-cell--10-col">{{sisquilloCompartit.username}}</div>\n' +
    '\n' +
    '          <div class="mdl-cell--2-col">\n' +
    '            <button ng-if="$root.permisos.SISQUILLO.delete"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"\n' +
    '                    title="{{::\'DEFAULT_ESBORRAR_OBRA_COMPARTIDA\' | translate}}"\n' +
    '                    itec-dialog-confirm\n' +
    '                    itec-dialog-confirm-title="OBRES_DELETE_COMPARTIT_TITLE"\n' +
    '                    itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT_COMPARTIT"\n' +
    '                    itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"\n' +
    '                    itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"\n' +
    '                    itec-dialog-confirm-function-ok=\n' +
    '                        "ctrl.esborrarSisquilloCompartit(sisquilloCompartit.id, $index,sisquilloCompartit.identitatcompartida)">\n' +
    '              <i class="material-icons">delete_forever</i>\n' +
    '            </button>\n' +
    '\n' +
    '            <button ng-if="$root.permisos.SISQUILLO.edit"\n' +
    '                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"\n' +
    '                    title="{{::\'DEFAULT_EDITAR_OBRA_COMPARTIDA\' | translate}}"\n' +
    '                    ng-click="ctrl.editarSisquilloCompartit(sisquilloCompartit.id,sisquilloCompartit.username)">\n' +
    '                    <i class="material-icons">edit</i>\n' +
    '            </button>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </md-card-content>\n' +
    '    </md-card>\n' +
    '  </div>\n' +
    '\n' +
    '\n' +
    '  <!-- Formulario con los módulos y permisos -->\n' +
    '  <div ng-if="ctrl.showCompartirModulos" id="tcqihome--obres-permisos" class="tcqi--padding-30">\n' +
    '    <h5 class="bold"><span>{{ctrl.obraCompartidaObj.username}}</span></h5>\n' +
    '\n' +
    '    <itec-button class="itec-float-right" ng-click-function="ctrl.back()"\n' +
    '      label="{{\'DEFAULT_TORNAR\' | translate}}" icon-left="ctrl.backButton"></itec-button>\n' +
    '\n' +
    '\n' +
    '    <div id="tcqihome--obres-modulos" class="tcqi--padding-10 tcqi--padding-left-30">\n' +
    '      <div id="tcqihome--obres-modulos-title" class="tcqi--margin-bottom-20">\n' +
    '        {{\'obra.compartida.modulos\' | translate }}:\n' +
    '      </div>\n' +
    '\n' +
    '      <a ng-show="!ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=true;">\n' +
    '        {{\'obra.compartida.mostrar.permisos\' | translate}}\n' +
    '      </a>\n' +
    '      <a ng-show="ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=false;">\n' +
    '        {{\'obra.compartida.ocultar.permisos\' | translate}}\n' +
    '      </a>\n' +
    '\n' +
    '      <form>\n' +
    '        <div ng-repeat="permiso in ctrl.permisosModulsUsername track by $index"\n' +
    '            class="mdl-grid mdl-grid--no-spacing">\n' +
    '\n' +
    '          <div class="mdl-cell--4-col">\n' +
    '          <itec-checkbox ng-if="permiso.acces"\n' +
    '            label="{{permiso.nom}}"\n' +
    '            ng-model="permiso.id"\n' +
    '            ng-class="{\'bold\': permiso.id}">\n' +
    '          </itec-checkbox>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-show="ctrl.showrols" class="rols mdl-cell--8-col">\n' +
    '          <itec-select\n' +
    '            ng-if="permiso.acces"\n' +
    '            empty-option="true"\n' +
    '            name="rol"\n' +
    '            list="permiso.listrols"\n' +
    '            property-label="nom"\n' +
    '            ng-model="permiso.rol"\n' +
    '            property-id="id"\n' +
    '            class="itec-float-right">\n' +
    '          </itec-select>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div id="compartir-obra-form--actions-to-share"\n' +
    '        class="mdl-grid mdl-grid--no-spacing tcqi--margin-top-10 tcqi--padding-10 tcqi--padding-left-30">\n' +
    '\n' +
    '      <div class="mdl-cell--6-col">\n' +
    '        <itec-checkbox label="SISQUILLO_COMPARTIT_AGAIN" ng-model="ctrl.checkAgain"></itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_DATOS_GENERALES" ng-model="ctrl.checkDatosGenerales"></itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_ELIMINAR_OBRA" ng-model="ctrl.checkRemoveObra"></itec-checkbox>\n' +
    '      </div>\n' +
    '      <div class="mdl-cell--6-col">\n' +
    '        <itec-checkbox ng-if="false" label="SISQUILLO_IMPORTAR_BIM" ng-model="ctrl.checkImportBim" >\n' +
    '        </itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_EXPORTAR_TCQI" ng-model="ctrl.checkExportTcqi" >\n' +
    '        </itec-checkbox>\n' +
    '        <itec-checkbox label="SISQUILLO_EXPORTAR_FIE" ng-model="ctrl.checkExportFie" >\n' +
    '        </itec-checkbox>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <itec-button class="itec-float-right tcqi--margin-top-10 tcqi--margin-bottom-20"\n' +
    '      ng-click-function="ctrl.shareSisquillo()" label="{{\'DEFAULT_COMPARTIR\' | translate}}">\n' +
    '    </itec-button>\n' +
    '  </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('ok.html',
    '0');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('notfound.html',
    '<html>\n' +
    '<head><title>Not Found</title></head>\n' +
    '<body bgcolor="white">\n' +
    '<center><h1>Not Found</h1></center>\n' +
    '<hr><center>itec - tcqi</center>\n' +
    '\n' +
    '<form action="v1/tcqihome/">\n' +
    '	<center>\n' +
    '  	<input type="submit" value="Go to Homepage" />\n' +
    '  </center>\n' +
    '</form>\n' +
    '\n' +
    '</body>\n' +
    '</html>');
}]);

angular.module('itecTcqihome').run(['$templateCache', function($templateCache) {
  $templateCache.put('clipboard.html',
    '<!DOCTYPE html>\n' +
    '<html>\n' +
    '<head>\n' +
    '    <script src="//metabase.itec.cat/vide/js/tools/crossDomainLocalStorage.js"></script>\n' +
    '</head>\n' +
    '<body>\n' +
    '</body>\n' +
    '</html>');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name commonControls.utils.tcq-obra-form-upload
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tcqObraFormUpload', [
      'ngFileUpload'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqObraFormUpload.directive:tcqObraFormUpload
   * @restrict EA
   *
   */
  tcqObraFormUpload.$inject = ["$cookies"];
  angular
    .module('itecTcqihome.shared.tcqObraFormUpload')
    .directive('tcqObraFormUpload', tcqObraFormUpload);

  function tcqObraFormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-obra-form-upload/tcq-obra-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: ["$scope", "$rootScope", "Notification", "HelperFactory", "UploadFactory", "UploadFileConstants", "$mdDialog", "$document", "DataFactory", "SisquilloFactory", "$timeout", function ($scope, $rootScope, Notification, HelperFactory,
        UploadFactory, UploadFileConstants, $mdDialog, $document, DataFactory,
        SisquilloFactory, $timeout) {
        var vm = this;
        vm.name = 'tcqObraFormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;
        vm.cleanForm 		    = cleanForm;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;
        vm.codiObra = '';
        vm.descripcioObra = '';
        vm.mostrarArbolTipologias = false;

        vm.fileName = '';
        vm.fileNameExtension = '';

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';


        vm.tipologiaDialogController = TipologiaDialogController;
        vm.obra = {};
        vm.obra.tipologia = {nom : '', id : 0};

        vm.tipologies = [];
        vm.loadTipologies = loadTipologies;
        //vm.loadTipologies();


        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
          $mdDialog.show({
            controller: ["$q", "$http", "ApiLayoutConstant", "$rootScope", "$scope", "$mdDialog", "parametres", "SisquilloFactory", "Notification", "$translate", "DataFactory", function DialogController($q, $http, ApiLayoutConstant,
              $rootScope, $scope, $mdDialog, parametres, SisquilloFactory,
              Notification, $translate, DataFactory) {
              var vm = this;
              vm.errors = [];
              vm.object = {};

              vm.backButton = {};
              vm.backButton.class = 'material-icons tcqi--button-icon-left';
              vm.backButton.content = 'arrow_back';

              vm.codi = parametres.codiObra;
              vm.descripcio = parametres.descripcioObra;
              vm.tipologies= parametres.tipologies;
              vm.schemaVersionScript = parametres.schemaVersionScript;
              vm.sisId = parametres.sisId;
              vm.obra = parametres.obra;

              /*
                Variable que se utiliza para alternar entre el formulario y el
                arbol de tipologias.
              */
              vm.mostrarOriginal = true;

              /*
                Variable que permite que se pueda visualizar el input y el botón
                de las tipologías.
              */
              vm.mostrarArbolTipologias = parametres.mostrarArbolTipologias;

              vm.cancelFunction = function() {
                vm.mostrarOriginal = true;
              }

              vm.confirmFunction = function(node) {
                vm.mostrarOriginal = true;
                vm.obra.tipologia = node;
              };

              $scope.accept = function() {
                $rootScope.$broadcast('$activateLoader');
                vm.object.idFile = {};
                vm.object.idFile.name = parametres.fileName;
                vm.object.idFile.extension = parametres.fileNameExtension;

                vm.object.codi = vm.codi;
                vm.object.descripcio = vm.descripcio;
                vm.object.idTipologia = vm.obra.tipologia.id;
                vm.object.schemaVersionScript = vm.schemaVersionScript;
                vm.object.sisId = vm.sisId;

                var url = parametres.serverPath + 'upload/extractFile';
                DataFactory.add(url, vm.object).then(function(response) {
                  if (angular.isDefined(parametres.onFinishFunction)) {
                      parametres.onFinishFunction()(response.data.sisId);
                  }
                }).catch(function(error){
                  onErrorUpload(vm.object, error);
                }).finally(function() {
                  $rootScope.$broadcast('$deactivateLoader');
                });
                $mdDialog.hide();
              };

              $scope.hide = function() {
                parametres.cleanForm();
                $mdDialog.cancel();
              };

              $scope.cancel = function() {
                parametres.cleanForm();
                $mdDialog.cancel();
              };

              vm.selectTipologia = function() {
                vm.mostrarOriginal = false;
              }

            }],
            controllerAs: 'ctrl',
            templateUrl: 'obres/importar-obra/importar-obra-dialog.tpl.html',
            parent: angular.element($document.body),
            clickOutsideToClose:true,
            locals: {'parametres': {cleanForm:vm.cleanForm, codiObra:vm.codiObra, schemaVersionScript: vm.schemaVersionScript, sisId:vm.sisId,
                                    obra:vm.obra, tipologies:vm.tipologies,
                                    descripcioObra:vm.descripcioObra,
                                    mostrarArbolTipologias:vm.mostrarArbolTipologias,
                                    fileName:vm.fileName,
                                    fileNameExtension:vm.fileNameExtension,
                                    serverPath:$scope.serverPath,
                                    onFinishFunction:vm.onFinishFunction}}
          });
        }

        function loadTipologies() {
           SisquilloFactory.getTipologies().then(function(response) {
             vm.tipologies = response.data;
           }).catch(function(response){});
        }

        function TipologiaDialogController(obra, tipologies) {
            var ctrl = this;
            ctrl.obra = obra;
            ctrl.tipologies = [];
            ctrl.expanded = [];
        };

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if(response !=null && !angular.isUndefined(response) && response.data !=null && !angular.isUndefined(response.data) && response.data.data !=null && !angular.isUndefined(response.data.data)){
              vm.codiObra = response.data.data.codi;
              vm.descripcioObra = response.data.data.descripcio;
              vm.schemaVersionScript = response.data.data.schemaVersionScript;
              vm.sisId = response.data.data.sisId;
              vm.mostrarArbolTipologias = response.data.data.mostrarArbolTipologias;
              vm.fileName =  response.data.data.idFile.name;
              vm.fileNameExtension = response.data.data.idFile.extension;
            }
          }
          vm.selectFile();
        }

        function normalizeError(error) {
          //24931 degut a multiples causes i routes de l'error s'ha observat que poden arribar errors diferents, per exemple itec-api envia el .data del error, mentre que uploadFactory envia un nivell superior. Aqui identifiquem els diferents tipus per retornar sempre el mateix nivell d'anidament de l'error.
          return (error && error.config)? error.data : error;
        }

        function onErrorUpload(objFile, error) {
          error = normalizeError(error);
          Notification.processMessage(error);
          vm.errors = Notification.processError(error);
          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }

        function uploadFile() {
          SisquilloFactory.getTipologies().then(function(response) {
            vm.tipologies = response.data;
            vm.errors = [];
            if (vm.files.length > 0) {
              var file = vm.files[0];
              if (angular.isDefined(file) && file !== null) {

                $rootScope.$broadcast('$activateLoader');

                vm.objFile = file;
                vm.objFile.fields = {newFileId:HelperFactory.uniqueMoment()};
                vm.objFile.chunkSize = vm.chunkSize;
                vm.objFile.sizeUrl = 'ok.html';
                vm.objFile.baseUrl = '/api/' + $scope.serverPath;
                vm.objFile.controllerUrl = 'upload';
                vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

                return UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).then(function(response) {
                  //nothing
                  console.log("You shouldn't be seeing this");
                }).catch(function(error) {
                  onErrorUpload(vm.objFile, error);
                }).finally(function() {
                  $rootScope.$broadcast('$deactivateLoader');
                });

              }
            }
          }).catch(function(response){});
        }

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name commonControls.utils.tcq-obra-form-upload
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload', [
      'ngFileUpload'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqBc3FormUpload.directive:tcqBc3FormUpload
   * @restrict EA
   *
   */
  tcqBc3FormUpload.$inject = ["$cookies"];
  angular
    .module('itecTcqihome.shared.tcqBc3FormUpload')
    .directive('tcqBc3FormUpload', tcqBc3FormUpload);

  function tcqBc3FormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-bc3-form-upload/tcq-bc3-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: ["$scope", "$rootScope", "Notification", "HelperFactory", "UploadFactory", "UploadFileConstants", "$mdDialog", "$document", "$timeout", "DataFactory", function ($scope, $rootScope, Notification, HelperFactory, UploadFactory, UploadFileConstants,$mdDialog,$document, $timeout, DataFactory) {
        var vm = this;
        vm.name = 'tcqBc3FormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;
        vm.cleanForm 		= cleanForm;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';

        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
        	
        	
          if (vm.files.length > 0) {
        	//   
              $mdDialog.show({
                  controller: ["$scope", "$mdDialog", "parametres", "SisquilloFactory", "Notification", "$translate", function DialogController($scope, $mdDialog, parametres, SisquilloFactory, Notification, $translate) {
                    var vm = this;
                    vm.codi = '';
                    vm.descripcio = '';
                    vm.rotulo =0;
                    vm.copycodidesc = false;
                    vm.errors = [];

                    $scope.accept = function() {

	                    parametres.uploadFile(parametres.file,vm.codi,vm.descripcio,vm.rotulo,vm.copycodidesc);  
	                	$mdDialog.hide();
                    };
                    $scope.hide = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                    $scope.cancel = function() {
                      parametres.cleanForm();
                      $mdDialog.cancel();
                    };
                  }],
                  controllerAs: 'ctrl',
                  templateUrl: 'obres/exportar-a-fie/importar-de-fie-dialog.tpl.html',
                  parent: angular.element($document.body),
                  clickOutsideToClose:true,
                  locals: {'parametres': {cleanForm: vm.cleanForm, uploadFile: vm.uploadFile, file: vm.files[0]}} 
                });
          }
        }

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if (!angular.isUndefined(vm.onFinishFunction)) {
              vm.onFinishFunction()(response.data.data.sisId);
            }
          }
          $rootScope.$broadcast('$deactivateLoader');          
        }

        function normalizeError(error) {
          //24931 degut a multiples causes i routes de l'error s'ha observat que poden arribar errors diferents, per exemple itec-api envia el .data del error, mentre que uploadFactory envia un nivell superior. Aqui identifiquem els diferents tipus per retornar sempre el mateix nivell d'anidament de l'error.
          return (error && error.config)? error.data : error;
        }

        function onErrorUpload(objFile, error) {
          error = normalizeError(error);
//          Notification.processMessage(error.data);

//          vm.errors = Notification.processError(error.data);
          vm.errors = Notification.processError(error);

          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }      

        function uploadFile(file,codi,descripcio,rotulo,copycodidesc) {
          vm.errors = [];
          if (angular.isDefined(file) && file !== null) {

            $rootScope.$broadcast('$activateLoader');

            vm.objFile = file;
            vm.objFile.fields = {codi:codi,descripcio:descripcio,rotulo:rotulo,copycodidesc:copycodidesc};
            vm.objFile.fields.newFileId = HelperFactory.uniqueMoment();
            vm.objFile.chunkSize = vm.chunkSize;
            vm.objFile.sizeUrl = 'ok.html';
            vm.objFile.baseUrl = '/api/' + $scope.serverPath;
            vm.objFile.controllerUrl = 'upload/bc3';
            vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

            return UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).catch(function(error) {
              onErrorUpload(vm.objFile, error);
            }).finally(function() {
              $rootScope.$broadcast('$deactivateLoader');
            });
          }
        }

        

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name shared.tables
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.tables', []);
}());

(function () {
  'use strict';

  /**
          Útil per posar tds en un ng-repeat d'un tr



   * @ngdoc directive
   * @name shared.directive:replaceMe
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="shared">
       <file name="index.html">
        <replace-me></replace-me>
       </file>
     </example>
   *
   */
      replaceMe.$inject = ["$compile", "$http", "$templateCache"];
  angular
    .module('itecTcqihome.shared.tables')
    .directive('replaceMe', replaceMe);

  function replaceMe($compile, $http, $templateCache) {
    return {
            restrict: 'A',
            scope: {
               row: '=',
               controlador: '='
            },
            link: function (scope, element, attrs) {
                function getTemplate(template) {
                    $http.get(template, {cache: $templateCache}).success(function (templateContent) {
                        element.replaceWith($compile(templateContent)(scope));
                    });
                }

                scope.$watch(attrs.template, function () {
                    if (attrs.template) {
                        getTemplate(attrs.template);
                    }
                });
            }
        };
      }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.submain
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.submain', [
    ]);
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.pdfViewer
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.pdfViewer', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.pdfViewer.directive:mntPdfViewer
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="itecTcqihome.shared.pdfViewer">
       <file name="index.html">
        <mnt-pdf-viewer></mnt-pdf-viewer>
       </file>
     </example>
   *
   */
  angular
    .module('itecTcqihome.shared.pdfViewer')
    .directive('mntPdfViewer', mntPdfViewer);

  function mntPdfViewer() {
    return {
      restrict: 'EA',
      scope: {
        fields                : '=',
        ngModel               : '=',
        baseUrl               : '=',
        paramsUrl             : '=',
        errors                : '=',
        title                 : '@?',
        docButton             : '=?',
        xlsButton             : '=?',
        transcludeButton      : '=?',
        transcludeButtonAction: '&?',
        transcludeButtonText  : '@?',
        preFunction           : '&?',
        titleBuscador         : '@'
      },
      templateUrl: 'shared/pdf-viewer/mnt-pdf-viewer-directive.tpl.html',
      transclude: true,
      controllerAs: 'ctrl',
      controller: ["$scope", "$window", "$sce", function ($scope, $window, $sce) {
        var vm = this;
        vm.name = 'mntPdfViewer';
        vm.pdfUrl = '';

        vm.showPdfLayout        = false;
        vm.showTranscludeLayout = false;
        vm.havingTranscludeOp   = $scope.transcludeButton === true;
        vm.goPdfUrl = goPdfUrl;
        vm.downloadDoc = downloadDoc;
        vm.downloadXls = downloadXls;
        vm.expandedSearch = true;
        
        if (vm.havingTranscludeOp){
          vm.goTransclude = goTransclude;
        }

        $scope.$watch('errors[\'\']', function (newValue, oldValue){
          if (!angular.isUndefined(newValue) && newValue.length > 0) {
            $window.scrollTo(0, 0);
          }
        });

        $scope.trustSrc = function(src) {
         return $sce.trustAsResourceUrl(src);
        }

        function goPdfUrl(){
          vm.showPdfLayout        = true;
          vm.showTranscludeLayout = false;
  
          var params = '?format=pdf' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }
        
        function downloadDoc(){
          var params = '?format=doc' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function downloadXls(){
          var params = '?format=xls' + $scope.paramsUrl;
          vm.pdfUrl = $scope.baseUrl + params;
        }

        function goTransclude(){
          vm.showPdfLayout        = false;
          vm.showTranscludeLayout = true;
          $scope.transcludeButtonAction();
        }
        //console.log($scope);

      }],
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        scope.showError = false;

        var iframe = element.find('iframe');
        iframe.bind('load', function (event) {
          if ( iframe[0].innerHTML ) {
            scope.$apply(function(){
              var status = null;
              scope.messageError = '';

              var head = iframe[0].contentDocument.head;
              if (head){
                var metaStatus = head.querySelector('meta[name=status]');
                if (metaStatus !== null) {
                    status = metaStatus.content;
                }
              }
              
              if ( status !== null && status >= 300 ) {
                var metaMessage = head.querySelector('meta[name=message]');
                if (metaMessage !== null) {
                    scope.messageError = metaMessage.content;
                }
                var metaValidations = head.querySelector('meta[name=validations]');
                if (metaValidations !== null) {
                    scope.errors = Notification.processError({validations: JSON.parse(metaValidations.content.replace(new RegExp('\'', 'g'),'"'))});
                }
                scope.showError = true;
              } else {
                scope.showError = false;
                scope.errors = [];
              }
            });
          } else {
            // didn't load
          }
          
        });

        if (angular.isUndefined(scope.submitButtonText)){
          scope.submitButtonText = "DEFAULT_SUBMIT_TEXT";
        }

        scope.$emit('itecColumnFilterDirectiveReady');
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.locale', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.shared.locale.factory:LocaleInterceptorFactory
   *
   * @description
   *
   */
  LocaleInterceptorFactory.$inject = ["$cookies", "LocalStorage", "LocaleService"];
  angular
    .module('itecTcqihome.shared.locale')
    .factory('LocaleInterceptorFactory', LocaleInterceptorFactory);

  function LocaleInterceptorFactory($cookies, LocalStorage, LocaleService) {
    var requestInterceptor = {
      response: function(response) {
        var localeCookie = $cookies.get('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE');
        if (!angular.isUndefined(localeCookie)) {
          var locale = LocalStorage.get('locale');
          if (locale !== localeCookie) {
            LocalStorage.save('locale', localeCookie);
            LocaleService.setLocale(localeCookie);
          }  
        }
        
        return response;
      }
    };
    return requestInterceptor;
  }

}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.estat
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.headerSubmenu', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.shared.headerSubmenu.controller:HeaderSubmenuCtrl
   *
   * @description
   *
   */
  HeaderSubmenuCtrl.$inject = ["optionsHeaderSubmenuData", "AppHeaderSubmenuFactory", "PermisosFactory", "$rootScope", "$scope", "$state", "$stateParams"];
  angular
    .module('itecTcqihome.shared.headerSubmenu')
    .controller('HeaderSubmenuCtrl', HeaderSubmenuCtrl);

  function HeaderSubmenuCtrl(
                optionsHeaderSubmenuData,
                AppHeaderSubmenuFactory, 
                PermisosFactory,
                $rootScope, $scope, $state, $stateParams) {
    var vm = this;   
    
    if (!angular.isUndefined(optionsHeaderSubmenuData.addPermit) && optionsHeaderSubmenuData.addPermit !== null && (angular.isUndefined($stateParams.submenuInfo.left.addButton) || $stateParams.submenuInfo.left.addButton)) {
      $stateParams.submenuInfo.left.addButton = PermisosFactory.isVisible(optionsHeaderSubmenuData.addPermit, 'add');
    }
    
    $scope.$on(optionsHeaderSubmenuData.name + '.showOptionSubmenu',function(event, data){
      if (angular.isUndefined(data.onlyFor)) {
        if (data.show){
           angular.forEach(optionsHeaderSubmenuData.options, function(key){
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            });
        }else{
          angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
          });
        }
      }
      else {
        //primer tot a false
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
            key.show = false;
        });
        //a true tots els menus que no tenen camp onlyFor o el tenen i coincideix amb el data.onlyFor
        angular.forEach(optionsHeaderSubmenuData.options, function(key){
          if(!angular.isUndefined(key.onlyFor)) {
            if (data.onlyFor === key.onlyFor) {
              key.show = PermisosFactory.isVisible(key.permit, 'read');
            }
          }
          else {
            key.show = PermisosFactory.isVisible(key.permit, 'read');
          }
        });
      }


    });
    
    AppHeaderSubmenuFactory.resize($state, $stateParams, optionsHeaderSubmenuData.name);

    AppHeaderSubmenuFactory.createAttributes(vm, optionsHeaderSubmenuData.options, $stateParams, false);


  }
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.breadcrumb
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared.breadcrumb', [
      'itecGuicomponents.layout.wrapper.breadcrumb'
    ]);
    // .config(function(ItecBreadcrumbProvider) {
    //   ItecBreadcrumbProvider.compileWith(function(currentState, $interpolate) {
    //     var state = currentState.self;
    //     if(!state.breadcrumb) { return null; }
    //     if(typeof state.breadcrumb === 'string') {
    //       return {
    //         text: state.breadcrumb,
    //         stateName: state.name
    //       };
    //     } else {
    //       var breadcrumb = angular.copy(state.breadcrumb);

    //       if (breadcrumb.dynamicText) {
    //         breadcrumb.text=$interpolate(breadcrumb.dynamicText)(currentState.locals.globals);
    //       }

    //       if (breadcrumb.list){
    //         var list = JSON.parse($interpolate(breadcrumb.list)(currentState.locals.globals));
    //         var breadcrumbs = [];
    //         angular.forEach(list, function(obj){
    //           var aux = {};
    //           aux.stateName = breadcrumb.stateName;
    //           if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //             var result = '';
    //             angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + stateParam.paramName + '\" : \"' + obj[stateParam.paramProperty] + '\"';
    //             });
    //             aux.stateName = aux.stateName + '({' + result + '})';
    //           } else if (breadcrumb.stateParam) {
    //             aux.stateName = aux.stateName + '({' + breadcrumb.stateParam.paramName + ' : ' + obj[breadcrumb.stateParam.paramProperty] + '})';
    //           }
    //           aux.class = breadcrumb.class;
    //           aux.text = breadcrumb.text;
    //           if (breadcrumb.textParams){
    //             var result = '';
    //             angular.forEach(breadcrumb.textParams, function(textParam, index){
    //               if (index > 0){
    //                 result += ', ';
    //               }
    //               result = result + '\"' + textParam.paramName + '\" : \"' + obj[textParam.paramProperty] + '\"';
    //             });
    //             aux.textParams = JSON.parse('{' + result + '}');
    //           } else if (breadcrumb.textParam) {
    //             aux.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + obj[breadcrumb.textParam.paramProperty] + '\"}');  
    //           } else {
    //             aux.textParams = {};
    //           }
              
    //           breadcrumbs.push(aux);
    //         });
    //         return breadcrumbs;
    //       } else {
            
    //         if (breadcrumb.textParams){ //tractament per N paràmetres de text
    //           var result = '';
    //           angular.forEach(breadcrumb.textParams, function(textParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + textParam.paramName + '\" : \"' + $interpolate(textParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.textParams = JSON.parse('{' + result + '}');
    //         } else if (breadcrumb.textParam) { //tractament per un paràmetre de text
    //           breadcrumb.textParams = JSON.parse('{\"' + breadcrumb.textParam.paramName + '\" : \"' + $interpolate(breadcrumb.textParam.paramValue)(currentState.locals.globals) + '\"}');
    //         } else {
    //           breadcrumb.textParams = {};
    //         }

    //         if (breadcrumb.stateParams){ //tractament per N paràmetres d'estat
    //           var result = '';
    //           angular.forEach(breadcrumb.stateParams, function(stateParam, index){
    //             if (index > 0){
    //               result += ', ';
    //             }
    //             result = result + '\"' + stateParam.paramName + '\" : \"' + $interpolate(stateParam.paramValue)(currentState.locals.globals) + '\"';
    //           });
    //           breadcrumb.stateName = breadcrumb.stateName + '({' + result + '})';
    //         } else if (breadcrumb.stateParam) { //tractament per un paràmetre d'estat
    //           breadcrumb.stateName = breadcrumb.stateName + '({\"' + breadcrumb.stateParam.paramName + '\" : \"' + $interpolate(breadcrumb.stateParam.paramValue)(currentState.locals.globals) + '\"})';
    //         }
    //       }
    //       return breadcrumb;
    //     }
    //   });
    // });
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.arbol-tipologia
   *
   */
  angular
    .module('itecTcqihome.shared.arbreTipologies', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.arbol-tipologia
   * @restrict EA
   * @element
   *
   * @description
   *
   * <arbre-tipologies
       tipologies="ctrl.tipologies"
       tipologia-selected="ctrl.tipologiaSelected"
       submit-function="ctrl.confirm"
       cancel-function="ctrl.cancel()">
     </arbre-tipologies>
   *
   *
   * "ctrl.tipologies"         --> Lista de las tipologías
   * "ctrl.tipologiaSelected"  --> Si la obra tiene tipologia
   * "ctrl.confirm"            --> Funcion confirmar del controlador del dialog
   * "ctrl.cancel()"           --> Funcion cancelar del controlador del dialog
   *
   */

  angular
    .module('itecTcqihome.shared.arbreTipologies')
    .directive('arbreTipologies', arbreTipologies);

  function arbreTipologies() {
    return {
      restrict: 'EA',
      scope: {
          tipologies: '=',
          tipologiaSelected: '=?',
          submitFunction: '=',
          cancelFunction: '&'
      },
      templateUrl: 'shared/arbre-tipologies/arbre-tipologies-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: ["$scope", "$rootScope", function ($scope, $rootScope) {
        var vm = this;

        vm.backButton = {};
        vm.backButton.class = 'material-icons tcqi--button-icon-left';
        vm.backButton.content = 'arrow_back';
        
        vm.tipologies = [];
        vm.expanded = [];
        vm.tipologiaSelected = $scope.tipologiaSelected;
        vm.cancelFunction = $scope.cancelFunction;
        vm.submitFunction = $scope.submitFunction;

        // Función que envía la tipología seleccionada al controlador de
        // la función $scope.submitFunction.
        vm.guardarCanvisTipologia = function () {
          var item = vm.getItemChecked();
          $scope.$eval($scope.submitFunction(item));
        }

        // Monta l'arbre de tipologies a partir de la llista
        vm.addChildren = function (parent) {

          if (parent !== undefined) {
            parent.children = [];
          }
          angular.forEach ($scope.tipologies, function (item) {
            if ((parent === undefined) && (item.idPare === null)) {
              vm.tipologies.push (item);
              vm.addChildren (item);
              // El node rel sempre surt expanded
              vm.expanded.push(item);
            }

            if ((parent !== undefined) && (item.idPare === parent.id)) {
              parent.children.push(item);
              vm.addChildren (item);
            }
          });
        };

        // fica l'item amb el id a expanded i expandeix el seu pare
        vm.expandItemAndParent = function (id) {
          if (id === null) return;
          angular.forEach ($scope.tipologies, function (item) {
            if (item.id === id) {
              vm.expanded.push(item);
              vm.expandItemAndParent (item.idPare);
            }
          });
        };

        // Obte l'element checkejat o null si no n'hi ha cap
        vm.getItemChecked = function () {
          var result = null;
          angular.forEach ($scope.tipologies, function (item) {
            if (item.checked) {
              result = item;
            }
          });
          console.log(result);
          return result;
        };

        // Busquem el item seleccionat i critem a expandir tot el seu cami
        vm.expandCheckedPath = function () {
          var item = vm.getItemChecked();
          if (item !== null) {
            vm.expanded = [];
            vm.expandItemAndParent(item.idPare);
          }
        };

        // Busquem el node i el fiquem a checked
        vm.setTipologiaToChecked = function(node) {
          if ((node !== null) && (node !== undefined)) {
            angular.forEach ($scope.tipologies, function (item) {
              if (item.id == node.id) {
                item.checked = true;
              }
              else {
                item.checked = false;
              }
            });
          }
        };

        vm.setTipologiaToChecked(vm.tipologiaSelected);
        vm.addChildren ();
        vm.expandCheckedPath();

        // Quan checkejen fiquem la resta a unchecked
        vm.changeCheck = function(node) {
          if (node.checked) {
            vm.setTipologiaToChecked(node);
          }
        };

      }]
      ,
      link: function (scope, element, attrs) {
      }
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres.dades
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres.dades', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.obres.dades')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres.dades', {
        url: '/{idObra:[0-9]{0,32}}/dades',
        views: {
          'wrapper@': {
              templateUrl : 'obres/dades/dades.tpl.html',
              controller  : 'ObresDadesCtrl',
              controllerAs: 'ctrl'
          }
        },
        resolve: {
          obraData: ["SisquilloFactory", "$stateParams", "SessionStorage", function(SisquilloFactory, $stateParams,SessionStorage) {
            if (angular.isDefined($stateParams.isNew) && $stateParams.isNew !== null && $stateParams.isNew) {
                return {};
            } else if (angular.isDefined($stateParams.idObra) && $stateParams.idObra !== null &&
                $stateParams.idObra) {
              return SisquilloFactory.get($stateParams.idObra,SessionStorage.get('idEntitatCompartida')).catch(function(){});
            } else {
              return {};
            }
          }],
          tipologies: ["SisquilloFactory", "$stateParams", "SessionStorage", function(SisquilloFactory,$stateParams,SessionStorage) {
            return SisquilloFactory.getTipologies(SessionStorage.get('idEntitatCompartida')).catch(function(){});
          }],
          dadesGeneralsData: ["SisquilloFactory", "$stateParams", "SessionStorage", function(SisquilloFactory,$stateParams,SessionStorage) {
            return SisquilloFactory.getDadesGenerals(SessionStorage.get('idEntitatCompartida')).catch(function(){});
          }]

        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name plantillaConductiuEspai.dades.controller:ObresDadesCtrl
   *
   * @description
   *
   */
  ObresDadesCtrl.$inject = ["SisquilloFactory", "TipologiaPreguntaConstants", "HelperFactory", "$stateParams", "obraData", "$state", "$mdDialog", "ItecTreeTracking", "$rootScope", "$window", "$scope", "Notification", "$translate", "dadesGeneralsData", "tipologies", "ImatgeFactory", "ApiLayoutConstant", "$cookies", "TcqihomeConstant", "SessionStorage"];
  angular
    .module('itecTcqihome.obres.dades')
    .controller('ObresDadesCtrl', ObresDadesCtrl);

  function ObresDadesCtrl(SisquilloFactory, TipologiaPreguntaConstants,
    HelperFactory, $stateParams, obraData, $state, $mdDialog, ItecTreeTracking,
    $rootScope, $window, $scope, Notification, $translate, dadesGeneralsData,
    tipologies, ImatgeFactory, ApiLayoutConstant, $cookies, TcqihomeConstant,SessionStorage) {

    TipologiaDialogController.$inject = ["obra", "tipologies"];
    var vm = this;

    vm.needRefresh = $stateParams.needRefresh || false;

    vm.back = back;
    vm.desarObra = desarObra;
    vm.refresh = refresh;
    vm.tipologies = tipologies.data;

    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.TcqihomeConstant = TcqihomeConstant;
    vm.TipologiaPreguntaConstants = TipologiaPreguntaConstants;

    vm.iconLeft = {};
    vm.iconLeft.class = 'material-icons';
    vm.iconLeft.content = 'file_upload';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';
    vm.isNew = false;

    vm.deleteImage = deleteImage;

    init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
        $cookies.get('authsecurity')).then( function(response) {

      vm.headers = {
        'x-api-version': ApiLayoutConstant.SERVER_VERSION_TCQIHOME,
        'authtcqihome': response.app.auth
      };
    });

    vm.errors = [];

    if (angular.isUndefined(obraData) || angular.isUndefined(obraData.data)) {
      HelperFactory.setParams($stateParams, {'idObra': undefined});
      vm.obra = {};
      vm.obra.monedes = [];
      vm.obra.monedes.push(dadesGeneralsData.data.monedaDefault);
      vm.isNew = true;
    } else {
      vm.obra = obraData.data.sisquillo;
      SessionStorage.save('codiNewSisquillo', vm.obra.codi);
      vm.obra.monedes = [];
      vm.obra.monedes.push(vm.obra.moneda);
      vm.isNew = false;
    }
    vm.maxUploadSize = dadesGeneralsData.data.maxUploadSizeImages;

    //Esta variable solo sirve la primera vez que se carga la pagina para que
    //se actualicen las preguntas de la tipología:
    var primeraVez = true;

    // vm.setTipologia = function setTipologia(){
    //   if(!angular.isUndefined(vm.obra.tipologia) && vm.obra.tipologia != null){
    //     angular.forEach (vm.tipologies, function (tipologia){
    //       if (tipologia.id === vm.obra.tipologia) {
    //         vm.obra.tipologia = tipologia;
    //       }
    //     });
    //   }
    // }


    //Actualizamos las preguntas de la tipología:
    if(!angular.isUndefined(vm.obra.id) && vm.obra.id != null &&
       !angular.isUndefined(vm.obra.tipologia.id) &&
       vm.obra.tipologia.id != null){
         actualizarPreguntasTipologia(vm.obra.id, vm.obra.tipologia.id)
    }


    vm.formulari = {
      nom: 'dadesGenerals',
      func: function() {
        return vm.desarObra();
      },
      grups: [
        {
          title:'DEFAULT_DADES_OBRA',
          camps: [
            {
              type: 'text',
              name: 'codi',
              label: 'DEFAULT_CODI',
              columns: 6,
              required: true,
              disabled: vm.obra.teSeguiment,
              maxLength: 50
            }, {
              type: 'text',
              name: 'descripcio',
              label: 'DEFAULT_TITOL_OBRA',
              columns: 6,
              required: true,
              disabled: vm.obra.teSeguiment,
              maxLength: 2000
            }, {
              type: 'chips',
              name: 'monedes',
              label: 'DEFAULT_MONEDA',
              fieldProperty: 'descripcion',
              textPlaceholder: $translate.instant('DEFAULT_MONEDA'),
              list: dadesGeneralsData.data.monedaList,
              required: true,
              readonly: vm.obra.teSeguiment,
              columns: 3,
              mdMaxChips: 2
            }, {
              type: 'multiTransclude',
              name: 'tipologia',
              label: 'DEFAULT_TIPOLOGIA',
              required: true,
              columns: 9,
              block: "tipologia-block"
            }, {
              type: 'select',
              simple: true,
              name: 'contractacio',
              label: 'DEFAULT_CONTRACTACIO',
              list: dadesGeneralsData.data.contractacioList,
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 6
            }, {
              type: 'select',
              simple: true,
              name: 'actuacio',
              label: 'DEFAULT_TIPUS_ACTUACIO',
              list: dadesGeneralsData.data.actuacioList,
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 6
            }, {
              type: 'textarea',
              name: 'notes',
              label: 'DEFAULT_NOTES',
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 12,
              maxLength: 2000
            },
            {
              type: 'datepicker',
              name: 'dataCreacio',
              label: 'DEFAULT_DATA_CREACION',
              required: false,
              disabled: true,
              show: (vm.obra.dataCreacio?true:false),
              columns: 2
            },
            {
            	type: 'numeric',
            	name: 'vidaUtil',
            	label: 'DEFAULT_VIDA_UTIL_ANYS',
            	decimalPlaces: 0,
            	columns:3,
            	required: false
            },
             {
              type: 'site',
              name: 'site_coordinates',
              latitud: 'site_latitud',
              longitud: 'site_longitud',
              label: 'DEFAULT_LOCALITZACIO',
              required: false,
              disabled: vm.obra.teSeguiment,
              columns: 12
            }
          ]
       }
     ]
    };

    function deleteImage() {
      ImatgeFactory.remove(vm.obra.imatge.id, vm.obra.id,SessionStorage.get('idEntitatCompartida')).then(function() {
        vm.obra.imatge = undefined;
      });
    }

    function back() {
      $rootScope.$broadcast('tooManySisquillos');
      if (vm.needRefresh) {
        $state.go('^', $stateParams, {reload: true});
      } else {
        $state.go('^');
      }
    }

    function refresh() {
      $state.go('.', $stateParams, {reload: true});
    }

    function desarObra() {
      if (angular.isDefined(vm.obra.monedes[0])) {
        vm.obra.moneda = vm.obra.monedes[0];
      }
      // var obraAux = vm.obra;
      // obraAux.tipologia = obraAux.tipologia.id;
      $rootScope.$broadcast('$activateLoader');
      if (vm.isNew) {
        return SisquilloFactory.add(vm.obra).then(function(response) {
          $scope.$evalAsync(function() {
            Notification.success('OBRES_CREATE_SUCCESS');
            HelperFactory.setParams($stateParams, {'idObra': response.data});
            vm.isNew = false;
            //$state.go('.', $stateParams, {'reload':true});
            //$rootScope.$broadcast('$deactivateLoader');

            vm.obra.id = response.data;
            vm.obra.dataCreacio = 'DEFAULT_NOW';
            SessionStorage.save('idNewSisquillo', vm.obra.id);
            SessionStorage.save('codiNewSisquillo', vm.obra.codi);
            $rootScope.$broadcast('$activateLoader');


            back();
          });
        }).catch(function(error) {
          $rootScope.$broadcast('$deactivateLoader');
          vm.errors = Notification.processError(error);
        });

      } else {
        return SisquilloFactory.update(vm.obra.id, vm.obra,SessionStorage.get('idEntitatCompartida')).then(function() {
          Notification.success('OBRES_UPDATE_SUCCESS');
          SessionStorage.save('codiNewSisquillo', vm.obra.codi);
          $rootScope.$broadcast('$deactivateLoader');
        }).catch(function(error) {
          vm.errors = Notification.processError(error);
          $rootScope.$broadcast('$deactivateLoader');
        });
      }
    }

    $scope.$on ('tipologia-new-preguntes', function (evt, list) {
      // Copiem les respostes que ja existeixen
      angular.forEach (vm.obra.listPreguntes, function (preguntaExisting){
        angular.forEach (list, function (preguntaNova){
          if (preguntaExisting.idTipologiaPregunta === preguntaNova.idTipologiaPregunta) {
            preguntaNova.valorBoolea = preguntaExisting.valorBoolea;
            preguntaNova.valorNumeric = preguntaExisting.valorNumeric;
            preguntaNova.valorCadena = preguntaExisting.valorCadena;
          }
        });
      });
      vm.obra.listPreguntes = list;
    });


    vm.getDescPregunta = function(pregunta) {
      var result = pregunta.nom;
      if ((pregunta.ua !== null) && (pregunta.ua !== '')) {
        result += ' (' + pregunta.ua + ')';
      }
      return result;
    };

    function actualizarPreguntasTipologia(idObra, idTipologia){
      SisquilloFactory.getSisquilloTipologies(idObra, idTipologia,SessionStorage.get('idEntitatCompartida')).then(function (response) {
        $rootScope.$broadcast('tipologia-new-preguntes', response.data);
      }).catch(function (error) {
        vm.errors = Notification.processError(error);
      });
      $mdDialog.hide();
    }

    // Función que abre el dialog del árbol de tipologías
    // cargando el controlador TipologiaDialogController y la plantilla donde
    // esta definida la directiva del arbre-tipologies.
    vm.selectTipologia = function() {
      $mdDialog.show({
        locals: {obra: vm.obra, tipologies: vm.tipologies},
        controller: TipologiaDialogController,
        controllerAs: 'ctrl',
        templateUrl: 'obres/dades/tipologies.tpl.html',
        parent: angular.element(document.body),
        title:'DEFAULT_PREVENCIO_MINIMITZACIO',
        clickOutsideToClose:true,
        escapeToClose:true
      });
    }

    // Este es el controlador que se encarga del dialog que aparece al pulsar
    // sobre el botón de las tipologías.
    function TipologiaDialogController (obra, tipologies) {
      var ctrl = this;
      ctrl.obra = obra;
      ctrl.tipologies = tipologies;
      ctrl.tipologiaSelected = ctrl.obra.tipologia;
  
      ctrl.confirm = function(node) {
        ctrl.obra.tipologia = node;
        actualizarPreguntasTipologia(ctrl.obra.id, node.id);
      };

      ctrl.cancel = function () {
        $mdDialog.hide();
      };

    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.sisquillo
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.sisquillo', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.sisquillo.factory:SisquilloFactory
   *
   * @description
   *
   */
  SisquilloFactory.$inject = ["DataFactory", "ApiLayoutConstant"];
  angular
    .module('itecTcqihome.api.sisquillo')
    .factory('SisquilloFactory', SisquilloFactory);

  function SisquilloFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres';
    var api_security = ApiLayoutConstant.SERVER_PATH_ADMINISTRACIO;


    var base = {

      getAllWithParams: function(params){
        var url = api;
        return DataFactory.getWithParams(url, params);
      },

      getSisquilloPage: function(params){
        var url = api + '/page';
        return DataFactory.getWithParams(url, params);
      },

      getEntitatEmisions: function(obj) {
        var url = api + '/entitatEmisions';
        return DataFactory.getWithParams(url, obj);
      },

      getAllByEntitat: function(obj) {
        var url = api + '/entitat';
        return DataFactory.getWithParams(url, obj);
      },

      getDadesGenerals: function(identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/dades_generals' + parament;
        return DataFactory.get(url);
      },

      getTipologies: function(identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/tipologies/all' + parament;
        return DataFactory.get(url);
      },

      get: function(id,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id + parament;
        return DataFactory.get(url);
      },

      getSisquilloTipologies: function (idSisquillo, idTipologia,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url;
        if (idSisquillo !== undefined) {

          url = api + '/' + idSisquillo + '/' + idTipologia + '/preguntes' + parament;
        }
        else {
          url = api + '/' + idTipologia + '/preguntes' + parament;
        }
        return DataFactory.get(url);
      },

      getSisquillosCompartits: function(id,identitatobra) {
        var url = api + '/' + id + '/sisquillosCompartits?idEntitatCompartida=' + identitatobra;
        return DataFactory.get(url);
      },

      update: function(id, obj,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id + parament;
        return DataFactory.update(url,obj);
      },

      add: function(obj){
        var url = api;
        return DataFactory.add(url,obj);
      },

      getUsernamePermisos: function (obj) {
        var url = api + '/loadPermisosByModuls';
        return DataFactory.getWithParams(url, obj);
      },

      getSisquilloCompartit: function(id) {
        var url = api + '/sharedwork/' +id;
        return DataFactory.get(url);
      },
      addSisquilloCompartit: function(obj) {
        var url = api + '/sharedwork';
        return DataFactory.add(url, obj);
      },

      updateSisquilloCompartit: function(obj) {
        var url = api + '/sharedwork';
        return DataFactory.update(url, obj);
      },

      delete: function(id,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api;
        return DataFactory.delete(url, id + parament);
      },

      deleteSisquilloCompartit: function(id) {
        var url = api + '/sharedwork';
        return DataFactory.delete(url, id);
      },

      getSisquillosCompartitsByEntitat: function(idEntitat) {
        //var url = api + '/sharedwork/entitat/' + idEntitat;
        var url = api + '/sharedwork/allentitats'
        return DataFactory.get(url);
      },

      download: function(id) {
        var url = api + '/' + id +'/download';
        return DataFactory.get(url);
      },

      getDocumentsBim: function(id,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id +'/documentsBim' + parament;
        return DataFactory.get(url);
      },

      getConfiguracioData: function() {
        var url = api + '/configuracioData';
        return DataFactory.get(url);
      },

      getConfiguracio: function() {
        var url = api + '/configuracio';
        return DataFactory.get(url);
      },

      setConfiguracio: function(obj){
        var url = api + '/configuracio';
        return DataFactory.update(url,obj);
      },

      exportarObra: function(id,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = '/api/' + api + '/' + id +'/download' + parament;
        return DataFactory.getWithParamsReports(url, {});
      },

      exportarFie: function(id,export_plecs,export_grafics,export_amid,export_codisoriginals,identcomp) {
        var parament='';
        if (identcomp !== undefined){
          // S'ha de posar el & en lloc del ? , perque té paràmetres anteriors
              parament='&idEntitatCompartida=' + identcomp;
        }

        var url = '/api/' + api + '/' + id +'/downloadfie/'  +
              '?volPlecs=' + export_plecs + '&volGrafics=' + export_grafics +
              '&volAmids=' + export_amid + '&volCodisOriginals=' + export_codisoriginals + parament;
        return DataFactory.getWithParamsReports(url, {});
      },

      getCompensacionsEntitat: function() {
        var url = api + '/compensacionsEntitatPropia';
        return DataFactory.get(url);
      },

      //TCQi Cities
      setVinculacio: function(idSisquillo, listProjectesVinculacio){
        var url = api + '/tcqicities/sisquillo/' + idSisquillo + '/vinculacio';
        return DataFactory.update(url, listProjectesVinculacio);
      },

      getListProjectesTCQiCities: function(idSisquillo){
           var url = api + '/tcqicities/sisquillo/' + idSisquillo + '/projecte/list';
        return DataFactory.get(url);
      }

      //fi TCQi Cities

    };
    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.logout
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.logout', [
    ]);
}());

(function () {
'use strict';

/**
 * @ngdoc service
 * @name itecTcqihome.api.logout.factory:LogoutFactory
 *
 * @description
 *
   */
    LogoutFactory.$inject = ["LogoutAllFactory"];
  angular
    .module('itecTcqihome.api.logout')
    .factory('LogoutFactory', LogoutFactory);

    function LogoutFactory(LogoutAllFactory) {
      var LogoutBase = {
        get: function() {
            return LogoutAllFactory.logoutall();
        }
      };
      return LogoutBase;
    }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name api.locale
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.locale', [
      'itecApi'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.locale.factory:LocaleFactory
   *
   * @description
   *
   */
  LocaleFactory.$inject = ["DataFactory", "ApiLayoutConstant"];
  angular
    .module('itecTcqihome.api.locale')
    .factory('LocaleFactory', LocaleFactory);

  function LocaleFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_MANTENIMENT + 'locales';

    var base = {
      getAll: function(){
        var url = api;
        return DataFactory.get(url);
      },
      getCurrent: function(){
        var url = api + '/session';
        return DataFactory.get(url);
      },
      updateCurrent: function(obj){
        var url = api + '/session';
        return DataFactory.update(url,obj);
      }
    };
    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.imatge
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.imatge', [
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.imatge.factory:ImatgeFactory
   *
   * @description
   *
   */
  ImatgeFactory.$inject = ["DataFactory", "ApiLayoutConstant", "$http", "$cookies"];
  angular
    .module('itecTcqihome.api.imatge')
    .factory('ImatgeFactory', ImatgeFactory);

  function ImatgeFactory(DataFactory, ApiLayoutConstant, $http, $cookies) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres';

    var base = {
      download: function(id, size, idSisquillo) {
        var url = '/api/' + ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres/' + idSisquillo + '/imatges/' + id +
          '/download?size=' + size;

        var promise = init_app({name: 'tcqihome', version: ApiLayoutConstant.SERVER_VERSION_TCQIHOME},
            $cookies.get('authsecurity')).then(

          function(response) {
            var headers = {};
            headers['x-api-version'] = ApiLayoutConstant.SERVER_VERSION_TCQIHOME;
            headers['authsecurity'] = $cookies.get('authsecurity');
            headers['authtcqihome'] = response.app.auth;
            headers['responseType'] = 'arraybuffer';
            return $http.get(url, {headers: headers});
          });
          return promise;
      },

      remove: function(idImage, idSisquillo,identcomp) {
        var parament='';
        if (identcomp !== undefined)
        {
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + idSisquillo + '/imatges'  ;

        return DataFactory.delete(url, idImage + parament);
      }
    };

    return base;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.persona
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.entitat', []);
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.entitat.factory:EntitatFactory
   *
   * @description
   *
   */
  EntitatFactory.$inject = ["ApiLayoutConstant", "DataFactory", "$cookies", "$http"];
  angular
    .module('itecTcqihome.api.entitat')
    .factory('EntitatFactory', EntitatFactory);

  function EntitatFactory(ApiLayoutConstant, DataFactory, $cookies, $http) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'entitats';

    var EntitatBase = {
      getActual: function() {
        var url = api + '/current';
        return DataFactory.get(url);
      },

      getAll: function() {
        var url = api + '/all_entitats';
        return DataFactory.get(url);
      },

      getEstadistiques: function() {
        var url = api + '/estadistiques_entitat';
        return DataFactory.get(url);
      }
      

    };

    return EntitatBase;
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name shared
   * @description
   *
   */
  angular
    .module('itecTcqihome.shared', [
      'itecTcqihome.shared.tables',
      'itecTcqihome.shared.pdfViewer',
      'itecTcqihome.shared.breadcrumb',
      'itecTcqihome.shared.headerSubmenu',
      'itecTcqihome.shared.submain',
      'itecTcqihome.shared.locale',
      'itecTcqihome.shared.tcqObraFormUpload',
      'itecTcqihome.shared.tcqBc3FormUpload',
      'itecTcqihome.shared.arbreTipologies'
    ]);
}());

"use strict";

angular.module('sharedData', [])
  .factory('DataSharedService', function() {
    var data = {};

    return {
      getData: function() {
        return data;
      },
      setData: function(newData) {
        data = newData;
      }
    };
  });
(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres
   * @description
   *
   */
  angular
    .module('itecTcqihome.obres', [
      'ui.router',
      'itecTcqihome.obres.dades'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.obres')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres', {
        url: '/obres',
        cache: true,
        views: {
          'wrapper': {
            templateUrl: 'obres/obres.tpl.html',
            controller: 'ObresCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          entitatActual: ["EntitatFactory", function(EntitatFactory) {
            return EntitatFactory.getActual().catch(function(){});
          }],
          allEntitats: ["EntitatFactory", function(EntitatFactory) {
            return EntitatFactory.getAll().then(
              function(response){
                if (angular.isDefined(response) && angular.isDefined(response.data)) {
                  response = response.data;
                  return response;
                }
              });
          }],
          dadesGeneralsData: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getDadesGenerals().catch(function(){});
          }],
          obresCompartidesTotesEntitats: ["SisquilloFactory", "entitatActual", function(SisquilloFactory,entitatActual) {
             return SisquilloFactory.getSisquillosCompartitsByEntitat(entitatActual.data.id).
             then(function(response) {
                 if (angular.isDefined(response) && angular.isDefined(response.data)) {
                   return response.data;
                 }
              });
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.obres.filter:obraByCodiAndDescripcio
   *
   * @description
   *
   */
obraByCodiAndDescripcioFilter.$inject = ["$log"];
  angular
    .module('itecTcqihome.obres')
    .filter('obraByCodiAndDescripcio', obraByCodiAndDescripcioFilter);

  function obraByCodiAndDescripcioFilter($log) {
  return function (items, search) {
      var result = [];
      angular.forEach(items, function (value, key) {
        if (value.codi !== null && value.descripcio !== null) {
          var codi = value.codi.replace(/\s+/g, '').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          var descripcio = value.descripcio.replace(/\s+/g, '').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          search = search.replace(/\s+/g, '').toLowerCase(); // Eliminar los espacios en blanco de la cadena de búsqueda
          if (codi.indexOf(search) !== -1 || descripcio.indexOf(search) !== -1) {
              result.push(value);
          }
        }
      });
      return result;
  }
}
}());
(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.obres.controller:ObresCtrl
   *
   * @description
   *
   */
  ObresCtrl.$inject = ["SisquilloFactory", "HelperFactory", "ConvertFactory", "FileSaver", "Notification", "$cookies", "$http", "$rootScope", "$scope", "$state", "$stateParams", "$window", "ApiLayoutConstant", "ImatgeFactory", "$mdDialog", "$document", "UploadFileConstants", "allEntitats", "PermisosFactory", "entitatActual", "$log", "$timeout", "dadesGeneralsData", "SessionStorage", "TcqihomeConstant", "ConfigService", "DataFactory", "obresCompartidesTotesEntitats", "ServerRootPathService", "$translate", "obraByCodiAndDescripcioFilter", "DataSharedService"];
  angular
    .module('itecTcqihome.obres')
    .controller('ObresCtrl', ObresCtrl);

  function ObresCtrl(SisquilloFactory, HelperFactory, ConvertFactory,FileSaver, Notification, $cookies, $http,
      $rootScope, $scope, $state, $stateParams, $window, ApiLayoutConstant, ImatgeFactory, $mdDialog, $document,
      UploadFileConstants, allEntitats, PermisosFactory, entitatActual, $log, $timeout, dadesGeneralsData,
      SessionStorage, TcqihomeConstant,ConfigService,DataFactory,obresCompartidesTotesEntitats,ServerRootPathService,$translate, obraByCodiAndDescripcioFilter, DataSharedService) {

        VincularTCQiCitiesDialogController.$inject = ["$scope", "$mdDialog", "SisquilloFactory", "Notification", "$translate", "obra", "projectList"];
    var vm = this;
    vm.maxObraSize = TcqihomeConstant.UPLOAD_OBRA_MAX_SIZE;
    vm.fields = [];
    vm.entitats = allEntitats;
    vm.errors = [];
    vm.showrols = false;
    vm.canLoad = true;
    vm.scrollPosition = 0;
    vm.scrollPositionAnterior = 0;
    vm.loadedPages = [];
    vm.searched = false;
    vm.cercaObj = {
      page: 0,
      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
    };
    vm.obres = [];
    vm.createdSisquillo = false;
    
    var data = DataSharedService.getData();
    if (SessionStorage.get('idNewSisquillo') == undefined && angular.isDefined(data) && Object.keys(data).length > 0  ) {
      // La variable 'data' no está vacía, contiene datos
      vm.cercaObj.page = data.cercaObj.page;
      vm.obres = data.obres;
    }




    vm.newObra = false;
    vm.expanded = false;
    vm.searchMode = false;
    vm.loadSearchSisquillos = loadSearchSisquillos;
    vm.searchSisquillos = searchSisquillos;
    vm.escucharEnterSearch = escucharEnterSearch;
    vm.closeSearchSisquillos = closeSearchSisquillos;
    vm.SortByCodi = SortByCodi;

    vm.loadSisquillos = loadSisquillos;
    vm.viewDades = viewDades;
    vm.viewDadesUpload = viewDadesUpload;
    vm.showPagesOrdered = showPagesOrdered;
    vm.viewDadesCompartides=viewDadesCompartides;
    vm.esborrar = esborrar;
    vm.copiarObra=copiarObra;
    vm.exportarObra = exportarObra;
    vm.exportarObraFIE = exportarObraFIE;
    vm.addSisquillo = addSisquillo;
    vm.openObra = openObra;
    var loadImatgesObres = loadImatgesObres;
    vm.showObresByEntitat = showObresByEntitat;
    vm.showDialogCompartirObra = showDialogCompartirObra;
    vm.shareSisquillo = shareSisquillo;
    vm.esborrarSisquilloCompartit = esborrarSisquilloCompartit;
    vm.editarSisquilloCompartit = editarSisquilloCompartit;
    vm.back = back;

    vm.size = UploadFileConstants.SIZE_MINIMAL;
    vm.listMode = true;

    vm.addButton = {};
    vm.addButton.class = 'material-icons tcqi--button-icon-left';
    vm.addButton.content = 'add';

    vm.continueButton = {};
    vm.continueButton.class = 'material-icons tcqi--button-icon-right';
    vm.continueButton.content = 'keyboard_arrow_right';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';

    vm.obrescompartidesFiltrades = [];
    vm.searchTextModified = false;
    vm.searchText = '';
    vm.sortType = '';
    vm.sortReverse  = false;
    vm.setSubObraExpand=setSubObraExpand;
    vm.obraFound = false;


    if (vm.entitats.length>0){
      vm.entitatSelected = vm.entitats[0];
    }
    else {
      vm.entitatSelected =entitatActual.data;
    }

    SessionStorage.save('idEntitat', entitatActual.data.id);
    SessionStorage.save('idEntitatCompartida','');

    vm.checkAgain = false;
    vm.checkDatosGenerales = false;
    vm.checkRemoveObra = false;
    vm.checkExportTcqi = false;
    vm.checkExportFie = false;
    vm.checkImportBim = false;
    vm.idSisquilloCompartit = undefined;
    vm.dadesGeneralsData = dadesGeneralsData.data;

    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.hasaccessobraapp = hasaccessobraapp;
    vm.tesubsisquillos=tesubsisquillos;
    vm.titlemodul=titlemodul;
    vm.titlemodulcircular=titlemodulcircular;

    vm.vincularObraTCQiCities = vincularObraTCQiCities;

    vm.tcqiApps = {
      'security': {
        'id': ApiLayoutConstant.APP_ID_ADMINISTRACIO,
        'image': $rootScope.prefixItecGuicomponents +
                'images/layout/header/menu/directives/images/logos/security.png',
        'app': ApiLayoutConstant.APP_NOM_ADMINISTRACIO,
        'title': 'DEFAULT_TCQI_SECURITY',
        'descr': ''
      },
      'manteniment': {
        'id': ApiLayoutConstant.APP_ID_MANTENIMENT,
        'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/manteniment.png',
        'app': ApiLayoutConstant.APP_NOM_MANTENIMENT,
        'title': 'DEFAULT_TCQI_MANTENIMENT',
        'descr': ''
       },
       'areacollaborativa': {
         'id': ApiLayoutConstant.APP_ID_AREACOLLABORATIVA,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/areaColaborativa.png',
         'app': ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA,
         'title': 'DEFAULT_TCQI_AREA_COLABORATIVA',
         'descr': ''
       },
       'comparacioofertes': {
         'id': ApiLayoutConstant.APP_ID_COMPARACIOOFERTES,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/comparacioOfertes.png',
         'app': ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES,
         'title': 'DEFAULT_TCQI_COMPARACIO_OFERTES',
         'descr': ''
        },
        'estimaciocostos': {
          'id': ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS,
          'image': $rootScope.prefixItecGuicomponents +
                     'images/layout/header/menu/directives/images/logos/estimaciocostos.png',
          'app': ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
          'title': 'DEFAULT_TCQI_ESTIMACIO_COSTOS',
          'descr': ''
         }
      };

    vm.obraCompartidaObj = {};

    vm.formulari = {
      nom: 'formulari',
      func: loadPermisosUserToCheck,
      camps: [{
          type: 'text',
          name: 'username',
          label: 'DEFAULT_USERNAME',
          columns: 12,
          required: true
        }
      ]};

  
function SortByCodi(obresResult) {
  console.log('sorting .. ');
  return new Promise(function(resolve, reject) {
    obresResult.sort(function(a, b) {
      return a.codi.localeCompare(b.codi, undefined, { sensitivity: 'base' });
    });

    resolve(obresResult); // Resuelve la promesa con el resultado ordenado
  });
}


//Al crear una nueva obra se obtiene la pagina en la que se encuentra y a partir de ahi se obtienen todas las paginas hasta ella
$scope.MoveScrollBar = function(id) {
  vm.createdSisquillo = true;
  var params = {
    search: SessionStorage.get('codiNewSisquillo'),
    perPage: vm.cercaObj.perPage
  };
  vm.canLoad = false;
  
  SessionStorage.save('codiNewSisquillo', undefined);

  SisquilloFactory.getSisquilloPage(params).then(function(response) {
    $rootScope.$broadcast('$activateLoader');
    if (angular.isDefined(response.data.page)) {

      var obresResult = [];

      vm.cercaObj.page = 0;

      DataSharedService.setData({
        cercaObj: undefined,
        obres: []
      });

      var promises = []; // Array para almacenar las promesas
      
      var n = (response.data.page + 2);
      
      
      //Obtenemos las obras
      for (var i = 0; i < n ; i++) {
        var params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage,
          orderByCodi: 1,
        };

     
        promises.push(Promise.resolve(SisquilloFactory.getAllWithParams(params).then(function(response) {
          if (angular.isDefined(response.data.obres)) {
            response.data.obres.forEach(function(obj) {
              obj.isSubObraExpand = false;
              obj.visible = true;
              obj.isSubobra = false;
              obresResult.push(obj);
            });
          }
          vm.canLoad = true;
          vm.cercaObj.pages = response.data.pages;
          showIconAsObraCompartida(obresResult, entitatActual.data.id);

        })));
        
        
        vm.cercaObj.page++;
      }

    //Ordenamos las obras obtenidas por codigo  
    Promise.all(promises)
      .then(function(values){
            vm.SortByCodi(obresResult)
              .then(function(result) {
                // Asignar el resultado a vm.obres
                vm.obres = result;
                DataSharedService.setData({
                  cercaObj: vm.cercaObj,
                  obres: result
                });
                vm.newObra = true;
              })
              .catch(function(error) {
                console.error(error);
              });
          })
          .catch(function(error){
            console.error(error);
          });
    }
  });
}
  //Esta funcion se invoca al renderizar las obras, al renderizarse la nueva obra hace un scroll y durante dos segundos la marca
    this.renderComplete = function(isLast, idObra) {
    var idNew = SessionStorage.get('idNewSisquillo');
    if (idNew == idObra) {
      //Esperamos dos segundos ya que en ocasiones puede ser renderizado pero su posicion puede ser intercambiada por otra obra
      $timeout(function() {
        vm.obraFound = true;
        console.log('done loading');
        var id = '#obra' + idNew;
        console.log("id:" + id);
        SessionStorage.save('idNewSisquillo', undefined);
        $timeout(function() {
          var objective = angular.element(id);
          if (objective.length > 0) {
            objective.css({
              'background-color': '#2EC4B6',
              'transition': 'background-color 0.5s ease'
            });
            //Scrolleamos a la obra
            angular.element("#tcqihome--obres-list-tbody")[0].scrollTop = (0, objective[0].offsetTop - 100);
            $rootScope.$broadcast('$deactivateLoader');
            setTimeout(function() {
              objective.css('background-color', 'transparent');
            }, 2000);
          }
        });
      },2000);
    }

    };


    // Escucha el evento "keydown" en el campo de entrada
    function escucharEnterSearch(evento) {
      if (evento.keyCode === 13) {
        searchSisquillos();
      }
    }

    function searchSisquillos(){
      vm.loadedPages = [];
      vm.searched = true;
      if(!vm.expanded){
        vm.expanded=true;
        vm.copiaObresCompartidesOriginal = vm.obrescompartides;
        vm.searchMode = true;
      }
      else if (vm.searchText !== "") {
        // Cerca per el text introduït
        //Obres propies
        vm.cercaObj = {
          page: 0,
          perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
        };
        vm.searchTextModified = true;
        loadSearchSisquillos(false);

        //Obres compartides
        vm.obrescompartides = obraByCodiAndDescripcioFilter(vm.copiaObresCompartidesOriginal, vm.searchText);
      }
      else if (vm.searchText === "" && vm.searchTextModified){
        //Obres propies
        vm.cercaObj = {
          page: 0,
          perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
        };
        vm.searchTextModified = false;
        loadSisquillos(true);

        //Obres compartides
        vm.obrescompartides = vm.copiaObresCompartidesOriginal;
      }
    }

    function closeSearchSisquillos(){
      // Si s'ha buscat text, iniciem amb la cárrega inicial de los obres propies i compartides
      vm.searchText = '';
      vm.expanded=false;
      if(vm.searchTextModified){
        
        //Obres propies
        vm.searchTextModified = false;
        vm.cercaObj = {
          page: 0,
          perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
        };
        loadSisquillos(true);
       
       //Obres compartides
       vm.obrescompartides = vm.copiaObresCompartidesOriginal;
      }
      

    }

    function loadSearchSisquillos(deactivateLoader) {
      if (angular.isUndefined(vm.cercaObj.pages) || (vm.cercaObj.page + 1) <= vm.cercaObj.pages) {
        if(!deactivateLoader){
          $rootScope.$broadcast('$activateLoader');
        }

        if (vm.cercaObj.page === 0) {
          vm.obres = [];
        }

        var params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage,
          filterCodi: true,
          filterDesc: true,
          search: vm.searchText
        };
        vm.canLoad = false;

        SisquilloFactory.getAllWithParams(params).then(function(response) {
          if (angular.isDefined(response.data.obres)) {
            response.data.obres.forEach(function(obj) {
              obj.isSubObraExpand=false;
              obj.visible=true;
              obj.isSubobra=false;
              vm.obres.push(obj);
            });
          }
          vm.canLoad = true;
          vm.cercaObj.pages = response.data.pages;

          showIconAsObraCompartida(vm.obres,entitatActual.data.id);
          if(!deactivateLoader){
            $rootScope.$broadcast('$deactivateLoader');
          }
        });

        vm.cercaObj.page++;
      } else {
        vm.canLoad = false;
      }
    }

    

    function loadSisquillos(deactivateLoader) {
        console.log(vm.cercaObj.page);
        var isUndefined = SessionStorage.get('PrimeraConsulta');
        if ((vm.cercaObj.page + 1) <= isUndefined || isUndefined == undefined) {

          if (vm.cercaObj.page === 0) {
              vm.obres = [];
              vm.sortType = 'codi';
              vm.sortReverse = false;
          }
          
          
          if(SessionStorage.get('sortType') != undefined) {
            vm.sortType = SessionStorage.get('sortType');
            SessionStorage.save('sortType', undefined);
          }
          
          var params = initParams(vm.sortType, vm.sortReverse)
          vm.canLoad = false;
          
          SisquilloFactory.getAllWithParams(params).then(function(response) {
            if (angular.isDefined(response.data.obres)) {
              response.data.obres.forEach(function(obj) {
                obj.isSubObraExpand=false;
                obj.visible=true;
                obj.isSubobra=false;
                vm.loadedPages.length = response.data.pages;
                vm.obres.push(obj);
              });
            }
                SessionStorage.save('PrimeraConsulta', response.data.pages);
                vm.canLoad = true;
                showIconAsObraCompartida(vm.obres,entitatActual.data.id);
                vm.loadedPages[vm.cercaObj.page] = 1;
                vm.cercaObj.page++;
                DataSharedService.setData({
                  cercaObj: vm.cercaObj,
                  obres: vm.obres
                });
              });
      } else {
        var data = DataSharedService.getData();
        vm.cercaObj.page = data.cercaObj.page;
        vm.obres = data.obres;
        vm.canLoad = false;
      }
      console.log(vm.obres.length);
    }

    function showIconAsObraCompartida(listObres, identitat) {
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              listObresCompartides.forEach(function(idObraCompartida) {
                if (listObres !== null) {
                  listObres.forEach(function(obra) {
                    if (obra.id === idObraCompartida) {
                      obra.isSisquilloCompartit = true;
                    }
                    else {
                      if (obra.subSisquillos!==null){
                        obra.subSisquillos.forEach(function(subobra) {
                          if (subobra.id === idObraCompartida) {
                            subobra.isSisquilloCompartit = true;
                          }
                        });
                      }
                    }
                  });
                }
              });
            }
          }
      }
      return listObres;
    }


    function showObresByEntitat(activateLoader) {
      if (activateLoader) {
        $rootScope.$broadcast('$activateLoader');
      } else {
        vm.showObras = false;
      }

      var params = {
          idEntitat: vm.entitatSelected.id,
          page: 0,
          perPage: vm.cercaObj.perPage
        };

      SisquilloFactory.getAllByEntitat(params).then(
        function(response) {
          vm.obrescompartides = response.data;
          //SisquilloFactory.getSisquillosCompartitsByEntitat(vm.entitatSelected.id).then(function(response) {
            showIconAsObraCompartida(vm.obrescompartides, vm.entitatSelected.id);
          //}).finally(function() {
            //$rootScope.$broadcast('$deactivateLoader');
            vm.showObras = true;
            vm.obrescompartides = obraByCodiAndDescripcioFilter(vm.obrescompartides, vm.searchText);
          //});
        }).catch(function(error){
          $log.error(error);
          vm.obrescompartides = undefined;

          $rootScope.$broadcast('$deactivateLoader');
          vm.showObras = true;
        });
    }

    function viewDadesUpload(id) {
      SessionStorage.save('idEntitatCompartida',undefined);
      SessionStorage.save('idSelectedObra',id);
      SessionStorage.save('sortType',vm.sortType);
      SessionStorage.save('idNewSisquillo', id);
     if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }


    function viewDades(id) {
      SessionStorage.save('idEntitatCompartida',undefined);
      SessionStorage.save('idSelectedObra',id);
      SessionStorage.save('sortType',vm.sortType);


      if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }


    function viewDadesCompartides(id,dadesgenerals)
    {
      if (id && dadesgenerals) {
        SessionStorage.save('idEntitatCompartida',vm.entitatSelected.id);
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }

    function esborrar(obra,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.delete(obra.id,identcomp).then(function() {
        $scope.$evalAsync(function() {
          if (!obra.isSubobra)
          {
            HelperFactory.removeFromArray(vm.obres, obra.id);
          }
          else {
            esborrarsubobraarray(obra);
          }
        });
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    function exportarObra(id,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.exportarObra(id,identcomp).then(function(response){
        var blob = new Blob([response.data], {type: response.headers('Content-Type')});
        FileSaver.saveAs(blob, response.headers('filename'));
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    //TCQi Cities

    function vincularObraTCQiCities(obra){
      vm.projecteList = [];
      SisquilloFactory.getListProjectesTCQiCities(obra.id).then(function(response) {
        if (angular.isDefined(response.data.projecteList)) {
          vm.projecteList = response.data.projecteList;
          $mdDialog.show({
            locals: {obra: obra, projectList: vm.projecteList},
            controller: VincularTCQiCitiesDialogController,
            controllerAs: 'ctrl',
            templateUrl: 'obres/vincular-a-tcqicities/vincular-a-tcqicities-dialog.tpl.html',
            parent: angular.element($document.body),
            clickOutsideToClose:true
          });
        }
      }).catch(function(error) {
        Notification.error(error);
      });

    }


     

      function VincularTCQiCitiesDialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate, obra, projectList) {
          var ctrl = this;
          ctrl.errors = [];

          for (var key in vm) {
            if(vm.hasOwnProperty(key) && !ctrl.hasOwnProperty(key)){
              $scope[key] = vm[key];
            }
          }
          ctrl.showNoProjects = false;

          if(projectList !== null && !(projectList.length > 0)){
            ctrl.showNoProjects = true;
          }
          ctrl.listItems = projectList;

          ctrl.fields = [];
          ctrl.idObra = obra.id;
          ctrl.fields = [
            {
              type: 'check',
              name: 'vinculat',
              param: 'vinculat',
              sortable : false ,
              columns : 1,
            },
            {
              header: 'DEFAULT_CODI_PROJECTE',
              type: 'text',
              name: 'codi',
              sortable : true ,
              columns : 11,
            }
          ];

            //FORMATER
          ctrl.formatter = formatter;
          function formatter(object, field) {
            // TODO
            var css = '';
            if (field === 'nom'){
              css ='font-weight: 400;';
            }
            return css;
          };

          $scope.accept = function() {
            ctrl.projectesVinculats = [];
            ctrl.auxTeProjectesTCQiCities = false;
            ctrl.listItems.forEach(function(proj) {
              if(proj.vinculat === true){
                ctrl.auxTeProjectesTCQiCities = true;
              }
              ctrl.projectesVinculats.push(
                {
                  'id': proj.id,
                  'vinculat': proj.vinculat
                }
              );
            });
            
            SisquilloFactory.setVinculacio(ctrl.idObra, ctrl.projectesVinculats).then(function(response) {
                if (angular.isDefined(response)) {
                   //Això es per mostrar la icone de color en cas de tenir projectes TCQi CIITIES vinculats
                    obra.teProjectesTCQiCities = ctrl.auxTeProjectesTCQiCities;
                   Notification.success('ACCIO_REALITZADA_CORRECTAMENT');
                }
            }).catch(function(error) {
              ctrl.errors = Notification.processError(error);

             if(angular.isDefined(ctrl.errors.validacions)){
              ctrl.errors = ctrl.errors.validacions;
             }
  
            }).finally(function() {
  
            });

          };

          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        }

    // Fi TCQi CITIES

    function exportarObraFIE(id,compartida) {
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }

      $mdDialog.show({
        controller: ["$scope", "$mdDialog", "SisquilloFactory", "Notification", "$translate", function DialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate) {
          var vm = this;
          vm.export_plecs = true;
          vm.export_grafics = true;
          vm.export_amid = true;
          vm.export_codisoriginals=false;
          vm.errors = [];

          $scope.accept = function() {
          SisquilloFactory.exportarFie(id,vm.export_plecs,vm.export_grafics,vm.export_amid,vm.export_codisoriginals,identcomp).then(function(response){
            var blob = new Blob([response.data], {type: response.headers('Content-Type')});
            FileSaver.saveAs(blob, response.headers('filename'));
          }).catch(function(error){
            Notification.processError(error);
          }).finally(function() {
            $rootScope.$broadcast('$deactivateLoader');
          });
            $mdDialog.hide();
          };
          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        }],

        controllerAs: 'ctrl',
        templateUrl: 'obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
        parent: angular.element($document.body),
        clickOutsideToClose:true
      });
    }



    function copiarObra(idObra) {
        $rootScope.$broadcast('$activateLoader');
        var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
        DataFactory.get(url).then(function(response) {
        	var dadesinicials=response.data;
            $rootScope.$broadcast('$deactivateLoader');

            $mdDialog.show({
                controller: ["$scope", "$mdDialog", "Notification", "$translate", function DialogController($scope, $mdDialog,  Notification, $translate) {
                  var vmc = this;
                  vmc.copiar_codi = dadesinicials.codi;
                  vmc.copiar_descripcio = dadesinicials.descripcio;
                  vmc.copiar_bolBanc = dadesinicials.bolBanc;
                  vmc.copiar_teBanc = dadesinicials.bolBanc;
                  vmc.copiar_bolPressupost = dadesinicials.bolPressupost;
                  vmc.copiar_tePressupost = dadesinicials.bolPressupost;
                  vmc.copiar_bolSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_teSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_bolPlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_tePlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_bolMA = dadesinicials.bolMA;
                  vmc.copiar_teMA = dadesinicials.bolMA;
                  vmc.copiar_bolQaulitat = dadesinicials.bolQualitat;

                  vmc.copiar_bolPreusZero = dadesinicials.bolPreusZero;
                  vmc.copiar_bolFixarPreus = dadesinicials.bolFixarPreus;
                  vmc.copiar_bolDesFixarPreus = dadesinicials.bolDesFixarPreus;
                  vmc.copiar_bolSenseCT = dadesinicials.bolSenseCT;
                  vmc.copiar_bolSenseGrafics = dadesinicials.bolSenseGrafics;

                  vmc.errors = [];

                  vmc.fixarPreus = fixarPreus;
                  vmc.desFixarPreus = desFixarPreus;
                  vmc.copiarCertificacio=copiarCertificacio;

                  vmc.copiarMA=copiarMA;
                  vmc.preusZero=preusZero;

                  $scope.accept = function() {
                	vmc.errors = [];

                  	var form ={};
                  	form.sisIdCopia=idObra;
                    form.codi=  vmc.copiar_codi;
                    form.descripcio=  vmc.copiar_descripcio;
                    form.bolBanc=  vmc.copiar_bolBanc;
                    form.bolPressupost=vmc.copiar_bolPressupost;
                    form.bolSeguiment=  vmc.copiar_bolSeguiment;
                    form.bolPlaTreballs=  vmc.copiar_bolPlaTreballs;
                    form.bolMA=vmc.copiar_bolMA;
                    form.bolPreusZero=  vmc.copiar_bolPreusZero;
                    form.bolFixarPreus=  vmc.copiar_bolFixarPreus;
                    form.bolDesFixarPreus=  vmc.copiar_bolDesFixarPreus;
                    form.bolSenseCT=  vmc.copiar_bolSenseCT;
                    form.bolSenseGrafics=  vmc.copiar_bolSenseGrafics;
                    form.bolQualitat=vmc.copiar_bolQualitat;


                    $rootScope.$broadcast('$activateLoader');
                    var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
                    DataFactory.add(url,form).then(function(response) {
                        Notification.success("DEFAULT_ACCIO_FINALITZADA_EXIT");
                    	$mdDialog.hide();
                        vm.cercaObj = {
                        	      page: 0,
                        	      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
                        	    };
                        loadSisquillos(false);
                        loadImatgesObres();
                        showObresByEntitat(false);


                    }).catch(function(error){
                    	error.validations.forEach(function(validation){
                    		vmc.errors.push(validation);
                    	});
                        Notification.error("GENERIC_ERROR_DEFAULT");
                      }).finally(function() {
                        $rootScope.$broadcast('$deactivateLoader');
                      });
                  };
                  $scope.hide = function() {
                    $mdDialog.cancel();
                  };
                  $scope.cancel = function() {
                    $mdDialog.cancel();
                  };


                  // Funcions internes
                  function fixarPreus(){
                      if(vmc.copiar_bolFixarPreus===true){
                    	  vmc.copiar_bolDesFixarPreus = false;
                      }
                    }

                    function desFixarPreus(){
                        if(vmc.copiar_bolDesFixarPreus===true){
                      	  vmc.copiar_bolFixarPreus = false;
                        }

                        if(vmc.copiar_teSeguiment ===true){
	                        if(vmc.copiar_bolSeguiment ===true){
		                        	vmc.copiar_bolDesFixarPreus = false;
                            }
	                        else{
	                        	vmc.copiar_bolDesFixarPreus = true;
	                        }
                        }
                      }

                    function copiarCertificacio(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                            if(vmc.copiar_teSeguiment ===true){
	                        	vmc.copiar_bolDesFixarPreus = false;
                            }
                        }
                        if(vmc.copiar_bolSeguiment ===false && vmc.copiar_teSeguiment ===true ){
                        	vmc.copiar_bolDesFixarPreus = true;
                        }

                      }

                    function copiarMA(){
                        if(vmc.copiar_bolMA ===true){
                            vmc.copiar_bolBanc = true;
                            vmc.copiar_bolPressupost = true;
                        }

                      }



                    function preusZero(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                        }
                      }

                }],

                controllerAs: 'ctrl',
                templateUrl: 'obres/copiar-obra/copiar-obra-dialog.tpl.html',
                parent: angular.element($document.body),
                clickOutsideToClose:true
              });


        }).catch(function(error){
            $rootScope.$broadcast('$deactivateLoader');
          });

      }


    function openObra(id, app, hasAccess,compartida) {
      var domini=ServerRootPathService.getServerRootPath();
      if (compartida){
          var parament='?entitat=' + vm.entitatSelected.id;
      }
      else {
          var parament='?entitat=' + ConfigService.getEntitat();
      }
      if (hasAccess) {
        if (app === ApiLayoutConstant.APP_NOM_PRESSUPOST) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PRESSUPOST +
            '/pressupost/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_QUALITAT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_QUALITAT +
            '/qualitat/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MEDIAMBIENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MEDIAMBIENT +
            '/mediambient/#/' + 'obres/' + id + '/obra/indicadors/informacio' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGURETATISALUT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGURETATISALUT +
            '/seguretatisalut/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_PLANIFICACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PLANIFICACIO +
             '/planificacio/#/' + 'obres/' + id + '/tasques/gantt' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGUIMENTECONOMIC +
            '/seguimenteconomic/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MANTENIMENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MANTENIMENT +
            '/manteniment');
        } else if (app === ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA) {
          $window.open(domini + 'areacolaborativa');
        } else if (app === ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES) {
          $window.open(domini + 'comparadorofertes');
        } else if (app === ApiLayoutConstant.APP_NOM_ADMINISTRACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ADMINISTRACIO +
            '/security');
        } else if (app === ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS) {
          if (angular.isUndefined(id)) {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos' + parament);
          } else {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos/#/' + id + '/assignacio/estructura' + parament);
          }
        } else if (app === ApiLayoutConstant.APP_NOM_CONTROLCOSTOS) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_CONTROLCOSTOS +
            '/controlcostos/#/obra/' + id + '/arxiu' + parament);
        } else if (app ===ApiLayoutConstant.APP_NOM_CIRCULAR) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_CIRCULAR +
          '/circular/#/obres/' + id + '/materials/list/informacio' + parament);
        }
         /*else if (app === ApiLayoutConstant.APP_NOM_BANC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_BANC +
            '/banctcq/#/' + id + '/obra/estructura'  + parament);
        }*/
      }
    }


    function showDialogCompartirObra(idObra,compartida) {
      vm.showCompartirModulos = false;
      vm.sisquillosCompartits = {};
      var entobra=undefined;
      if (compartida){
          entobra=vm.entitatSelected.id;
      }
      else {
          entobra=ConfigService.getEntitat();
      }
      // Se cargan los usuarios compartidos de esta obra.
      $rootScope.$broadcast('$activateLoader');
      SisquilloFactory.getSisquillosCompartits(idObra,entobra).then(function(response) {
        vm.sisquillosCompartits = response.data;
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });

      vm.obraSelected = idObra;
      vm.obraCompartidaObj.username = '';
      vm.obraCompartidaObj.idEntitat=entobra;
      vm.obraCompartidaObj.compartida=compartida;
    }


    function loadPermisosUserToCheck() {
      $rootScope.$broadcast('$activateLoader');

      vm.checkAgain = false;
      vm.checkDatosGenerales = false;
      vm.checkRemoveObra = false;
      vm.checkExportTcqi = false;
      vm.checkExportFie = false;
      vm.checkImportBim = false;

      //vm.obraCompartidaObj.idEntitat = vm.idEntitat;
      vm.obraCompartidaObj.idObra = vm.obraSelected;

      vm.idSisquilloCompartit = undefined;

      SisquilloFactory.getUsernamePermisos(vm.obraCompartidaObj).then(function(response) {
        vm.permisosModulsUsername = response.data;
        vm.showCompartirModulos = true;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
          vm.obraCompartidaObj.username = '';
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    function esborrarSisquilloCompartit(idSisquilloCompartit, index,identitatcompartida) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.deleteSisquilloCompartit(idSisquilloCompartit).then(function() {
        // Se cargan los usuarios compartidos de esta obra.
        SisquilloFactory.getSisquillosCompartits(vm.obraSelected,vm.obraCompartidaObj.idEntitat).then(function(response) {
          vm.sisquillosCompartits = response.data;
        }).finally(function() {
          $rootScope.$broadcast('$deactivateLoader');
          if (vm.sisquillosCompartits.listsisquillocompartit.length === 0) {
            hideIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
          }
        });
      });
    }

    function deleteFromListCompartida(idObraACompartir,identitat) {
      var index=undefined;
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              for(var i = 0; i < listObresCompartides.length; i++) {
                if (listObresCompartides[i] === idObraACompartir) {
                  index=i;
                  break;
                }
              }
              if (index!=undefined)
              {
                obresCompartidesTotesEntitats[identitat].splice(index,1);
              }
            }
          }
      }
    }




    function hideIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;
      deleteFromListCompartida(idObraACompartir,identitat);
      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            obra.isSisquilloCompartit = false;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              obra.isSisquilloCompartit = false;
            }
            else {
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  subobra.isSisquilloCompartit=false;
                }
              });
            }
          });
        }
      }
      return listObres;
    }


    function addSisquillo() {
      SessionStorage.save('idEntitatCompartida',undefined);
      $window.scrollTo(0, 0);
      HelperFactory.setParams($stateParams, {'isNew': true, 'idObra': null});
      $state.go('obres.dades', $stateParams);
    }


    function loadImatgesObres() {
      angular.forEach(vm.obres, function(obra, index) {
        if (obra.imatge) {
          ImatgeFactory.download(obra.imatge.id, vm.size, obra.id).then(function(response2) {
            vm.obres[index].imatge.data = response2.data;
          });
        }
      });
    }


    function back($form) {
      vm.showCompartirModulos = false;
      vm.obraCompartidaObj.username = '';
    }


    function shareSisquillo() {
      var form = {};
      form.idobra = vm.obraSelected;
      form.username = vm.obraCompartidaObj.username;
      form.tornaracompartir = vm.checkAgain;
      form.dadesgenerals = vm.checkDatosGenerales;
      form.eliminarobra = vm.checkRemoveObra;
      form.exportartcqi = vm.checkExportTcqi;
      form.exportarfie = vm.checkExportFie;
      form.importarbim = vm.checkImportBim;

      form.listaplicaciorol = [];
      //form.identitat=vm.entitatSelected.id;
      form.identitat=vm.obraCompartidaObj.idEntitat;

      angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
        if (permiso.id) {
          if (!angular.isUndefined(permiso.rol) && vm.showrols) {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': permiso.rol.id});
          } else {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': undefined});
          }
        }
      });

      var hasError = false;
      if (angular.isDefined(form.listaplicaciorol) && form.listaplicaciorol.length > 0) {
        if (angular.isUndefined(vm.idSisquilloCompartit)) {
          SisquilloFactory.addSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_CREATE_SUCCESS');
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        } else {
          form.id=vm.idSisquilloCompartit;

          SisquilloFactory.updateSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_EDIT_SUCCESS');
            vm.idSisquilloCompartit=undefined;
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        }
      } else {
        Notification.error('obra.compartida.error.modules.not_checked');
      }
    }

    function actualitzallistaobrescompartides(idObraACompartir,identitat)
    {
      if (obresCompartidesTotesEntitats[identitat]!=undefined){
        obresCompartidesTotesEntitats[identitat].push(idObraACompartir);
      }
      else {
        obresCompartidesTotesEntitats[identitat]=[idObraACompartir];
      }
    }
    function showIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;

      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            if (!obra.isSisquilloCompartit) {
              actualitzallistaobrescompartides(idObraACompartir,identitat);
            }
            obra.isSisquilloCompartit = true;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              if (!obra.isSisquilloCompartit) {
                actualitzallistaobrescompartides(idObraACompartir,identitat);
              }
              obra.isSisquilloCompartit = true;
            }
            else{
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  if(!subobra.isSisquilloCompartit) {
                    actualitzallistaobrescompartides(idObraACompartir,identitat);
                  }
                  subobra.isSisquilloCompartit = true;
                }
              });
            }
          });
        }
      }
      return listObres;
    }


    function titlemodulcircular(tepermis)
    {
      var title="";
      if (tepermis){
        title=$translate.instant('TE_PERMIS_2');
      }
      else {
        title=$translate.instant('NO_TE_PERMIS_2');
      }
      return title;
    }


    function titlemodul(temodul,tepermis)
    {
      var title="";
      if (tepermis){
        title=$translate.instant('TE_PERMIS');
      }
      else {
        title=$translate.instant('NO_TE_PERMIS');
      }
      if (temodul)
      {
        title=title + $translate.instant('OBRA_TE_INFORMACIO');
      }
      else {
        title=title +$translate.instant('OBRA_NO_TE_INFORMACIO');
      }
      return title;
    }

    function hasaccessobraapp(obra, idapp) {
      var appcompartida = false;
      if (obra.listIdsAplicacionsCompartides!==null){
        for(var i = 0; i < obra.listIdsAplicacionsCompartides.length; i++) {
          if (obra.listIdsAplicacionsCompartides[i].toString() === idapp) {
            appcompartida = true;
            break;
          }
        }
      }
      return appcompartida ;
    }


    function editarSisquilloCompartit(idSisquilloCompartit,username) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.getSisquilloCompartit(idSisquilloCompartit).then(function(response) {
          vm.showCompartirModulos = true;
          vm.permisosModulsUsername = response.data.listaccesstcqi;
          var listappscompartides = response.data.listaplicaciorol;

          angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
            var compartida = false;

            for (var i=0;i<listappscompartides.length;i++ ) {
              if (listappscompartides[i].idaplicacio === permiso.idaplicacio) {
                compartida=true;
                break;
              }
            }
            permiso.id = compartida;

            if (permiso.id && listappscompartides[i].idrol !== null) {
              for(var j = 0; j < permiso.listrols.length; j++) {
                if (permiso.listrols[j].id === listappscompartides[i].idrol) {
                  vm.showrols = true;
                  permiso.rol = permiso.listrols[j];
                  break;
                }
              }
            }
          });

          vm.checkAgain = response.data.tornaracompartir;
          vm.checkDatosGenerales = response.data.dadesgenerals;
          vm.checkRemoveObra = response.data.eliminarobra;
          vm.checkExportTcqi = response.data.exportartcqi;
          vm.checkExportFie = response.data.exportarfie;
          vm.checkImportBim = response.data.importarbim;


          vm.idSisquilloCompartit = idSisquilloCompartit;
          vm.obraCompartidaObj.username = username;
          vm.obraCompartidaObj.idEntitat=response.data.identitat;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    if (angular.isUndefined(vm.obrescompartides) || vm.obrescompartides === null || (vm.obrescompartides !== null && vm.obrescompartides.length === 0)) {
      $timeout(function() {
          ngRepeatFinishedFunction();
      },0);     
    }


    // Se recalcula la altura del "scroll" (elemento con clase CSS "tcqi--with-scroll") de la capa de obras
    // cuando se cambia de identidad.
    $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
      ngRepeatFinishedFunction();
      vm.obraFound = true;
    });
    

    function ngRepeatFinishedFunction() {
      $rootScope.$broadcast('tcqiAppLoaded');

      if (angular.isUndefined(vm.obrescompartides) || vm.obrescompartides === null ||
          (vm.obrescompartides !== null && vm.obrescompartides.length === 0)) {
        var userSisquilloList = angular.element('.tcqi--table-tbody');
        var htmlUserSisquilloList = userSisquilloList[0];
        var top = htmlUserSisquilloList.getBoundingClientRect().top;
        var windowHeight = $window.innerHeight;

        $('#' + htmlUserSisquilloList.id).css('cssText', 'height:' + (windowHeight - top) + 'px !important');
      }
    }


  /*  if (angular.isUndefined($rootScope.acces) || $rootScope.acces === null) {
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(
        function(response) {
          $rootScope.acces = response.data.acces;
        }
      ).catch(function(){});
    }
*/

    function initParams(sortType, sortReverse) {
      var params = {          
        page: vm.cercaObj.page,
        perPage: vm.cercaObj.perPage
      };

      if(sortType === 'descripcio'){
        if(sortReverse){
          params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage,
          orderByTitol: -1,
          };
        }
        else{
          params = {
            page: vm.cercaObj.page,
            perPage: vm.cercaObj.perPage,
            orderByTitol: 1,
            };
        }
      }
      if(sortType === 'codi' || sortType === "") {
        if(sortReverse){
          params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage,
          orderByCodi: -1,
          };
        }
        else{
          params = {
            page: vm.cercaObj.page,
            perPage: vm.cercaObj.perPage,
            orderByCodi: 1,
            };
        }
      }
      if(sortType === 'dataCreacio'){
        if(sortReverse){
          params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage,
          orderByData: -1,
          };
        }
        else{
          params = {
            page: vm.cercaObj.page,
            perPage: vm.cercaObj.perPage,
            orderByData: 1,
            };
        }
      }
      return params;
    }



    // Funcion utilizada para cambiar el tipo de ordenacion en la home (codi, descripcio, data)

    // Reinicialitza el conjunt d'obres i carrega la primera pagina en l'ordre escollit per l'usuari.

    function showPagesOrdered(sortType, sortReverse) {
      vm.obres = [];
      vm.cercaObj.page = 0;
      var params = initParams(sortType, sortReverse);
      
      vm.canLoad = false;
      
      SisquilloFactory.getAllWithParams(params).then(function(response) {
        if (angular.isDefined(response.data.obres)) {
          response.data.obres.forEach(function(obj) {
            obj.isSubObraExpand=false;
            obj.visible=true;
            obj.isSubobra=false;
            vm.loadedPages.length = response.data.pages;
            vm.obres.push(obj);
          });
        }
            SessionStorage.save('PrimeraConsulta', response.data.pages);
            vm.canLoad = true;
            showIconAsObraCompartida(vm.obres,entitatActual.data.id);
            vm.loadedPages[vm.cercaObj.page] = 1;
            vm.cercaObj.page++;
            DataSharedService.setData({
              cercaObj: vm.cercaObj,
              obres: vm.obres
            });
          });
    }

    function tesubsisquillos(obra) {
      return obra.subSisquillos.length>0 ;
    }

    function setSubObraExpand(expand,obra) {
        obra.isSubObraExpand=expand;
        obra.subSisquillos.forEach(function(obj) {
          obj.visible=expand;
          obj.isSubobra=true;
        });
    }


    //Comprobamos si se ha creado una nueva obra
    function nuevaObra(){
      if(SessionStorage.get('idNewSisquillo') != undefined){
        var id = '#obra' + SessionStorage.get('idNewSisquillo');
        console.log(id);
        $scope.MoveScrollBar(id);
      }

    }

    $rootScope.$on('tooManySisquillos', function(){
      if(vm.obres.length > 200) {
        $rootScope.$broadcast('$activateLoader');
      }
    });

    if(vm.obres.length == 0 && SessionStorage.get('idNewSisquillo') == undefined && !vm.createdSisquillo) {
    loadSisquillos(false);
    }
    
    if (!angular.isDefined(data) && !Object.keys(data).length > 0) {
      loadImatgesObres();
    }
    $timeout(function() {
      var id = '#obra' + SessionStorage.get('idSelectedObra');
      if(SessionStorage.get('idSelectedObra') != undefined) {
        SessionStorage.save('idSelectedObra', undefined);
        //if(vm.obres.length > 20) vm.cercaObj.page = 0;
        var objective = angular.element(id);
        if (objective.length > 0) {
          //Scrolleamos a la obra
          $timeout(function() {
            angular.element("#tcqihome--obres-list-tbody")[0].scrollTop = (0, objective[0].offsetTop - 160);
            objective.css({
              'background-color': '#f8f0cb',
              'transition': 'background-color 0.5s ease'
            });
          });
        }
        $rootScope.$broadcast('$deactivateLoader');
        setTimeout(function() {
          objective.css('background-color', 'transparent');
        }, 1000);
      }
    });

    showObresByEntitat(false);
    nuevaObra();
    console.log(vm.obres.length);


    function esborrarsubobraarray(subobraesborrar){
      vm.obres.forEach(function(obra) {
        if (obra.id === subobraesborrar.idpare) {
          if (obra.subSisquillos!==null){
             obra.subSisquillos.forEach(function(subobra,index) {
              if (subobra.id === subobraesborrar.id) {
                obra.subSisquillos.splice(index,1);
              }
            });
          }
        }
      });
    }

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres
   * @description
   *
   */
  angular
    .module('itecTcqihome.configuracio', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.configuracio')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('configuracio', {
        url: '/configuracio',
        views: {
          'wrapper': {
            templateUrl: 'configuracio/configuracio.tpl.html',
            controller: 'ConfiguracioCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          configuracioData: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getConfiguracioData().catch(function(){});
          }],
          configuracio: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getConfiguracio().catch(function(){});
          }]

        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.configuracio.controller:ConfiguracioCtrl
   *
   * @description
   *
   */
  ConfiguracioCtrl.$inject = ["$state", "configuracioData", "configuracio", "$rootScope", "SisquilloFactory", "Notification"];
  angular
    .module('itecTcqihome.configuracio')
    .controller('ConfiguracioCtrl', ConfiguracioCtrl);

  function ConfiguracioCtrl($state, configuracioData, configuracio, $rootScope,
   SisquilloFactory, Notification) {

    var vm = this;

    vm.back = back;
    vm.saveConf = saveConf;

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';

    vm.configuracioList = configuracioData.data.tipusCopyDragConfList;
    vm.configuracio = {};

    if(angular.isDefined(configuracio.data) &&
        angular.isDefined(configuracio.data.tipusCopyDragConfList) &&
        configuracio.data.tipusCopyDragConfList != null){
      vm.configuracio.copyDragConf = configuracio.data.tipusCopyDragConfList;
    }
    else{
     vm.configuracio.copyDragConf = [];
    }

    vm.formulari = {
      nom: 'configuracioForm',
      func: function() {
        return vm.saveConf();
      },
      grups: [
       {
         title:'DEFAULT_CONFIGURACIO',
         camps: [
           {
             type: 'multiTransclude',
             name: 'imatge',
             columns: 12,
             block: "configuracio-copydrag-block"
           }
         ]
       }
     ]
    };

    function back() {
      $state.go('obres');
    }

    function saveConf() {
      $rootScope.$broadcast('$activateLoader');

      return SisquilloFactory.setConfiguracio(vm.configuracio.copyDragConf).then(function() {
        $rootScope.$broadcast('$deactivateLoader');
        Notification.success('DEFAULT_ACCIO_FINALITZADA_EXIT');
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        $rootScope.$broadcast('$deactivateLoader');
      });

    }

  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:theme
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('theme', {
      'name': 'tcqihome'
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuSettings
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuSettings', {
        '0': {
          'title': 'DEFAULT_CONFIGURACIO',
          'onclick': ''
        }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuLinks
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuLinks', {
        '0': {
          'name': 'DEFAULT_TCQI',
          'state': 'obres',
          'onclick': ''
        },
        '1':{
          'name': 'DEFAULT_COMPENSACIONS',
          'state': 'compensacions',
          'onclick': ''
        }
    });
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuIconsPosition
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuIconsPosition', 'up'); // Opciones: 'up', 'down', 'left' o 'right'.
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres
   * @description
   *
   */
  angular
    .module('itecTcqihome.compensacions', [
      'ui.router',
      'itecTcqihome.compensacions'
    ]);
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.compensacions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('compensacions', {
        url: '/compensacions',
        views: {
          'wrapper': {
            templateUrl: 'compensacions/compensacions.tpl.html',
            controller: 'CompensacionsCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          entitatActual: ["EntitatFactory", function(EntitatFactory) {
            return EntitatFactory.getActual().catch(function(){});
          }],
          allEntitats: ["EntitatFactory", function(EntitatFactory) {
            return EntitatFactory.getAll().then(
              function(response){
                if (angular.isDefined(response) && angular.isDefined(response.data)) {
                  response = response.data;
                  return response;
                }
              });
          }],
          dadesGeneralsData: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getDadesGenerals().catch(function(){});
          }],
          compensacionsData: ["SisquilloFactory", function(SisquilloFactory) {
            return SisquilloFactory.getCompensacionsEntitat().catch(function(){});
          }],
          obresCompartidesTotesEntitats: ["SisquilloFactory", "entitatActual", function(SisquilloFactory,entitatActual) {
             return SisquilloFactory.getSisquillosCompartitsByEntitat(entitatActual.data.id).
             then(function(response) {
                 if (angular.isDefined(response) && angular.isDefined(response.data)) {
                   return response.data;
                 }
              });
          }]
        }
      });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.obres.filter:obraByCodiAndDescripcio
   *
   * @description
   *
   */
obraByCodiAndDescripcioFilter.$inject = ["$log"];
  angular
    .module('itecTcqihome.obres')
    .filter('obraByCodiAndDescripcio', obraByCodiAndDescripcioFilter);

  function obraByCodiAndDescripcioFilter($log) {
  return function (items, search) {
      var result = [];
      angular.forEach(items, function (value, key) {
        if (value.codi !== null && value.descripcio !== null) {
          var codi = value.codi.replace(/\s+/g, ' ').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          var descripcio = value.descripcio.replace(/\s+/g, ' ').toLowerCase(); // Eliminar espacios en blanco y convertir a minúsculas
          search = search.replace(/\s+/g, ' ').toLowerCase(); // Eliminar los espacios en blanco de la cadena de búsqueda
          if (codi.indexOf(search) !== -1 || descripcio.indexOf(search) !== -1) {
              result.push(value);
          }
        }
      });
      return result;
  }
}
}());
(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.obres.controller:ObresCtrl
   *
   * @description
   *
   */
  CompensacionsCtrl.$inject = ["SisquilloFactory", "HelperFactory", "ConvertFactory", "FileSaver", "Notification", "$cookies", "$http", "$rootScope", "$scope", "$state", "$stateParams", "$window", "ApiLayoutConstant", "ImatgeFactory", "$mdDialog", "$document", "UploadFileConstants", "allEntitats", "PermisosFactory", "entitatActual", "$log", "$timeout", "dadesGeneralsData", "compensacionsData", "SessionStorage", "TcqihomeConstant", "ConfigService", "DataFactory", "obresCompartidesTotesEntitats", "ServerRootPathService", "$translate"];
  angular
    .module('itecTcqihome.compensacions')
    .controller('CompensacionsCtrl', CompensacionsCtrl);

  function CompensacionsCtrl(SisquilloFactory, HelperFactory, ConvertFactory,FileSaver, Notification, $cookies, $http,
      $rootScope, $scope, $state, $stateParams, $window, ApiLayoutConstant, ImatgeFactory, $mdDialog, $document,
      UploadFileConstants, allEntitats, PermisosFactory, entitatActual, $log, $timeout, dadesGeneralsData, compensacionsData,
      SessionStorage, TcqihomeConstant,ConfigService,DataFactory,obresCompartidesTotesEntitats,ServerRootPathService,$translate) {

    var vm = this;

    vm.maxObraSize = TcqihomeConstant.UPLOAD_OBRA_MAX_SIZE;
    vm.fields = [];
    vm.entitats = allEntitats;
    vm.errors = [];
    vm.showrols = false;
    vm.canLoad = true;

    vm.cercaObj = {
      page: 0,
      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
    };
    vm.emisionsObresCompartides = null;
    vm.loadSisquillos = loadSisquillos;
    vm.viewDades = viewDades;
    vm.viewDadesCompartides=viewDadesCompartides;
    vm.esborrar = esborrar;
    vm.copiarObra=copiarObra;
    vm.exportarObra = exportarObra;
    vm.exportarObraFIE = exportarObraFIE;
    vm.addSisquillo = addSisquillo;
    vm.openObra = openObra;
    var loadImatgesObres = loadImatgesObres;
    vm.showObresByEntitat = showObresByEntitat;
    vm.showDialogCompartirObra = showDialogCompartirObra;
    vm.shareSisquillo = shareSisquillo;
    vm.esborrarSisquilloCompartit = esborrarSisquilloCompartit;
    vm.editarSisquilloCompartit = editarSisquilloCompartit;
    vm.back = back;

    vm.size = UploadFileConstants.SIZE_MINIMAL;
    vm.listMode = true;

    vm.addButton = {};
    vm.addButton.class = 'material-icons tcqi--button-icon-left';
    vm.addButton.content = 'add';

    vm.continueButton = {};
    vm.continueButton.class = 'material-icons tcqi--button-icon-right';
    vm.continueButton.content = 'keyboard_arrow_right';

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';

    vm.searchText = '';
    vm.sortType = '';
    vm.sortReverse  = false;
    vm.setSubObraExpand=setSubObraExpand;

    if (vm.entitats.length>0){
      vm.entitatSelected = vm.entitats[0];
    }
    else {
      vm.entitatSelected =entitatActual.data;
    }

    SessionStorage.save('idEntitat', entitatActual.data.id);
    SessionStorage.save('idEntitatCompartida','');

    vm.checkAgain = false;
    vm.checkDatosGenerales = false;
    vm.checkRemoveObra = false;
    vm.checkExportTcqi = false;
    vm.checkExportFie = false;
    vm.checkImportBim = false;
    vm.idSisquilloCompartit = undefined;
    vm.dadesGeneralsData = dadesGeneralsData.data;
    vm.compensacionsData = compensacionsData.data;
    vm.ApiLayoutConstant = ApiLayoutConstant;
    vm.hasaccessobraapp = hasaccessobraapp;
    vm.tesubsisquillos=tesubsisquillos;
    vm.titlemodul=titlemodul;

    vm.tcqiApps = {
      'security': {
        'id': ApiLayoutConstant.APP_ID_ADMINISTRACIO,
        'image': $rootScope.prefixItecGuicomponents +
                'images/layout/header/menu/directives/images/logos/security.png',
        'app': ApiLayoutConstant.APP_NOM_ADMINISTRACIO,
        'title': 'DEFAULT_TCQI_SECURITY',
        'descr': ''
      },
      'manteniment': {
        'id': ApiLayoutConstant.APP_ID_MANTENIMENT,
        'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/manteniment.png',
        'app': ApiLayoutConstant.APP_NOM_MANTENIMENT,
        'title': 'DEFAULT_TCQI_MANTENIMENT',
        'descr': ''
       },
       'areacollaborativa': {
         'id': ApiLayoutConstant.APP_ID_AREACOLLABORATIVA,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/areaColaborativa.png',
         'app': ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA,
         'title': 'DEFAULT_TCQI_AREA_COLABORATIVA',
         'descr': ''
       },
       'comparacioofertes': {
         'id': ApiLayoutConstant.APP_ID_COMPARACIOOFERTES,
         'image': $rootScope.prefixItecGuicomponents +
                    'images/layout/header/menu/directives/images/logos/comparacioOfertes.png',
         'app': ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES,
         'title': 'DEFAULT_TCQI_COMPARACIO_OFERTES',
         'descr': ''
        },
        'estimaciocostos': {
          'id': ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS,
          'image': $rootScope.prefixItecGuicomponents +
                     'images/layout/header/menu/directives/images/logos/estimaciocostos.png',
          'app': ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
          'title': 'DEFAULT_TCQI_ESTIMACIO_COSTOS',
          'descr': ''
         }
      };

    vm.obraCompartidaObj = {};

    vm.formulari = {
      nom: 'formulari',
      func: loadPermisosUserToCheck,
      camps: [{
          type: 'text',
          name: 'username',
          label: 'DEFAULT_USERNAME',
          columns: 12,
          required: true
        }
      ]};

    function loadSisquillos(deactivateLoader) {
      if (angular.isUndefined(vm.cercaObj.pages) || (vm.cercaObj.page + 1) <= vm.cercaObj.pages) {
        //$rootScope.$broadcast('$activateLoader');

        if (vm.cercaObj.page === 0) {
          vm.obres = [];
        }

        var params = {
          page: vm.cercaObj.page,
          perPage: vm.cercaObj.perPage
        };
        vm.canLoad = false;

        SisquilloFactory.getAllWithParams(params).then(function(response) {
          if (angular.isDefined(response.data.obres)) {
            response.data.obres.forEach(function(obj) {
              obj.isSubObraExpand=false;
              obj.visible=true;
              obj.isSubobra=false;
            for(var i = 0; i < vm.compensacionsData.length; i++) {
              var item =  vm.compensacionsData[i];
              if(item.idSisquillo == obj.id){
                 obj.emisionsHome = item;
                  break;
              }
            }
              vm.obres.push(obj);
            });
          }
          vm.canLoad = true;
          vm.cercaObj.pages = response.data.pages;

          showIconAsObraCompartida(vm.obres,entitatActual.data.id);

          /*SisquilloFactory.getSisquillosCompartitsByEntitat(entitatActual.data.id).then(function(response) {*/
          //  showIconAsObraCompartida(vm.obres, response.data);
          /*}).finally(function() {*/
            /*if (deactivateLoader) {
              $rootScope.$broadcast('$deactivateLoader');
            }*/
          /*});*/
        });

        vm.cercaObj.page++;
      } else {
        vm.canLoad = false;
      }
    }

    function showIconAsObraCompartida(listObres, identitat) {
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              listObresCompartides.forEach(function(idObraCompartida) {
                if (listObres !== null) {
                  listObres.forEach(function(obra) {
                    if (obra.id === idObraCompartida) {
                      obra.isSisquilloCompartit = true;
                    }
                    else {
                      if (obra.subSisquillos!==null){
                        obra.subSisquillos.forEach(function(subobra) {
                          if (subobra.id === idObraCompartida) {
                            subobra.isSisquilloCompartit = true;
                          }
                        });
                      }
                    }
                  });
                }
              });
            }
          }
      }
      return listObres;
    }

    function showObresByEntitat(activateLoader) {
      if (activateLoader) {
        $rootScope.$broadcast('$activateLoader');
      } else {
        vm.showObras = false;
      }

      var params = {
          idEntitat: vm.entitatSelected.id,
          page: 0,
          perPage: vm.cercaObj.perPage
        };


      SisquilloFactory.getEntitatEmisions(params).then(
        function(response) {
          vm.obrescompartides = response.data;


       

          //SisquilloFactory.getSisquillosCompartitsByEntitat(vm.entitatSelected.id).then(function(response) {
            showIconAsObraCompartida(vm.obrescompartides, vm.entitatSelected.id);
          //}).finally(function() {
            $rootScope.$broadcast('$deactivateLoader');
            vm.showObras = true;
          //});
        }).catch(function(error){
          $log.error(error);
          vm.obrescompartides = undefined;

          $rootScope.$broadcast('$deactivateLoader');
          vm.showObras = true;
        });
    }


    function viewDades(id) {
      SessionStorage.save('idEntitatCompartida',undefined);
      SessionStorage.save('idSelectedObra',id);

      if (angular.isDefined(id) && id !== null) {
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }


    function viewDadesCompartides(id,dadesgenerals)
    {
      if (id && dadesgenerals) {
        SessionStorage.save('idEntitatCompartida',vm.entitatSelected.id);
        HelperFactory.setParams($stateParams, {'idObra': id});
        $state.go('obres.dades', $stateParams);
      }
    }

    function esborrar(obra,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.delete(obra.id,identcomp).then(function() {
        $scope.$evalAsync(function() {
          if (!obra.isSubobra)
          {
            HelperFactory.removeFromArray(vm.obres, obra.id);
          }
          else {
            esborrarsubobraarray(obra);
          }
        });
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }

    function exportarObra(id,compartida) {
      $rootScope.$broadcast('$activateLoader');
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }
      SisquilloFactory.exportarObra(id,identcomp).then(function(response){
        var blob = new Blob([response.data], {type: response.headers('Content-Type')});
        FileSaver.saveAs(blob, response.headers('filename'));
      }).catch(function(error){
        Notification.processError(error);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    function exportarObraFIE(id,compartida) {
      var identcomp=undefined;
      if(compartida){
        identcomp=vm.entitatSelected.id;
      }

      $mdDialog.show({
        controller: ["$scope", "$mdDialog", "SisquilloFactory", "Notification", "$translate", function DialogController($scope, $mdDialog, SisquilloFactory, Notification, $translate) {
          var vm = this;
          vm.export_plecs = true;
          vm.export_grafics = true;
          vm.export_amid = true;
          vm.export_codisoriginals=false;
          vm.errors = [];

          $scope.accept = function() {
          SisquilloFactory.exportarFie(id,vm.export_plecs,vm.export_grafics,vm.export_amid,vm.export_codisoriginals,identcomp).then(function(response){
            var blob = new Blob([response.data], {type: response.headers('Content-Type')});
            FileSaver.saveAs(blob, response.headers('filename'));
          }).catch(function(error){
            Notification.processError(error);
          }).finally(function() {
            $rootScope.$broadcast('$deactivateLoader');
          });
            $mdDialog.hide();
          };
          $scope.hide = function() {
            $mdDialog.cancel();
          };
          $scope.cancel = function() {
            $mdDialog.cancel();
          };
        }],

        controllerAs: 'ctrl',
        templateUrl: 'obres/exportar-a-fie/exportar-a-fie-dialog.tpl.html',
        parent: angular.element($document.body),
        clickOutsideToClose:true
      });
    }



    function copiarObra(idObra) {
        $rootScope.$broadcast('$activateLoader');
        var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
        DataFactory.get(url).then(function(response) {
        	var dadesinicials=response.data;
            $rootScope.$broadcast('$deactivateLoader');

            $mdDialog.show({
                controller: ["$scope", "$mdDialog", "Notification", "$translate", function DialogController($scope, $mdDialog,  Notification, $translate) {
                  var vmc = this;
                  vmc.copiar_codi = dadesinicials.codi;
                  vmc.copiar_descripcio = dadesinicials.descripcio;
                  vmc.copiar_bolBanc = dadesinicials.bolBanc;
                  vmc.copiar_teBanc = dadesinicials.bolBanc;
                  vmc.copiar_bolPressupost = dadesinicials.bolPressupost;
                  vmc.copiar_tePressupost = dadesinicials.bolPressupost;
                  vmc.copiar_bolSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_teSeguiment = dadesinicials.bolSeguiment;
                  vmc.copiar_bolPlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_tePlaTreballs = dadesinicials.bolPlaTreballs;
                  vmc.copiar_bolMA = dadesinicials.bolMA;
                  vmc.copiar_teMA = dadesinicials.bolMA;

                  vmc.copiar_bolPreusZero = dadesinicials.bolPreusZero;
                  vmc.copiar_bolFixarPreus = dadesinicials.bolFixarPreus;
                  vmc.copiar_bolDesFixarPreus = dadesinicials.bolDesFixarPreus;
                  vmc.copiar_bolSenseCT = dadesinicials.bolSenseCT;
                  vmc.copiar_bolSenseGrafics = dadesinicials.bolSenseGrafics;

                  vmc.errors = [];

                  vmc.fixarPreus = fixarPreus;
                  vmc.desFixarPreus = desFixarPreus;
                  vmc.copiarCertificacio=copiarCertificacio;

                  vmc.copiarMA=copiarMA;
                  vmc.preusZero=preusZero;

                  $scope.accept = function() {
                	vmc.errors = [];

                  	var form ={};
                  	form.sisIdCopia=idObra;
                    form.codi=  vmc.copiar_codi;
                    form.descripcio=  vmc.copiar_descripcio;
                    form.bolBanc=  vmc.copiar_bolBanc;
                    form.bolPressupost=vmc.copiar_bolPressupost;
                    form.bolSeguiment=  vmc.copiar_bolSeguiment;
                    form.bolPlaTreballs=  vmc.copiar_bolPlaTreballs;
                    form.bolMA=vmc.copiar_bolMA;
                    form.bolPreusZero=  vmc.copiar_bolPreusZero;
                    form.bolFixarPreus=  vmc.copiar_bolFixarPreus;
                    form.bolDesFixarPreus=  vmc.copiar_bolDesFixarPreus;
                    form.bolSenseCT=  vmc.copiar_bolSenseCT;
                    form.bolSenseGrafics=  vmc.copiar_bolSenseGrafics;


                    $rootScope.$broadcast('$activateLoader');
                    var url = ApiLayoutConstant.SERVER_PATH_LOADER + 'upload/' + idObra + '/copyObra';
                    DataFactory.add(url,form).then(function(response) {
                        Notification.success("DEFAULT_ACCIO_FINALITZADA_EXIT");
                    	$mdDialog.hide();
                        vm.cercaObj = {
                        	      page: 0,
                        	      perPage: TcqihomeConstant.PAGINATE_LIST_PER_PAGE
                        	    };
                        loadSisquillos(false);
                        loadImatgesObres();
                        showObresByEntitat(false);


                    }).catch(function(error){
                    	error.validations.forEach(function(validation){
                    		vmc.errors.push(validation);
                    	});
                        Notification.error("GENERIC_ERROR_DEFAULT");
                      }).finally(function() {
                        $rootScope.$broadcast('$deactivateLoader');
                      });
                  };
                  $scope.hide = function() {
                    $mdDialog.cancel();
                  };
                  $scope.cancel = function() {
                    $mdDialog.cancel();
                  };


                  // Funcions internes
                  function fixarPreus(){
                      if(vmc.copiar_bolFixarPreus===true){
                    	  vmc.copiar_bolDesFixarPreus = false;
                      }
                    }

                    function desFixarPreus(){
                        if(vmc.copiar_bolDesFixarPreus===true){
                      	  vmc.copiar_bolFixarPreus = false;
                        }

                        if(vmc.copiar_teSeguiment ===true){
	                        if(vmc.copiar_bolSeguiment ===true){
		                        	vmc.copiar_bolDesFixarPreus = false;
                            }
	                        else{
	                        	vmc.copiar_bolDesFixarPreus = true;
	                        }
                        }
                      }

                    function copiarCertificacio(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                            if(vmc.copiar_teSeguiment ===true){
	                        	vmc.copiar_bolDesFixarPreus = false;
                            }
                        }
                        if(vmc.copiar_bolSeguiment ===false && vmc.copiar_teSeguiment ===true ){
                        	vmc.copiar_bolDesFixarPreus = true;
                        }

                      }

                    function copiarMA(){
                        if(vmc.copiar_bolMA ===true){
                            vmc.copiar_bolBanc = true;
                            vmc.copiar_bolPressupost = true;
                        }

                      }



                    function preusZero(){
                        if(vmc.copiar_bolSeguiment ===true){
                        	vmc.copiar_bolPreusZero = false;
                        }
                      }

                }],

                controllerAs: 'ctrl',
                templateUrl: 'obres/copiar-obra/copiar-obra-dialog.tpl.html',
                parent: angular.element($document.body),
                clickOutsideToClose:true
              });


        }).catch(function(error){
            $rootScope.$broadcast('$deactivateLoader');
          });

      }


    function openObra(id, app, hasAccess,compartida) {
      var domini=ServerRootPathService.getServerRootPath();
      if (compartida){
          var parament='?entitat=' + vm.entitatSelected.id;
      }
      else {
          var parament='?entitat=' + ConfigService.getEntitat();
      }
      if (hasAccess) {
        if (app === ApiLayoutConstant.APP_NOM_PRESSUPOST) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PRESSUPOST +
            '/pressupost/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_QUALITAT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_QUALITAT +
            '/qualitat/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MEDIAMBIENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MEDIAMBIENT +
            '/mediambient/#/' + 'obres/' + id + '/compensacions/climatetrade' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGURETATISALUT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGURETATISALUT +
            '/seguretatisalut/#/' + 'obra/' + id + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_PLANIFICACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_PLANIFICACIO +
             '/planificacio/#/' + 'obres/' + id + '/tasques/gantt' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_SEGUIMENTECONOMIC +
            '/seguimenteconomic/#/' + id + '/obra/estructura' + parament);
        } else if (app === ApiLayoutConstant.APP_NOM_MANTENIMENT) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_MANTENIMENT +
            '/manteniment');
        } else if (app === ApiLayoutConstant.APP_NOM_AREACOLLABORATIVA) {
          $window.open(domini + 'areacolaborativa');
        } else if (app === ApiLayoutConstant.APP_NOM_COMPARACIOOFERTES) {
          $window.open(domini + 'comparadorofertes');
        } else if (app === ApiLayoutConstant.APP_NOM_ADMINISTRACIO) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ADMINISTRACIO +
            '/security');
        } else if (app === ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS) {
          if (angular.isUndefined(id)) {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos' + parament);
          } else {
            $window.open(domini + ApiLayoutConstant.SERVER_VERSION_ESTIMACIOCOSTOS +
              '/estimaciocostos/#/' + id + '/assignacio/estructura' + parament);
          }
        } else if (app === ApiLayoutConstant.APP_NOM_CONTROLCOSTOS) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_CONTROLCOSTOS +
            '/controlcostos/#/obra/' + id + '/arxiu' + parament);
        } /*else if (app === ApiLayoutConstant.APP_NOM_BANC) {
          $window.open(domini + ApiLayoutConstant.SERVER_VERSION_BANC +
            '/banctcq/#/' + id + '/obra/estructura'  + parament);
        }*/
      }
    }


    function showDialogCompartirObra(idObra,compartida) {
      vm.showCompartirModulos = false;
      vm.sisquillosCompartits = {};
      var entobra=undefined;
      if (compartida){
          entobra=vm.entitatSelected.id;
      }
      else {
          entobra=ConfigService.getEntitat();
      }
      // Se cargan los usuarios compartidos de esta obra.
      $rootScope.$broadcast('$activateLoader');
      SisquilloFactory.getSisquillosCompartits(idObra,entobra).then(function(response) {
        vm.sisquillosCompartits = response.data;
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });

      vm.obraSelected = idObra;
      vm.obraCompartidaObj.username = '';
      vm.obraCompartidaObj.idEntitat=entobra;
      vm.obraCompartidaObj.compartida=compartida;
    }


    function loadPermisosUserToCheck() {
      $rootScope.$broadcast('$activateLoader');

      vm.checkAgain = false;
      vm.checkDatosGenerales = false;
      vm.checkRemoveObra = false;
      vm.checkExportTcqi = false;
      vm.checkExportFie = false;
      vm.checkImportBim = false;

      //vm.obraCompartidaObj.idEntitat = vm.idEntitat;
      vm.obraCompartidaObj.idObra = vm.obraSelected;

      vm.idSisquilloCompartit = undefined;

      SisquilloFactory.getUsernamePermisos(vm.obraCompartidaObj).then(function(response) {
        vm.permisosModulsUsername = response.data;
        vm.showCompartirModulos = true;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
          vm.obraCompartidaObj.username = '';
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    function esborrarSisquilloCompartit(idSisquilloCompartit, index,identitatcompartida) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.deleteSisquilloCompartit(idSisquilloCompartit).then(function() {
        // Se cargan los usuarios compartidos de esta obra.
        SisquilloFactory.getSisquillosCompartits(vm.obraSelected,vm.obraCompartidaObj.idEntitat).then(function(response) {
          vm.sisquillosCompartits = response.data;
        }).finally(function() {
          $rootScope.$broadcast('$deactivateLoader');
          if (vm.sisquillosCompartits.listsisquillocompartit.length === 0) {
            hideIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
          }
        });
      });
    }

    function deleteFromListCompartida(idObraACompartir,identitat) {
      var index=undefined;
      if (obresCompartidesTotesEntitats != null) {
        if (obresCompartidesTotesEntitats[identitat]!=undefined){
            var listObresCompartides=obresCompartidesTotesEntitats[identitat];
            if (listObresCompartides != null && listObresCompartides.length > 0) {
              for(var i = 0; i < listObresCompartides.length; i++) {
                if (listObresCompartides[i] === idObraACompartir) {
                  index=i;
                  break;
                }
              }
              if (index!=undefined)
              {
                obresCompartidesTotesEntitats[identitat].splice(index,1);
              }
            }
          }
      }
    }




    function hideIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;
      deleteFromListCompartida(idObraACompartir,identitat);
      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            obra.isSisquilloCompartit = false;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              obra.isSisquilloCompartit = false;
            }
            else {
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  subobra.isSisquilloCompartit=false;
                }
              });
            }
          });
        }
      }
      return listObres;
    }


    function addSisquillo() {
      SessionStorage.save('idEntitatCompartida',undefined);
      $window.scrollTo(0, 0);
      HelperFactory.setParams($stateParams, {'isNew': true, 'idObra': null});
      $state.go('obres.dades', $stateParams);
    }


    function loadImatgesObres() {
      angular.forEach(vm.obres, function(obra, index) {
        if (obra.imatge) {
          ImatgeFactory.download(obra.imatge.id, vm.size, obra.id).then(function(response2) {
            vm.obres[index].imatge.data = response2.data;
          });
        }
      });
    }


    function back($form) {
      vm.showCompartirModulos = false;
      vm.obraCompartidaObj.username = '';
    }


    function shareSisquillo() {
      var form = {};
      form.idobra = vm.obraSelected;
      form.username = vm.obraCompartidaObj.username;
      form.tornaracompartir = vm.checkAgain;
      form.dadesgenerals = vm.checkDatosGenerales;
      form.eliminarobra = vm.checkRemoveObra;
      form.exportartcqi = vm.checkExportTcqi;
      form.exportarfie = vm.checkExportFie;
      form.importarbim = vm.checkImportBim;

      form.listaplicaciorol = [];
      //form.identitat=vm.entitatSelected.id;
      form.identitat=vm.obraCompartidaObj.idEntitat;

      angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
        if (permiso.id) {
          if (!angular.isUndefined(permiso.rol) && vm.showrols) {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': permiso.rol.id});
          } else {
            form.listaplicaciorol.push({'idaplicacio': permiso.idaplicacio, 'idrol': undefined});
          }
        }
      });

      var hasError = false;
      if (angular.isDefined(form.listaplicaciorol) && form.listaplicaciorol.length > 0) {
        if (angular.isUndefined(vm.idSisquilloCompartit)) {
          SisquilloFactory.addSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_CREATE_SUCCESS');
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        } else {
          form.id=vm.idSisquilloCompartit;

          SisquilloFactory.updateSisquilloCompartit(form).then(function() {
            showDialogCompartirObra(vm.obraSelected,vm.obraCompartidaObj.compartida);
            Notification.success('OBRES_SHARED_EDIT_SUCCESS');
            vm.idSisquilloCompartit=undefined;
          }).catch(function(error) {
            hasError = true;
            Notification.processError(error);
          }).finally(function(){
            if (!hasError) {
              showIconAsObraCompartidaByIdObra(vm.obraSelected,vm.obraCompartidaObj.idEntitat);
            }
          });
        }
      } else {
        Notification.error('obra.compartida.error.modules.not_checked');
      }
    }

    function actualitzallistaobrescompartides(idObraACompartir,identitat)
    {
      if (obresCompartidesTotesEntitats[identitat]!=undefined){
        obresCompartidesTotesEntitats[identitat].push(idObraACompartir);
      }
      else {
        obresCompartidesTotesEntitats[identitat]=[idObraACompartir];
      }
    }
    function showIconAsObraCompartidaByIdObra(idObraACompartir,identitat) {
      var found = false;

      if (vm.obrescompartides != null && vm.obrescompartides.length > 0) {
        vm.obrescompartides.forEach(function(obra) {
          if (idObraACompartir === obra.id) {
            if (!obra.isSisquilloCompartit) {
              actualitzallistaobrescompartides(idObraACompartir,identitat);
            }
            obra.isSisquilloCompartit = true;
            found = true;
          }
        });
      }

      if (!found) {
        if (vm.obres != null && vm.obres.length > 0) {
          vm.obres.forEach(function(obra) {
            if (idObraACompartir === obra.id) {
              if (!obra.isSisquilloCompartit) {
                actualitzallistaobrescompartides(idObraACompartir,identitat);
              }
              obra.isSisquilloCompartit = true;
            }
            else{
              obra.subSisquillos.forEach(function(subobra){
                if (idObraACompartir === subobra.id) {
                  if(!subobra.isSisquilloCompartit) {
                    actualitzallistaobrescompartides(idObraACompartir,identitat);
                  }
                  subobra.isSisquilloCompartit = true;
                }
              });
            }
          });
        }
      }
      return listObres;
    }

    function titlemodul(temodul,tepermis)
    {
      var title="";
      if (tepermis){
        title=$translate.instant('TE_PERMIS');
      }
      else {
        title=$translate.instant('NO_TE_PERMIS');
      }
      if (temodul)
      {
        title=title + $translate.instant('OBRA_TE_INFORMACIO');
      }
      else {
        title=title +$translate.instant('OBRA_NO_TE_INFORMACIO');
      }
      return title;
    }

    function hasaccessobraapp(obra, idapp) {
      var appcompartida = false;
      if (obra.listIdsAplicacionsCompartides!==null){
        for(var i = 0; i < obra.listIdsAplicacionsCompartides.length; i++) {
          if (obra.listIdsAplicacionsCompartides[i].toString() === idapp) {
            appcompartida = true;
            break;
          }
        }
      }
      return appcompartida ;
    }


    function editarSisquilloCompartit(idSisquilloCompartit,username) {
      $rootScope.$broadcast('$activateLoader');

      SisquilloFactory.getSisquilloCompartit(idSisquilloCompartit).then(function(response) {
          vm.showCompartirModulos = true;
          vm.permisosModulsUsername = response.data.listaccesstcqi;
          var listappscompartides = response.data.listaplicaciorol;

          angular.forEach(vm.permisosModulsUsername, function(permiso, index) {
            var compartida = false;

            for (var i=0;i<listappscompartides.length;i++ ) {
              if (listappscompartides[i].idaplicacio === permiso.idaplicacio) {
                compartida=true;
                break;
              }
            }
            permiso.id = compartida;

            if (permiso.id && listappscompartides[i].idrol !== null) {
              for(var j = 0; j < permiso.listrols.length; j++) {
                if (permiso.listrols[j].id === listappscompartides[i].idrol) {
                  vm.showrols = true;
                  permiso.rol = permiso.listrols[j];
                  break;
                }
              }
            }
          });

          vm.checkAgain = response.data.tornaracompartir;
          vm.checkDatosGenerales = response.data.dadesgenerals;
          vm.checkRemoveObra = response.data.eliminarobra;
          vm.checkExportTcqi = response.data.exportartcqi;
          vm.checkExportFie = response.data.exportarfie;
          vm.checkImportBim = response.data.importarbim;


          vm.idSisquilloCompartit = idSisquilloCompartit;
          vm.obraCompartidaObj.username = username;
          vm.obraCompartidaObj.idEntitat=response.data.identitat;
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        vm.showCompartirModulos = false;
        $timeout(function() {
          vm.errors = [];
        }, 5000);
      }).finally(function() {
        $rootScope.$broadcast('$deactivateLoader');
      });
    }


    // Se recalcula la altura del "scroll" (elemento con clase CSS "tcqi--with-scroll") de la capa de obras
    // cuando se cambia de identidad.
    $scope.$on('ngRepeatFinished', function(ngRepeatFinishedEvent) {
      $rootScope.$broadcast('tcqiAppLoaded');

      if (angular.isUndefined(vm.obrescompartides) || vm.obrescompartides === null ||
          (vm.obrescompartides !== null && vm.obrescompartides.length === 0)) {
        var userSisquilloList = angular.element('.tcqi--table-tbody');
        var htmlUserSisquilloList = userSisquilloList[0];
        var top = htmlUserSisquilloList.getBoundingClientRect().top;
        var windowHeight = $window.innerHeight;

        $('#' + htmlUserSisquilloList.id).css('cssText', 'height:' + (windowHeight - top) + 'px !important');
      }
    });


  /*  if (angular.isUndefined($rootScope.acces) || $rootScope.acces === null) {
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(
        function(response) {
          $rootScope.acces = response.data.acces;
        }
      ).catch(function(){});
    }
*/



    function tesubsisquillos(obra) {
      return obra.subSisquillos.length>0 ;
    }

    function setSubObraExpand(expand,obra) {
        obra.isSubObraExpand=expand;
        obra.subSisquillos.forEach(function(obj) {
          obj.visible=expand;
          obj.isSubobra=true;
        });
    }


    loadSisquillos(false);
    loadImatgesObres();
    showObresByEntitat(false);

    function esborrarsubobraarray(subobraesborrar){
      vm.obres.forEach(function(obra) {
        if (obra.id === subobraesborrar.idpare) {
          if (obra.subSisquillos!==null){
             obra.subSisquillos.forEach(function(subobra,index) {
              if (subobra.id === subobraesborrar.id) {
                obra.subSisquillos.splice(index,1);
              }
            });
          }
        }
      });
    }

  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name api
   * @description
   *
   */
  angular
    .module('itecTcqihome.api', [
      'ui.router',
      'itecTcqihome.api.entitat',
      'itecTcqihome.api.locale',
      'itecTcqihome.api.imatge',
      'itecTcqihome.api.logout',
      'itecTcqihome.api.sisquillo'
    ]);
}());
(function () {
  'use strict';

  config.$inject = ["$stateProvider"];
  angular
    .module('itecTcqihome.api')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('api', {
        url: '/api',
        templateUrl: 'api/views/api.tpl.html',
        controller: 'ApiCtrl',
        controllerAs: 'api'
      });
  }
}());

(function () {
  'use strict';

  config.$inject = ["$stateProvider", "$urlRouterProvider"];
  angular
    .module('itecTcqihome')
    .config(config);

  function config($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when('', '/obres');
    $urlRouterProvider.otherwise('/');

    $stateProvider
    .state('pagenotfound', {
      url: '/',
      views: {
        'wrapper': {
          template: '<itec-notfound initial-state="obres"></itec-notfound>',
        }
      },
    });
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .factory('AppFactory', AppFactory);

  function AppFactory() {
    /* private */
    var existsSession  = false;
    var appInitialized = false;

    /* public */
    var AppFactoryBase = {
      existsSession: function() {
        return existsSession;
      },
      setExistsSession: function(bool) {
        existsSession = (bool === true)? true : false;
      },
      getAppInitialized: function() {
        return appInitialized;
      },
      setAppInitialized: function(bool) {
        appInitialized = (bool === true)? true : false;
      }
    };

    AppFactoryBase.loadParams = function () {
        var params = {};

        /*
          // Carga síncrona de un fichero.
	        var xhr = new XMLHttpRequest();
	        xhr.onreadystatechange = function() {
	            if (xhr.readyState == 4 && xhr.status == 200) {
	                loadApps(xhr);
	            }
	        };
	        xhr.open('GET', 'tcqi-filename.json', false);
	        xhr.send();

	        function loadApps(json) {
	            var items = angular.fromJson(json.response);

	            params.foo = items.foo;
	        }
        */

        return params;
    };
    
    return AppFactoryBase;
  }
}());

(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name itecTcqihome.controller:AppCtrl
     *
     * @description
     *
     */
    AppCtrl.$inject = ["theme", "headerMenuLinks", "headerMenuSettings", "headerMenuIconsPosition", "$timeout", "ApiLayoutConstant", "DataFactory", "$location", "$scope", "AppWrapperFactory", "$rootScope", "PermisosFactory", "LocaleService", "$stateParams", "$state", "SessionStorage", "ConfigService", "AppFactory"];
    angular
      .module('itecTcqihome')
      .controller('AppCtrl', AppCtrl);

    function AppCtrl(theme, headerMenuLinks, headerMenuSettings, headerMenuIconsPosition, $timeout, ApiLayoutConstant, DataFactory, $location, $scope, AppWrapperFactory, $rootScope, PermisosFactory, LocaleService, $stateParams, $state, SessionStorage, ConfigService, AppFactory) {
      var vm = this;

      $rootScope.modul = theme;

      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_COMPLETE_API =
        'https://'+$location.$$host+'/api/manteniment/';
      //La aplicación de SEGURETAT(ADM)siempre utilizará esta url
      //independientemente del entorno.
      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_REPORTS_API =
        'https://'+$location.$$host+'/api/reportsmanteniment/b2b/';


      vm.app = {};
      vm.app.theme = theme;
      vm.app.headerMenuLinks = headerMenuLinks;
      vm.app.headerMenuIconsPosition = headerMenuIconsPosition;
      vm.app.headerMenuSettings = headerMenuSettings;
      vm.showLinkApps = true;


      /*
        Hem de fer varies crides paraleles per carregar tota la informació necessaria el primer cop (permisos, configuracions, notificacions, etc). Aquesta estructura permet saber com portem la carrega d'avançada porcentualment.
      */
      vm.initialized    = false;
      vm.loaded         = false;
      var promisesArray = [];
      vm.loadCalls = {
        num: 1,
        done: 0,
        errors: 0,
        initial: 40, //quan arribem aqui, ja tenim una part cargada
        percentage: 0, 
        loaded: false,
        calculate: function() {
          var callsPerc   = (this.done/this.num);
          var ponderat    = callsPerc*(100-this.initial);
          var perc        = this.initial+ponderat;
          this.percentage = Math.min(perc,100);
          this.loaded     = (this.done >= this.num);
        }
      };
      vm.loadCalls.percentage = vm.loadCalls.initial;

      function cridaPosterior(promise) {
        promisesArray.push(promise);
        
        vm.loadCalls.num++;
        promise.then(function(){
          vm.loadCalls.done++;
          vm.loadCalls.calculate();
        }).catch(function(){
          vm.loadCalls.done++;
          vm.loadCalls.errors++;
          vm.loadCalls.calculate();
        }); 
      }


      function endInitialization(delay) {
        //ho encapsulo en un timeout per allargar la directiva de loading esperant al renderitzat, pero caldria una solucio mes optima
        $timeout(function() {
          AppFactory.setAppInitialized(true);
          vm.initialized = AppFactory.getAppInitialized();
        }, delay || 0);
      }

      function loadEndingInsurance() {
        //en cas d'error "d'infinit" al promisesArray, posem aquest metode per assegurar-nos que informara al AppFactory igualment als 10 segons.
        endInitialization(10000);
      }


      //Per evitar multiples crides paral·leles que causen un 'session expired' error primer fem una que obté els permisos i de pas crea una sessió unica, i després fem totes les crides conseqüents.
      vm.accesosAppsTCQi = undefined;
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(function(response){
        vm.loadCalls.done++;
        vm.accesosAppsTCQi = response.data.acces;
        $rootScope.acces   = response.data.acces;

        //quan totes les crides posteriors acabin, informem al AppFactory
        //amb un callback;
        function cb() {
          vm.loadCalls.done++;
          vm.loadCalls.calculate();
          endInitialization(2000);          
        }
        Promise.all(promisesArray).then(cb).catch(cb);

      }).catch(function(error){
        endInitialization();
        console.log(error);
      }).finally(function(){
        loadEndingInsurance();
      });


      AppWrapperFactory.init($scope);


      vm.loaded = false;
      $scope.$on('$viewContentLoaded', function(ev, data){
        $timeout(function() {
          vm.loaded = true;
          $rootScope.$broadcast('tcqiAppLoaded');
        },0);
      });

      /*vm.logout=logout;

      function logout() {
        var promise = LogoutFactory.get().then(function(response){

        }).catch(function(error){

        });
        return promise;
      }*/

      vm.configuracioOnClick = configuracioOnClick;

      function configuracioOnClick () {
        $state.go('configuracio', $stateParams);
      }

      vm.app.headerMenuSettings[0].onclick = vm.configuracioOnClick;

    }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:app
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .constant('TcqihomeConstant', {
      'AUTH_TCQIHOME':'authtcqihome',
      'UPLOAD_OBRA_MAX_SIZE': '60MB',
      'Decimals': 2,
      'PAGINATE_LIST_PER_PAGE': 20
    })
    .constant('TipologiaPreguntaConstants', {
      'Numeric': 0,
      'Cadena': 1,
      'Boolea': 2
    });



}());
