(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name itecTcqihome.configuracio.controller:ConfiguracioCtrl
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.configuracio')
    .controller('ConfiguracioCtrl', ConfiguracioCtrl);

  function ConfiguracioCtrl($state, configuracioData, configuracio, $rootScope,
   SisquilloFactory, Notification) {

    var vm = this;

    vm.back = back;
    vm.saveConf = saveConf;

    vm.backButton = {};
    vm.backButton.class = 'material-icons tcqi--button-icon-left';
    vm.backButton.content = 'arrow_back';

    vm.configuracioList = configuracioData.data.tipusCopyDragConfList;
    vm.configuracio = {};

    if(angular.isDefined(configuracio.data) &&
        angular.isDefined(configuracio.data.tipusCopyDragConfList) &&
        configuracio.data.tipusCopyDragConfList != null){
      vm.configuracio.copyDragConf = configuracio.data.tipusCopyDragConfList;
    }
    else{
     vm.configuracio.copyDragConf = [];
    }

    vm.formulari = {
      nom: 'configuracioForm',
      func: function() {
        return vm.saveConf();
      },
      grups: [
       {
         title:'DEFAULT_CONFIGURACIO',
         camps: [
           {
             type: 'multiTransclude',
             name: 'imatge',
             columns: 12,
             block: "configuracio-copydrag-block"
           }
         ]
       }
     ]
    };

    function back() {
      $state.go('obres');
    }

    function saveConf() {
      $rootScope.$broadcast('$activateLoader');

      return SisquilloFactory.setConfiguracio(vm.configuracio.copyDragConf).then(function() {
        $rootScope.$broadcast('$deactivateLoader');
        Notification.success('DEFAULT_ACCIO_FINALITZADA_EXIT');
      }).catch(function(error) {
        vm.errors = Notification.processError(error);
        $rootScope.$broadcast('$deactivateLoader');
      });

    }

  }
}());
