(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.api.persona
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.entitat', []);
}());
