(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name itecTcqihome.controller:AppCtrl
     *
     * @description
     *
     */
    angular
      .module('itecTcqihome')
      .controller('AppCtrl', AppCtrl);

    function AppCtrl(theme, headerMenuLinks, headerMenuSettings, headerMenuIconsPosition, $timeout, ApiLayoutConstant, DataFactory, $location, $scope, AppWrapperFactory, $rootScope, PermisosFactory, LocaleService, $stateParams, $state, SessionStorage, ConfigService, AppFactory) {
      var vm = this;

      $rootScope.modul = theme;

      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_COMPLETE_API =
        'https://'+$location.$$host+'/api/manteniment/';
      //La aplicación de SEGURETAT(ADM)siempre utilizará esta url
      //independientemente del entorno.
      ApiLayoutConstant.SERVER_PATH_MANTENIMENT_REPORTS_API =
        'https://'+$location.$$host+'/api/reportsmanteniment/b2b/';


      vm.app = {};
      vm.app.theme = theme;
      vm.app.headerMenuLinks = headerMenuLinks;
      vm.app.headerMenuIconsPosition = headerMenuIconsPosition;
      vm.app.headerMenuSettings = headerMenuSettings;
      vm.showLinkApps = true;


      /*
        Hem de fer varies crides paraleles per carregar tota la informació necessaria el primer cop (permisos, configuracions, notificacions, etc). Aquesta estructura permet saber com portem la carrega d'avançada porcentualment.
      */
      vm.initialized    = false;
      vm.loaded         = false;
      var promisesArray = [];
      vm.loadCalls = {
        num: 1,
        done: 0,
        errors: 0,
        initial: 40, //quan arribem aqui, ja tenim una part cargada
        percentage: 0, 
        loaded: false,
        calculate: function() {
          var callsPerc   = (this.done/this.num);
          var ponderat    = callsPerc*(100-this.initial);
          var perc        = this.initial+ponderat;
          this.percentage = Math.min(perc,100);
          this.loaded     = (this.done >= this.num);
        }
      };
      vm.loadCalls.percentage = vm.loadCalls.initial;

      function cridaPosterior(promise) {
        promisesArray.push(promise);
        
        vm.loadCalls.num++;
        promise.then(function(){
          vm.loadCalls.done++;
          vm.loadCalls.calculate();
        }).catch(function(){
          vm.loadCalls.done++;
          vm.loadCalls.errors++;
          vm.loadCalls.calculate();
        }); 
      }


      function endInitialization(delay) {
        //ho encapsulo en un timeout per allargar la directiva de loading esperant al renderitzat, pero caldria una solucio mes optima
        $timeout(function() {
          AppFactory.setAppInitialized(true);
          vm.initialized = AppFactory.getAppInitialized();
        }, delay || 0);
      }

      function loadEndingInsurance() {
        //en cas d'error "d'infinit" al promisesArray, posem aquest metode per assegurar-nos que informara al AppFactory igualment als 10 segons.
        endInitialization(10000);
      }


      //Per evitar multiples crides paral·leles que causen un 'session expired' error primer fem una que obté els permisos i de pas crea una sessió unica, i després fem totes les crides conseqüents.
      vm.accesosAppsTCQi = undefined;
      PermisosFactory.loadWithURL(ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'accesos').then(function(response){
        vm.loadCalls.done++;
        vm.accesosAppsTCQi = response.data.acces;
        $rootScope.acces   = response.data.acces;

        //quan totes les crides posteriors acabin, informem al AppFactory
        //amb un callback;
        function cb() {
          vm.loadCalls.done++;
          vm.loadCalls.calculate();
          endInitialization(2000);          
        }
        Promise.all(promisesArray).then(cb).catch(cb);

      }).catch(function(error){
        endInitialization();
        console.log(error);
      }).finally(function(){
        loadEndingInsurance();
      });


      AppWrapperFactory.init($scope);


      vm.loaded = false;
      $scope.$on('$viewContentLoaded', function(ev, data){
        $timeout(function() {
          vm.loaded = true;
          $rootScope.$broadcast('tcqiAppLoaded');
        },0);
      });

      /*vm.logout=logout;

      function logout() {
        var promise = LogoutFactory.get().then(function(response){

        }).catch(function(error){

        });
        return promise;
      }*/

      vm.configuracioOnClick = configuracioOnClick;

      function configuracioOnClick () {
        $state.go('configuracio', $stateParams);
      }

      vm.app.headerMenuSettings[0].onclick = vm.configuracioOnClick;

    }
}());
