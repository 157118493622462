<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--with-scroll">
  <div class="obres-content">

    <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">
        <itec-button class="itec-float-right" label="DEFAULT_TORNAR" ng-click-function="ctrl.back()"
          icon-left="ctrl.backButton"></itec-button>
    </div>

    <!-- Botón de subir la imagen -->
    <div id="tcqihome--obres-dades-image-button" class="mdl-cell--12-col">
      <tcq-imatge-form-upload
        ng-if="!ctrl.isNew"
        select-files-text="DEFAULT_IMATGE"
        save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
        max-size="{{ctrl.maxUploadSize}}"
        max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
        show-only-icon="false"
        id-container="ctrl.obra.id"
        ng-model="ctrl.obra.imatge"
        icon-left="ctrl.iconLeft"
        server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_TCQIHOME}}"
        server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
        app-name="{{ctrl.ApiLayoutConstant.APP_NOM_TCQIHOME}}"
        auth-name="{{ctrl.TcqihomeConstant.AUTH_TCQIHOME}}"
        request-headers="ctrl.headers"
        can-delete="true"
        on-delete-function="ctrl.deleteImage">
      </tcq-imatge-form-upload>
    </div>

    <div class="mdl-cell--12-col tcqi--margin-bottom-20">
      <itec-group-form
          name="ctrl.formulari.nom"
          fields="ctrl.formulari.grups"
          ng-model="ctrl.obra"
          ng-submit-function="ctrl.formulari.func()"
          errors="ctrl.errors"
          backbutton="false"
          submit-button="!ctrl.obra.teSeguiment">

        <div transclude-to="tipologia-block" >
          <div class="tipologia-block" ng-if="$root.permisos.TIPOLOGIA.read">
            <div class="mdl-grid">
              <itec-input-text
                class="mdl-cell mdl-cell--4-col"
                name="tipologiaEDC"
                ng-model="ctrl.obra.tipologia.nom"
                label="{{'DEFAULT_TIPOLOGIA' | translate}}"
                validation-message-required="{{'VALIDATION_ERROR_NotEmpty' | translate}}"
                ng-required="true"
                ng-readonly="true"
                ng-disabled="ctrl.obra.teSeguiment">
              </itec-input-text>

              <itec-button
                class="mdl-cell mdl-cell--2-col"
                ng-click-function="ctrl.selectTipologia()"
                class="icons-size"
                icon-left="{'class' : 'icon-llista'}"
                show-only-icon='true'
                ng-disabled="ctrl.obra.teSeguiment"
                css-classes="itec-button-squared">
              </itec-button>
              <!-- fill up the line -->
              <div class="mdl-cell mdl-cell--6-col"></div>

              <itec-input-numeric ng-form="form" ng-repeat="pregunta in ctrl.obra.listPreguntes"
                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Numeric"
                class="mdl-cell mdl-cell--3-col"
                cut-label="true"
                ng-model="pregunta.valorNumeric"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                decimal-places="ctrl.TcqihomeConstant.Decimals"
                ng-required="false">
              </itec-input-numeric>

              <itec-input-text
                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Cadena"
                class="mdl-cell mdl-cell--3-col"
                cut-label="true"
                ng-model="pregunta.valorCadena"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                ng-required="false">
              </itec-input-text>

              <itec-checkbox
                ng-if="pregunta.tipus === ctrl.TipologiaPreguntaConstants.Boolea"
                class="mdl-cell mdl-cell--3-col"
                cut-label="true"
                ng-model="pregunta.valorBoolea"
                label="{{ctrl.getDescPregunta(pregunta)}}"
                ng-required="false">
              </itec-checkbox>
            </div>
          </div>

        </div>

      </itec-group-form>

    </div>
  </div>
</div>
