(function () {
  'use strict';

  angular
    .module('itecTcqihome.obres.dades')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('obres.dades', {
        url: '/{idObra:[0-9]{0,32}}/dades',
        views: {
          'wrapper@': {
              templateUrl : 'obres/dades/dades.tpl.html',
              controller  : 'ObresDadesCtrl',
              controllerAs: 'ctrl'
          }
        },
        resolve: {
          obraData: function(SisquilloFactory, $stateParams,SessionStorage) {
            if (angular.isDefined($stateParams.isNew) && $stateParams.isNew !== null && $stateParams.isNew) {
                return {};
            } else if (angular.isDefined($stateParams.idObra) && $stateParams.idObra !== null &&
                $stateParams.idObra) {
              return SisquilloFactory.get($stateParams.idObra,SessionStorage.get('idEntitatCompartida')).catch(function(){});
            } else {
              return {};
            }
          },
          tipologies: function(SisquilloFactory,$stateParams,SessionStorage) {
            return SisquilloFactory.getTipologies(SessionStorage.get('idEntitatCompartida')).catch(function(){});
          },
          dadesGeneralsData: function(SisquilloFactory,$stateParams,SessionStorage) {
            return SisquilloFactory.getDadesGenerals(SessionStorage.get('idEntitatCompartida')).catch(function(){});
          }

        }
      });
  }
}());
