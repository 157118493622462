<div id="compartir-obra-form" ng-cloak>

  <!-- Formulario con el nombre del usuario -->
  <div ng-if="!ctrl.showCompartirModulos">
    <itec-form
      ng-if="$root.permisos.SISQUILLO.add"
      name="ctrl.formulari.nom"
      fields="ctrl.formulari.camps"
      ng-model="ctrl.obraCompartidaObj"
      ng-submit-function="ctrl.formulari.func()"
      errors="ctrl.errors"
      submit-button-text="DEFAULT_CONTINUE"
      submit-button-icon-right="ctrl.continueButton">
    </itec-form>

    <p ng-if ="ctrl.sisquillosCompartits.compartidaperuser != null" id="compartir-obra-form--compartitPer-title"
        class="tcqi--margin-left-30 tcqi--margin-bottom-20">
      <span>Obra compartida per {{ctrl.sisquillosCompartits.compartidaperuser}}.</span>
    </p>

    <md-card ng-show="ctrl.sisquillosCompartits.listsisquillocompartit.length > 0 && $root.permisos.SISQUILLO.read"
        id="list-sisquillo-compartit" class="tcqi--margin-top-10 tcqi--margin-10">

      <md-card-content>
        <div ng-repeat="sisquilloCompartit in ctrl.sisquillosCompartits.listsisquillocompartit track by $index"
             class="mdl-grid mdl-grid--no-spacing">

          <div class="mdl-cell--10-col">{{sisquilloCompartit.username}}</div>

          <div class="mdl-cell--2-col">
            <button ng-if="$root.permisos.SISQUILLO.delete"
                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"
                    title="{{::'DEFAULT_ESBORRAR_OBRA_COMPARTIDA' | translate}}"
                    itec-dialog-confirm
                    itec-dialog-confirm-title="OBRES_DELETE_COMPARTIT_TITLE"
                    itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT_COMPARTIT"
                    itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"
                    itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"
                    itec-dialog-confirm-function-ok=
                        "ctrl.esborrarSisquilloCompartit(sisquilloCompartit.id, $index,sisquilloCompartit.identitatcompartida)">
              <i class="material-icons">delete_forever</i>
            </button>

            <button ng-if="$root.permisos.SISQUILLO.edit"
                    class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored itec-float-right"
                    title="{{::'DEFAULT_EDITAR_OBRA_COMPARTIDA' | translate}}"
                    ng-click="ctrl.editarSisquilloCompartit(sisquilloCompartit.id,sisquilloCompartit.username)">
                    <i class="material-icons">edit</i>
            </button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>


  <!-- Formulario con los módulos y permisos -->
  <div ng-if="ctrl.showCompartirModulos" id="tcqihome--obres-permisos" class="tcqi--padding-30">
    <h5 class="bold"><span>{{ctrl.obraCompartidaObj.username}}</span></h5>

    <itec-button class="itec-float-right" ng-click-function="ctrl.back()"
      label="{{'DEFAULT_TORNAR' | translate}}" icon-left="ctrl.backButton"></itec-button>


    <div id="tcqihome--obres-modulos" class="tcqi--padding-10 tcqi--padding-left-30">
      <div id="tcqihome--obres-modulos-title" class="tcqi--margin-bottom-20">
        {{'obra.compartida.modulos' | translate }}:
      </div>

      <a ng-show="!ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=true;">
        {{'obra.compartida.mostrar.permisos' | translate}}
      </a>
      <a ng-show="ctrl.showrols" class="itec-float-right tcqi--margin-right-20" ng-click="ctrl.showrols=false;">
        {{'obra.compartida.ocultar.permisos' | translate}}
      </a>

      <form>
        <div ng-repeat="permiso in ctrl.permisosModulsUsername track by $index"
            class="mdl-grid mdl-grid--no-spacing">

          <div class="mdl-cell--4-col">
          <itec-checkbox ng-if="permiso.acces"
            label="{{permiso.nom}}"
            ng-model="permiso.id"
            ng-class="{'bold': permiso.id}">
          </itec-checkbox>
        </div>

        <div ng-show="ctrl.showrols" class="rols mdl-cell--8-col">
          <itec-select
            ng-if="permiso.acces"
            empty-option="true"
            name="rol"
            list="permiso.listrols"
            property-label="nom"
            ng-model="permiso.rol"
            property-id="id"
            class="itec-float-right">
          </itec-select>
        </div>
      </div>
      </form>
    </div>

    <div id="compartir-obra-form--actions-to-share"
        class="mdl-grid mdl-grid--no-spacing tcqi--margin-top-10 tcqi--padding-10 tcqi--padding-left-30">

      <div class="mdl-cell--6-col">
        <itec-checkbox label="SISQUILLO_COMPARTIT_AGAIN" ng-model="ctrl.checkAgain"></itec-checkbox>
        <itec-checkbox label="SISQUILLO_DATOS_GENERALES" ng-model="ctrl.checkDatosGenerales"></itec-checkbox>
        <itec-checkbox label="SISQUILLO_ELIMINAR_OBRA" ng-model="ctrl.checkRemoveObra"></itec-checkbox>
      </div>
      <div class="mdl-cell--6-col">
        <itec-checkbox ng-if="false" label="SISQUILLO_IMPORTAR_BIM" ng-model="ctrl.checkImportBim" >
        </itec-checkbox>
        <itec-checkbox label="SISQUILLO_EXPORTAR_TCQI" ng-model="ctrl.checkExportTcqi" >
        </itec-checkbox>
        <itec-checkbox label="SISQUILLO_EXPORTAR_FIE" ng-model="ctrl.checkExportFie" >
        </itec-checkbox>
      </div>
    </div>

    <itec-button class="itec-float-right tcqi--margin-top-10 tcqi--margin-bottom-20"
      ng-click-function="ctrl.shareSisquillo()" label="{{'DEFAULT_COMPARTIR' | translate}}">
    </itec-button>
  </div>
</div>
