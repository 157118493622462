(function () {
  'use strict';

  /**
   * @ngdoc service
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .factory('AppFactory', AppFactory);

  function AppFactory() {
    /* private */
    var existsSession  = false;
    var appInitialized = false;

    /* public */
    var AppFactoryBase = {
      existsSession: function() {
        return existsSession;
      },
      setExistsSession: function(bool) {
        existsSession = (bool === true)? true : false;
      },
      getAppInitialized: function() {
        return appInitialized;
      },
      setAppInitialized: function(bool) {
        appInitialized = (bool === true)? true : false;
      }
    };

    AppFactoryBase.loadParams = function () {
        var params = {};

        /*
          // Carga síncrona de un fichero.
	        var xhr = new XMLHttpRequest();
	        xhr.onreadystatechange = function() {
	            if (xhr.readyState == 4 && xhr.status == 200) {
	                loadApps(xhr);
	            }
	        };
	        xhr.open('GET', 'tcqi-filename.json', false);
	        xhr.send();

	        function loadApps(json) {
	            var items = angular.fromJson(json.response);

	            params.foo = items.foo;
	        }
        */

        return params;
    };
    
    return AppFactoryBase;
  }
}());
