<md-dialog  aria-label="{{'DEFAULT_VINCULAR_PROJECTE' | translate}}">
  <form>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>{{'DEFAULT_VINCULAR_PROJECTE' | translate}}</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="hide()">
          <md-icon aria-label="{{'DEFAULT_TANCAR' | translate}}">close</md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content  style="min-width:800px;max-width:1000px;max-height:810px; ">
      <div class="md-dialog-content">
        <div class="mdl-grid">

        <div class="mdl-cell mdl-cell--12-col">
          <itec-error-block errors="ctrl.errors"></itec-error-block>
        
        <div class="mdl-grid mdl-grid--no-spacing tcqi--with-scroll">
          <itec-simple-list
            class="mdl-cell mdl-cell--12-col"
            show-add-button="false"
            show-edit-button="false"
            show-delete-button="false"
            items="ctrl.listItems"
            fields="ctrl.fields"
            show-text-wrapped="true"
            click-field-checkbox="true"
            formatter-function="ctrl.formatter"
            >
          </itec-simple-list>
          <div ng-if="ctrl.showNoProjects">
            <span>{{'DEFAULT_NO_PROJECTES_TCQICITIES' | translate}}</span>
          </div >
        </div>
        </div>

      </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="hide()">
       {{'DEFAULT_CANCELAR' | translate}}
      </md-button>
      <md-button class="md-primary md-button md-default-theme md-ink-ripple" ng-click="accept()">
        {{'DEFAULT_ACCEPTAR' | translate}}
      </md-button>
    </md-dialog-actions>
  </form>
</md-dialog>