(function () {
  'use strict';

  /* @ngdoc object
   * @name api
   * @description
   *
   */
  angular
    .module('itecTcqihome.api', [
      'ui.router',
      'itecTcqihome.api.entitat',
      'itecTcqihome.api.locale',
      'itecTcqihome.api.imatge',
      'itecTcqihome.api.logout',
      'itecTcqihome.api.sisquillo'
    ]);
}());