<html>
<head><title>Not Found</title></head>
<body bgcolor="white">
<center><h1>Not Found</h1></center>
<hr><center>itec - tcqi</center>

<form action="v1/tcqihome/">
	<center>
  	<input type="submit" value="Go to Homepage" />
  </center>
</form>

</body>
</html>