(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.shared.arbol-tipologia
   *
   */
  angular
    .module('itecTcqihome.shared.arbreTipologies', []);
}());
