(function () {
'use strict';

/**
 * @ngdoc service
 * @name itecTcqihome.api.logout.factory:LogoutFactory
 *
 * @description
 *
   */
  angular
    .module('itecTcqihome.api.logout')
    .factory('LogoutFactory', LogoutFactory);

    function LogoutFactory(LogoutAllFactory) {
      var LogoutBase = {
        get: function() {
            return LogoutAllFactory.logoutall();
        }
      };
      return LogoutBase;
    }
}());
