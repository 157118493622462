(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:headerMenuSettings
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .value('headerMenuSettings', {
        '0': {
          'title': 'DEFAULT_CONFIGURACIO',
          'onclick': ''
        }
    });
}());
