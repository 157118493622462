(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.sisquillo.factory:SisquilloFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.sisquillo')
    .factory('SisquilloFactory', SisquilloFactory);

  function SisquilloFactory(DataFactory, ApiLayoutConstant) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'obres';
    var api_security = ApiLayoutConstant.SERVER_PATH_ADMINISTRACIO;


    var base = {

      getAllWithParams: function(params){
        var url = api;
        return DataFactory.getWithParams(url, params);
      },

      getSisquilloPage: function(params){
        var url = api + '/page';
        return DataFactory.getWithParams(url, params);
      },

      getEntitatEmisions: function(obj) {
        var url = api + '/entitatEmisions';
        return DataFactory.getWithParams(url, obj);
      },

      getAllByEntitat: function(obj) {
        var url = api + '/entitat';
        return DataFactory.getWithParams(url, obj);
      },

      getDadesGenerals: function(identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/dades_generals' + parament;
        return DataFactory.get(url);
      },

      getTipologies: function(identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/tipologies/all' + parament;
        return DataFactory.get(url);
      },

      get: function(id,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id + parament;
        return DataFactory.get(url);
      },

      getSisquilloTipologies: function (idSisquillo, idTipologia,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url;
        if (idSisquillo !== undefined) {

          url = api + '/' + idSisquillo + '/' + idTipologia + '/preguntes' + parament;
        }
        else {
          url = api + '/' + idTipologia + '/preguntes' + parament;
        }
        return DataFactory.get(url);
      },

      getSisquillosCompartits: function(id,identitatobra) {
        var url = api + '/' + id + '/sisquillosCompartits?idEntitatCompartida=' + identitatobra;
        return DataFactory.get(url);
      },

      update: function(id, obj,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id + parament;
        return DataFactory.update(url,obj);
      },

      add: function(obj){
        var url = api;
        return DataFactory.add(url,obj);
      },

      getUsernamePermisos: function (obj) {
        var url = api + '/loadPermisosByModuls';
        return DataFactory.getWithParams(url, obj);
      },

      getSisquilloCompartit: function(id) {
        var url = api + '/sharedwork/' +id;
        return DataFactory.get(url);
      },
      addSisquilloCompartit: function(obj) {
        var url = api + '/sharedwork';
        return DataFactory.add(url, obj);
      },

      updateSisquilloCompartit: function(obj) {
        var url = api + '/sharedwork';
        return DataFactory.update(url, obj);
      },

      delete: function(id,identcomp){
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api;
        return DataFactory.delete(url, id + parament);
      },

      deleteSisquilloCompartit: function(id) {
        var url = api + '/sharedwork';
        return DataFactory.delete(url, id);
      },

      getSisquillosCompartitsByEntitat: function(idEntitat) {
        //var url = api + '/sharedwork/entitat/' + idEntitat;
        var url = api + '/sharedwork/allentitats'
        return DataFactory.get(url);
      },

      download: function(id) {
        var url = api + '/' + id +'/download';
        return DataFactory.get(url);
      },

      getDocumentsBim: function(id,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = api + '/' + id +'/documentsBim' + parament;
        return DataFactory.get(url);
      },

      getConfiguracioData: function() {
        var url = api + '/configuracioData';
        return DataFactory.get(url);
      },

      getConfiguracio: function() {
        var url = api + '/configuracio';
        return DataFactory.get(url);
      },

      setConfiguracio: function(obj){
        var url = api + '/configuracio';
        return DataFactory.update(url,obj);
      },

      exportarObra: function(id,identcomp) {
        var parament='';
        if (identcomp !== undefined){
              parament='?idEntitatCompartida=' + identcomp;
        }
        var url = '/api/' + api + '/' + id +'/download' + parament;
        return DataFactory.getWithParamsReports(url, {});
      },

      exportarFie: function(id,export_plecs,export_grafics,export_amid,export_codisoriginals,identcomp) {
        var parament='';
        if (identcomp !== undefined){
          // S'ha de posar el & en lloc del ? , perque té paràmetres anteriors
              parament='&idEntitatCompartida=' + identcomp;
        }

        var url = '/api/' + api + '/' + id +'/downloadfie/'  +
              '?volPlecs=' + export_plecs + '&volGrafics=' + export_grafics +
              '&volAmids=' + export_amid + '&volCodisOriginals=' + export_codisoriginals + parament;
        return DataFactory.getWithParamsReports(url, {});
      },

      getCompensacionsEntitat: function() {
        var url = api + '/compensacionsEntitatPropia';
        return DataFactory.get(url);
      },

      //TCQi Cities
      setVinculacio: function(idSisquillo, listProjectesVinculacio){
        var url = api + '/tcqicities/sisquillo/' + idSisquillo + '/vinculacio';
        return DataFactory.update(url, listProjectesVinculacio);
      },

      getListProjectesTCQiCities: function(idSisquillo){
           var url = api + '/tcqicities/sisquillo/' + idSisquillo + '/projecte/list';
        return DataFactory.get(url);
      }

      //fi TCQi Cities

    };
    return base;
  }
}());
