(function () {
  'use strict';

  angular
    .module('itecTcqihome.configuracio')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('configuracio', {
        url: '/configuracio',
        views: {
          'wrapper': {
            templateUrl: 'configuracio/configuracio.tpl.html',
            controller: 'ConfiguracioCtrl',
            controllerAs: 'ctrl'
          }
        },
        resolve: {
          configuracioData: function(SisquilloFactory) {
            return SisquilloFactory.getConfiguracioData().catch(function(){});
          },
          configuracio: function(SisquilloFactory) {
            return SisquilloFactory.getConfiguracio().catch(function(){});
          }

        }
      });
  }
}());
