(function () {
  'use strict';

  /* @ngdoc object
   * @name itecTcqihome.obres
   * @description
   *
   */
  angular
    .module('itecTcqihome.compensacions', [
      'ui.router',
      'itecTcqihome.compensacions'
    ]);
}());
