"use strict";

angular.module('sharedData', [])
  .factory('DataSharedService', function() {
    var data = {};

    return {
      getData: function() {
        return data;
      },
      setData: function(newData) {
        data = newData;
      }
    };
  });