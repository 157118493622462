(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.arbol-tipologia
   * @restrict EA
   * @element
   *
   * @description
   *
   * <arbre-tipologies
       tipologies="ctrl.tipologies"
       tipologia-selected="ctrl.tipologiaSelected"
       submit-function="ctrl.confirm"
       cancel-function="ctrl.cancel()">
     </arbre-tipologies>
   *
   *
   * "ctrl.tipologies"         --> Lista de las tipologías
   * "ctrl.tipologiaSelected"  --> Si la obra tiene tipologia
   * "ctrl.confirm"            --> Funcion confirmar del controlador del dialog
   * "ctrl.cancel()"           --> Funcion cancelar del controlador del dialog
   *
   */

  angular
    .module('itecTcqihome.shared.arbreTipologies')
    .directive('arbreTipologies', arbreTipologies);

  function arbreTipologies() {
    return {
      restrict: 'EA',
      scope: {
          tipologies: '=',
          tipologiaSelected: '=?',
          submitFunction: '=',
          cancelFunction: '&'
      },
      templateUrl: 'shared/arbre-tipologies/arbre-tipologies-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope, $rootScope) {
        var vm = this;

        vm.backButton = {};
        vm.backButton.class = 'material-icons tcqi--button-icon-left';
        vm.backButton.content = 'arrow_back';
        
        vm.tipologies = [];
        vm.expanded = [];
        vm.tipologiaSelected = $scope.tipologiaSelected;
        vm.cancelFunction = $scope.cancelFunction;
        vm.submitFunction = $scope.submitFunction;

        // Función que envía la tipología seleccionada al controlador de
        // la función $scope.submitFunction.
        vm.guardarCanvisTipologia = function () {
          var item = vm.getItemChecked();
          $scope.$eval($scope.submitFunction(item));
        }

        // Monta l'arbre de tipologies a partir de la llista
        vm.addChildren = function (parent) {

          if (parent !== undefined) {
            parent.children = [];
          }
          angular.forEach ($scope.tipologies, function (item) {
            if ((parent === undefined) && (item.idPare === null)) {
              vm.tipologies.push (item);
              vm.addChildren (item);
              // El node rel sempre surt expanded
              vm.expanded.push(item);
            }

            if ((parent !== undefined) && (item.idPare === parent.id)) {
              parent.children.push(item);
              vm.addChildren (item);
            }
          });
        };

        // fica l'item amb el id a expanded i expandeix el seu pare
        vm.expandItemAndParent = function (id) {
          if (id === null) return;
          angular.forEach ($scope.tipologies, function (item) {
            if (item.id === id) {
              vm.expanded.push(item);
              vm.expandItemAndParent (item.idPare);
            }
          });
        };

        // Obte l'element checkejat o null si no n'hi ha cap
        vm.getItemChecked = function () {
          var result = null;
          angular.forEach ($scope.tipologies, function (item) {
            if (item.checked) {
              result = item;
            }
          });
          console.log(result);
          return result;
        };

        // Busquem el item seleccionat i critem a expandir tot el seu cami
        vm.expandCheckedPath = function () {
          var item = vm.getItemChecked();
          if (item !== null) {
            vm.expanded = [];
            vm.expandItemAndParent(item.idPare);
          }
        };

        // Busquem el node i el fiquem a checked
        vm.setTipologiaToChecked = function(node) {
          if ((node !== null) && (node !== undefined)) {
            angular.forEach ($scope.tipologies, function (item) {
              if (item.id == node.id) {
                item.checked = true;
              }
              else {
                item.checked = false;
              }
            });
          }
        };

        vm.setTipologiaToChecked(vm.tipologiaSelected);
        vm.addChildren ();
        vm.expandCheckedPath();

        // Quan checkejen fiquem la resta a unchecked
        vm.changeCheck = function(node) {
          if (node.checked) {
            vm.setTipologiaToChecked(node);
          }
        };

      }
      ,
      link: function (scope, element, attrs) {
      }
    };
  }
}());
