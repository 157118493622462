(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.constant:app
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome')
    .constant('TcqihomeConstant', {
      'AUTH_TCQIHOME':'authtcqihome',
      'UPLOAD_OBRA_MAX_SIZE': '60MB',
      'Decimals': 2,
      'PAGINATE_LIST_PER_PAGE': 20
    })
    .constant('TipologiaPreguntaConstants', {
      'Numeric': 0,
      'Cadena': 1,
      'Boolea': 2
    });



}());
