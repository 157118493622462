<!-- <img src="vendor/itec-icons/images/tcqi-icona-ban.png"/> -->
<div id="tcqi-content" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing">

  <!-- Botones -->
  <div id="tcqihome--obres-buttons" class="mdl-cell--12-col tcqi--margin-top-30">
    <div class="tcqihome--obres-title">
      <i class="material-icons">folder</i>
      <span class="bold">{{::'OBRES_TOTES_OBRES' | translate}}</span>
      <img class="co2-image" ng-src="images/resources/images/co2.png">
   

    </div>

    <button class="tcqi--margin-left-30 tcqi--margin-right-15 itec-float-right mdl-button mdl-js-button
        mdl-button--icon mdl-button--colored" ng-click="ctrl.listMode = !ctrl.listMode"
        ng-if="(ctrl.obres.length > 0 || ctrl.obrescompartides.length>0)">

      <i ng-if="ctrl.listMode" class="icon-th-large" style="font-size: large;"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_ICONES' | translate}}">

      </i>
      <i ng-if="!ctrl.listMode" class="material-icons"
         title="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}"
         aria-label="{{::'DEFAULT_ACCIONS_VISUALITZAR_LLISTA' | translate}}">
        format_list_bulleted
      </i>
    </button>




    <form ng-if="(ctrl.obrescompartides.length>0 || ctrl.obres.length > 0) && $root.permisos.SISQUILLO.read"
        class="itec-float-right">

      <div ng-show="ctrl.expanded" class="tcqihome-search itec-float-right mdl-textfield mdl-js-textfield">
        <input class="mdl-textfield__input" type="text" id="search-expandable" ng-model="ctrl.searchText" />
        <label class="mdl-textfield__label" for="search-expandable">
          {{::'DEFAULT_CERCA_CODI_O_TITOL' | translate}}
        </label>
      </div>

      <label class="itec-float-right mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
             ng-click="ctrl.expanded=!ctrl.expanded">
        <i class="material-icons" title="{{::'DEFAULT_CERCAR' | translate}}"
            aria-label="{{::'DEFAULT_CERCAR' | translate}}">search</i>
      </label>
    </form>
  </div>



  <!-- Módulos "TCQi" -->
  <div ng-if="ctrl.obres.length === 0 && ctrl.obrescompartides.length===0" id="tcqihome--obres-no-items">
    <span>{{::'DEFAULT_NO_ITEM' | translate}}</span>
    <div class="clear"></div>

    <div class="tcqihome--obres-title">
      <i class="icon-icono-grups-blanc"></i>
      <span class="bold">{{::'DEFAULT_MODULS' | translate}}</span>
    </div>
    <div class="tcqi-table mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqi--margin-top-20">
      <div ng-repeat="tcqiApp in ctrl.tcqiApps track by $index" class="tcqihome--obres-apps mdl-cell--2-col">
        <div ng-class="{'tcqiApps--app-disabled': !$root.acces[tcqiApp.id].acces}"
             ng-click="ctrl.openObra(undefined, tcqiApp.app, $root.acces[tcqiApp.id].acces)">
          <a>
            <img ng-src="{{tcqiApp.image}}"><br>
            <span class="itec-float-left tcqi--margin-top-10">
              {{::tcqiApp.title | translate | uppercase}}
            </span>
          </a>
        </div>

        <div class="clear"></div>
        <span>{{::tcqiApp.descr}}</span>
      </div>
    </div>
  </div>




  <!-- Obras propias del usuario en sesión -->
  <div ng-if="ctrl.obres.length === 0 && $root.permisos.SISQUILLO.read" ng-show="ctrl.showObras">
    {{::'OBRES_EMPTY_LIST' | translate}}
  </div>
  <div ng-if="ctrl.obres.length > 0 && $root.permisos.SISQUILLO.read" ng-show="ctrl.showObras"
      id="tcqihome--obres-list" class="tcqi-table mdl-cell--12-col">

    <!-- Cabecera -->
    <div ng-if="ctrl.listMode" class="tcqi--table-header mdl-grid mdl-grid--no-spacing tcqi--padding-bottom-5">

      <div></div>

      <div class="mdl-cell--1-col">
        <a ng-click="ctrl.sortType = 'codi'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_CODI' | translate}}</span>

          <i ng-show="ctrl.sortType === 'codi' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'codi' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'codi'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--3-col">
        <a ng-click="ctrl.sortType = 'descripcio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          <span>{{::'DEFAULT_TITOL' | translate}}</span>

          <i ng-show="ctrl.sortType === 'descripcio' && !ctrl.sortReverse" class="icon-caret-down"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-down tcqi-two-icon-carets"></i>

          <i ng-show="ctrl.sortType === 'descripcio' && ctrl.sortReverse" class="icon-caret-up"></i>
          <i ng-show="ctrl.sortType !== 'descripcio'" class="icon-caret-up tcqi-two-icon-carets"></i>
        </a>
      </div>

      <div class="mdl-cell--1-col tcqi--center-text">
        <a ng-click="ctrl.sortType = 'dataCreacio'; ctrl.sortReverse = !ctrl.sortReverse" class="sortable">
          {{::'DEFAULT_DATA' | translate}}

          <span ng-show="ctrl.sortType === 'dataCreacio' && !ctrl.sortReverse" class="icon-caret-down"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-down tcqi-two-icon-carets"></span>

          <span ng-show="ctrl.sortType === 'dataCreacio' && ctrl.sortReverse" class="icon-caret-up"></span>
          <span ng-show="ctrl.sortType !== 'dataCreacio'" class="icon-caret-up tcqi-two-icon-carets"></span>
        </a>
      </div>
        <div class="mdl-cell--4-col tcqi--center-text"><span>{{::'DEFAULT_EMISSIONS' | translate}}</span></div>
      <div class="mdl-cell--2-col tcqi--center-text"><span>{{::'DEFAULT_COMPENSAR' | translate}}</span></div>
    
    </div>


    <!-- Ítems. Formato estándar. -->
    <div ng-if="ctrl.listMode" id="tcqihome--obres-list-tbody"
         class="tcqi--table-tbody tcqi--margin-top-10 tcqi--with-scroll"
         ng-class="{'tcqi--not-recalculate-height': ctrl.obrescompartides === null}"
         itec-infinite-scroll="ctrl.loadSisquillos(true)" threshold="100" can-load="ctrl.canLoad">


      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText |
                      orderBy:ctrl.sortType:ctrl.sortReverse track by $index"
           class="mdl-grid mdl-grid--no-spacing " ng-class="{'tcqihome--subobres-expand': obra.isSubObraExpand}"
           itec-on-finish-render="ngRepeatFinished" >
            <div ng-include="'compensacions/obra.tpl.html'" class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres "></div>
            <div ng-repeat="obra in obra.subSisquillos"
                 class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres " itec-on-finish-render="ngRepeatFinished" ng-show="obra.visible"
                  ng-include="'compensacions/obra.tpl.html'">
            </div>
      </div>
    </div>

    <!-- Ítems. Formato cuadrícula -->
    <div ng-if="!ctrl.listMode" class="mdl-grid mdl-grid--no-spacing">
      <div ng-repeat="obra in ctrl.obres | obraByCodiAndDescripcio:ctrl.searchText track by $index"
          class="mdl-cell mdl-cell--3-col tcqihome-gallery-container">

        <div class="tcqihome-gallery-element">
          <div class="tcqihome-gallery-imatge">
            <span class="tcqihome-gallery-imatge-helper"></span><img ng-if="obra.imatge"
              data-ng-src="data:image/png;base64,{{obra.imatge.data}}" ng-click="ctrl.viewDades(obra.id)">

            <img ng-if="!obra.imatge" class="ng-thumb"
                 ng-src="{{$root.prefixItecGuicomponents}}images/common-controls/views/images/icoimg.gif"
                 ng-click="ctrl.viewDades(obra.id)">
          </div>

          <div><span>{{obra.descripcio}}</span></div>
        </div>
      </div>
    </div>
  </div>



  <!-- Obras compartidas -->
  <div ng-if="(ctrl.entitats.length > 0) && $root.permisos.SISQUILLO.read"
    ng-show="ctrl.showObras"
      id="tcqihome--obrescompartides" class="tcqi-table mdl-cell--12-col">


    <!-- Ítems. Formato estándar. -->
    <div class="tcqihome--obres-title tcqi--margin-left-30 tcqi--margin-bottom-40">

      <i class="material-icons itec-float-left">folder</i>
      <span class="bold">{{::'OBRES_COMPARTIDES' | translate}}</span>
       <img class="co2-image" ng-src="images/resources/images/co2.png">

      <!-- Entidades -->
      <div ng-if="ctrl.entitats.length > 0 && $root.permisos.SISQUILLO.read" id="tcqihome--entitats"
          class="itec-float-right tcqi--margin-right-30">
        <form>
          <itec-select
              name="entitat"
              label="{{::'DEFAULT_ENTITAT' | translate}}"
              list="ctrl.entitats"
              property-label="nom"
              ng-model="ctrl.entitatSelected"
              property-id="id"
              ng-change-function="ctrl.showObresByEntitat(true)"
              max-chars="25">
          </itec-select>
        </form>
      </div>
    </div>


    <div id="tcqihome--obrescompartides-list">

      <!-- Cabecera -->
      <div ng-if="ctrl.listMode && ctrl.obres.length > 0" class="tcqi--table-header mdl-grid mdl-grid--no-spacing
          tcqi--margin-bottom-10 tcqi--padding-bottom-5 tcqi--all-width">

        <div class="tcqi--center-text"></div>

        <div class="mdl-cell--1-col">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'codi';
                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">

            <span>{{::'DEFAULT_CODI' | translate}}</span>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'codi' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'codi'" class="icon-caret-down tcqi-two-icon-carets"></i>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'codi' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'codi'" class="icon-caret-up tcqi-two-icon-carets"></i>
          </a>
        </div>

        <div class="mdl-cell--3-col">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'descripcio';
              ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">

            <span>{{::'DEFAULT_TITOL' | translate}}</span>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'descripcio' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'descripcio'"
                class="icon-caret-down tcqi-two-icon-carets"></i>

            <i ng-show="ctrl.sortTypeObrasCompartidas === 'descripcio' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></i>
            <i ng-show="ctrl.sortTypeObrasCompartidas !== 'descripcio'"
                class="icon-caret-up tcqi-two-icon-carets"></i>
          </a>
        </div>

        <div class="mdl-cell--1-col tcqi--center-text">
          <a ng-click="ctrl.sortTypeObrasCompartidas = 'dataCreacio';
                ctrl.sortReverseObrasCompartidas = !ctrl.sortReverseObrasCompartidas" class="sortable">
            {{::'DEFAULT_DATA' | translate}}

            <span ng-show="ctrl.sortTypeObrasCompartidas === 'dataCreacio' && !ctrl.sortReverseObrasCompartidas"
                class="icon-caret-down"></span>
            <span ng-show="ctrl.sortTypeObrasCompartidas !== 'dataCreacio'"
                class="icon-caret-down tcqi-two-icon-carets"></span>

            <span ng-show="ctrl.sortTypeObrasCompartidas === 'dataCreacio' && ctrl.sortReverseObrasCompartidas"
                class="icon-caret-up"></span>
            <span ng-show="ctrl.sortTypeObrasCompartidas !== 'dataCreacio'"
                class="icon-caret-up tcqi-two-icon-carets"></span>
          </a>
        </div>

          <div class="mdl-cell--4-col tcqi--center-text"><span>{{::'DEFAULT_EMISSIONS' | translate}}</span></div>
        <div class="mdl-cell--2-col tcqi--center-text"><span>{{::'DEFAULT_COMPENSAR' | translate}}</span></div>
      
      </div>


      <!-- Ítems -->
      <div class="tcqi--table-tbody">
        <div ng-repeat="obra in ctrl.obrescompartides | obraByCodiAndDescripcio:ctrl.searchText |
                        orderBy:ctrl.sortTypeObrasCompartidas:ctrl.sortReverseObrasCompartidas track by $index"
             class="mdl-grid mdl-grid--no-spacing" itec-on-finish-render="ngRepeatFinished">
          <div class="mdl-cell--12-col mdl-grid mdl-grid--no-spacing tcqihome--subobres ">
              <div class="tcqi--center-text">
                <i ng-if="obra.isSisquilloCompartit" class="material-icons">person</i>
              </div>

              <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"
                  ng-click="ctrl.viewDadesCompartides(obra.id)" title="{{::obra.codi}}">
                <span>{{obra.codi}}</span>
              </div>

              <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"
                  ng-click="ctrl.viewDadesCompartides(obra.id)">
                <span>{{obra.descripcio}}</span>
              </div>

              <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-20"
                  >
                <span ng-if="obra.dataCreacio !== 'DEFAULT_NOW'" class="tcqi--padding-left-15">
                  {{obra.dataCreacio | date: 'itecDate'}}
                </span>
                <span ng-if="obra.dataCreacio === 'DEFAULT_NOW'" class="tcqi--padding-left-15">
                  {{obra.dataCreacio | translate}}
                </span>
              </div>

            <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-acciones">
              <div class="tcqi--padding-left-15 tcqi--center-text">
               
                <div style="width:12.5%">
                <button 
                    class="mdl-button mdl-js-button mdl-button--icon"
                    title="{{::'DEFAULT_FASE_PRODUCTE' | translate}}">
                    <i class="icon-gma-fase-fabricacio color-fabricacio"></i>
                </button> </br>
                <span>
                     {{obra.emisionsHome.fabricacio | number : 2}}
                </span>
                </div>
                <div style="width:12.5%">
                  <div ng-if="obra.emisionsHome.compensadesFabricacio> 0">
                   <button 
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                  </button></br>
                 {{obra.emisionsHome.compensadesFabricacio | number : 2}}
                 </div>
               </div>
                <div style="width:12.5%">
                <button 
                  class="mdl-button mdl-js-button mdl-button--icon"
                  title="{{::'DEFAULT_FASE_CONSTRUCCIO' | translate}}">
                  <i class="icon-gma-fase-construccio color-construccio"></i>
                </button> </br>
                <span>
                  {{obra.emisionsHome.construccio | number : 2}}
                </span>
                </div>
                  <div style="width:12.5%">
                    <div ng-if="obra.emisionsHome.compensadesConstruccio> 0">
                    <button 
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                    </button></br>
                    {{obra.emisionsHome.compensadesConstruccio | number : 2}}
                  </div>
                  </div>
                <div style="width:12.5%">
                <button 
                  class="mdl-button mdl-js-button mdl-button--icon"
                  title="{{::'DEFAULT_FASE_US' | translate}}">
                  <i class="icon-gma-fase-us color-us"></i>
                </button> </br>
                <span>
                 {{obra.emisionsHome.us | number : 2}}
                </span>
                </div>
                  <div style="width:12.5%">
                     <div ng-if="obra.emisionsHome.compensadesUs > 0">
                    <button 
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                    </button></br>
                    {{obra.emisionsHome.compensadesUs | number : 2}}
                    </div>
                  </div>
                <div style="width:12.5%">
                <button 
                  class="mdl-button mdl-js-button mdl-button--icon"
                  title="{{::'DEFAULT_FASE_FINAL_DE_VIDA' | translate}}">
                  <i class="icon-gma-fase-final-de-vida color-final"></i>
                </button> </br>
                <span>
                {{obra.emisionsHome.finalDeVida | number : 2}}
                </span>
                </div>  
                  <div style="width:12.5%">
                    <div ng-if="obra.emisionsHome.compensadesFinalDeVida > 0">
                    <button 
                      class="mdl-button mdl-js-button mdl-button--icon">
                      <i class="icon-IconeLogoClimatetrade color-climatetrade"></i>
                    </button></br>
                    {{obra.emisionsHome.compensadesFinalDeVida | number : 2}}
                    </div>
                  </div>
              </div>
            </div>

     
              <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-apps">
                <div class="tcqi--padding-left-15 tcqi--center-text">



                <!-- mediambient -->
                <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"
                        ng-class="{
                        'tcqi-gma-color-permisos-nodades':ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,
                        'tcqi-gma-color-no-permisos-dades':(!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) || !$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces)
                        && obra.temediambient,'tcqihome--hidden':obra.idpare!==null}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,
                        ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)
                        && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,true)"
                        ng-disabled="!ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT)
                        || !$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces">
                  <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,ctrl.hasaccessobraapp(obra,ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT) && $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>
                </button>
                </div>
              </div>

         
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
