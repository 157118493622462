
         <div class="mdl-cell--1-col tcqi--ellipsis tcqihome--expand-subsiquillos"
                              ng-class="{'tcqi--text-align-right': obra.isSubobra}">

            <i ng-if="obra.isSisquilloCompartit" class="material-icons"
             ng-class="{'tcqihome--subobra-ident': obra.isSubobra, 'tcqihome--subobres':obra.isSubobra}">person</i>

             <div ng-if="ctrl.tesubsisquillos(obra)" class="tcqi--text-align-right tcqihome--obres-table-tbody-td-subobres">
                 <a ng-if="!obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(true,obra);">
                   <i class="icon-gui-arrow-closed tcqi--text-align-right"></i>
                 </a>
                 <a  ng-if="obra.isSubObraExpand" ng-click="ctrl.setSubObraExpand(false,obra);">
                   <i class="icon-gui-arrow-open tcqi--text-align-right"></i>
                 </a>
              </div>
        </div>

        <div class="mdl-cell--1-col bold tcqihome--obres-table-tbody-td-codigo tcqi--ellipsis"
            ng-click="ctrl.viewDades(obra.id)" title="{{::obra.codi}}">
          <span  ng-class="{'tcqihome--subobra-ident': obra.isSubobra}" >{{obra.codi}}</span>
        </div>
        <div class="mdl-cell--3-col tcqi--ellipsis" title="{{::obra.descripcio}}"
            ng-click="ctrl.viewDades(obra.id)">
          <span ng-class="{'tcqihome--subobra-ident': obra.isSubobra}">{{obra.descripcio}}</span>
        </div>

          <div class="mdl-cell--1-col tcqihome--obres-table-tbody-td-fecha tcqi--padding-left-30"
              ng-click="ctrl.viewDades(obra.id)">
            <span ng-if="obra.dataCreacio !== 'DEFAULT_NOW'">
              {{obra.dataCreacio | date: 'itecDate'}}
            </span>

            <span ng-if="obra.dataCreacio === 'DEFAULT_NOW'">
              {{obra.dataCreacio | translate}}
            </span>
          </div>

          <div class="mdl-cell--4-col tcqihome--obres-table-tbody-td-apps">
            <div class="tcqi--padding-left-15 tcqi--center-text">
                <!-- banc -->
                <button ng-if ="false" class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ban-color"
                        ng-class="{'tcqi-ban-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && !obra.tebanc,
                        'tcqi-ban-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces && obra.tebanc,
                         'tcqihome--hidden':obra.isSubobra}"
                        ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_BANC,
                          $root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces,false)"
                        ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_BANC}}].acces">
                  <i class="icon-tcqi-icona-ban" title="{{ctrl.titlemodul(obra.tebanc,$root.acces[ctrl.ApiLayoutConstant.APP_ID_BANC].acces)}}" ></i>
                </button>
              <!-- </div> -->

              <!-- pressupost -->
              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pre-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PRESSUPOST,
                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces,false)"
                      ng-class="{'tcqi-pre-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && !obra.tepressupost,
                      'tcqi-pre-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces && obra.tepressupost}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST}}].acces">
                <i class="icon-tcqi-icona-pre" title="{{ctrl.titlemodul(obra.tepressupost,$root.acces[ctrl.ApiLayoutConstant.APP_ID_PRESSUPOST].acces)}}"></i>
              </button>

              <!-- certificacio -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-seg-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGUIMENTECONOMIC,
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces,false)"
                        ng-class="{'tcqi-seg-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && !obra.teSeguiment,
                        'tcqi-seg-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces && obra.teSeguiment,
                        'tcqihome--hidden': obra.isSubobra && obra.tipusSubSisquillo===2}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC}}].acces">
                <i class="icon-tcqi-icona-cer-1" title="{{ctrl.titlemodul(obra.teSeguiment,$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGUIMENTECONOMIC].acces)}}"></i>
              </button>

              <!-- planificacio -->
              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-pla-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_PLANIFICACIO,
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces,false)"
                      ng-class="{'tcqi-pla-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && !obra.tepladetreballs,
                      'tcqi-pla-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces && obra.tepladetreballs,
                     'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO}}].acces">
                <i class="icon-tcqi-icona-pla" title="{{ctrl.titlemodul(obra.tepladetreballs,$root.acces[ctrl.ApiLayoutConstant.APP_ID_PLANIFICACIO].acces)}}"></i>
              </button>

              <!-- qualitat -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gcq-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_QUALITAT,
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces,false)"
                      ng-class="{'tcqi-gcq-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && !obra.tequalitat,
                      'tcqi-gcq-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_QUALITAT}}].acces && obra.tequalitat,
                     'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces">
                <i class="icon-tcqi-icona-gcq"  title="{{ctrl.titlemodul(obra.tequalitat,$root.acces[ctrl.ApiLayoutConstant.APP_ID_QUALITAT].acces)}}"></i>
              </button>

              <!-- mediambient -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-gma-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_MEDIAMBIENT,
                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces,false)"
                      ng-class="{'tcqi-gma-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && !obra.temediambient,
                      'tcqi-gma-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces && obra.temediambient,
                      'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT}}].acces">
                <i class="icon-tcqi-icona-gma" title="{{ctrl.titlemodul(obra.temediambient,$root.acces[ctrl.ApiLayoutConstant.APP_ID_MEDIAMBIENT].acces)}}"></i>
              </button>

              <!-- seguretat i salut -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-ess-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_SEGURETATISALUT,
                      $root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces,false)"
                      ng-class="{'tcqi-ess-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && !obra.teseguretatisalut,
                      'tcqi-ess-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces && obra.teseguretatisalut,
                      'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT}}].acces">
                <i class="icon-tcqi-icona-ess" title="{{ctrl.titlemodul(obra.teseguretatisalut,$root.acces[ctrl.ApiLayoutConstant.APP_ID_SEGURETATISALUT].acces)}}"></i>
              </button>

              <!-- control costos -->
              <button class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-cdc-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_CONTROLCOSTOS,
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces,false)"
                      ng-class="{'tcqi-cdc-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && !obra.tecontrolcostos,
                      'tcqi-cdc-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces && obra.tecontrolcostos,
                      'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS}}].acces">
                <i class="icon-tcqi-icona-gcc" title="{{ctrl.titlemodul(obra.tecontrolcostos,$root.acces[ctrl.ApiLayoutConstant.APP_ID_CONTROLCOSTOS].acces)}}"></i>
              </button>

              <!-- estimaciocostos -->
              <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-edc-color"
                      ng-click="ctrl.openObra(obra.id, ctrl.ApiLayoutConstant.APP_NOM_ESTIMACIOCOSTOS,
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces,false)"
                      ng-class="{'tcqi-edc-color-permisos-nodades':$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && !obra.teestimaciocostos,
                      'tcqi-edc-color-no-permisos-dades':!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces && obra.teestimaciocostos,
                      'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS}}].acces">
                <i class="icon-tcqi-icona-edc"  title="{{ctrl.titlemodul(obra.teestimaciocostos,$root.acces[ctrl.ApiLayoutConstant.APP_ID_ESTIMACIOCOSTOS].acces)}}"></i>
              </button>

              <!-- Mantenim Circular -->
               <button  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqi-circular-color"
                      ng-click="ctrl.openObra(obra.id, 'circular',
                        $root.acces[ctrl.ApiLayoutConstant.APP_ID_CIRCULAR].acces,false)"
                      ng-class="{'tcqihome--hidden':obra.isSubobra}"
                      ng-disabled="!$root.acces[{{ctrl.ApiLayoutConstant.APP_ID_CIRCULAR}}].acces">
                <i class="icon-circular"  title="{{ctrl.titlemodulcircular($root.acces[ctrl.ApiLayoutConstant.APP_ID_CIRCULAR].acces)}}"></i>
              </button>
             </div>
          </div>

          <div class="mdl-cell--2-col tcqihome--obres-table-tbody-td-acciones">
            <div class="tcqi--padding-left-15 tcqi--center-text">

              <button
                  ng-disabled="!$root.permisos.SISQUILLO.edit"
                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                  title="{{::'DEFAULT_DADES_GENERALS' | translate}}"
                  ng-click="ctrl.viewDades(obra.id)">
                <i class="material-icons">description</i>
              </button>

              <button ng-disabled="!$root.permisos.SISQUILLO.delete"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                      title="{{::'DEFAULT_ESBORRAR_OBRA' | translate}}"
                      itec-dialog-confirm
                      itec-dialog-confirm-title="OBRES_DELETE_TITLE"
                      itec-dialog-confirm-text-content="OBRES_DELETE_CONTENT"
                      itec-dialog-confirm-text-ok="DEFAULT_ESBORRAR"
                      itec-dialog-confirm-text-cancel="DEFAULT_CANCELAR"
                      itec-dialog-confirm-function-ok="ctrl.esborrar(obra)">
                <i class="material-icons">delete_forever</i>
              </button>
              <button
                  ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"
                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                  title="{{::'DEFAULT_ACCIONS_COPIAR' | translate}}"
                  ng-click="ctrl.copiarObra(obra.id)">
                <i class="material-icons">file_copy</i>
              </button>

              <tcq-bim-form-upload
                ng-if="$root.permisos.SISQUILLO_CARREGAR_BIM.read"
                is-new = "false"
                obra-value = "obra"
                dades-generals="ctrl.dadesGeneralsData"
                save-files-text="SHARED_DOCUMENT_UPLOAD_SAVE_FILES"
                max-size-exceeded-text="SHARED_DOCUMENT_UPLOAD_MAX_SIZE_EXCEEDED"
                max-size="10MB"
                show-only-icon="true"
                on-finish-function="ctrl.viewDades"
                server-path="{{ctrl.ApiLayoutConstant.SERVER_PATH_LOADER}}"
                server-version="{{ctrl.ApiLayoutConstant.SERVER_VERSION_TCQIHOME}}"
                class="itec-float-right"
                icon-left-class="icon-home-importar-bim"
                title="{{'BIM_ARXIUS_ADD_TITLE' | translate}}"
                show-toolbar-dialog="true">
              </tcq-bim-form-upload>

              <button ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_TCQI.read"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                      title="{{::'DEFAULT_EXPORTAR' | translate}}"
                      ng-click="ctrl.exportarObra(obra.id)">
                <i class="icon-home-export-tcqi"></i>
              </button>

              <button ng-disabled="!$root.permisos.SISQUILLO_EXPORTAR_FIE.read"
                      id="fie"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                      title="{{::'DEFAULT_EXPORTAR_FIE' | translate}}"
                      ng-click="ctrl.exportarObraFIE(obra.id)">
                 <i class="icon-home-export-fie"></i>
              </button>

              <button
                  ng-disabled="!$root.permisos.OBRES_COMPARTIR.read"
                  class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored tcqihome--share-button"
                  title="{{::'DEFAULT_COMPARTIR_OBRA' | translate}}"
                  itec-dialog
                  itec-dialog-title="{{::'DEFAULT_COMPARTIR_OBRA' | translate}} ''{{obra.codi}}''"
                  itec-dialog-template-url="obres/obres-compartir.tpl.html"
                  itec-dialog-controller="ctrl"
                  itec-dialog-click-outside-to-close="false"
                  itec-dialog-function-init="ctrl.showDialogCompartirObra(obra.id,false)">
                <!-- <i class="icon-gma-formacio-ozo-aire-1X"></i> -->
                <i class="material-icons">share</i>
              </button>
              
              <!-- TCQi Cities: vincular Obres a projectes TCQi Cities -->
              <button 
                      ng-if="$root.acces[ctrl.ApiLayoutConstant.APP_ID_POCITYF].acces"
                      class="mdl-button mdl-js-button mdl-button--icon mdl-button--colored"
                      ng-class="{'tcqi-cities-no-te-projectes-vinculats': !obra.teProjectesTCQiCities}"
                      title="{{(obra.teProjectesTCQiCities ? 'DEFAULT_TE_PROJECTES_TCQICITIES'  : 'DEFAULT_NO_TE_PROJECTES_TCQICITIES') | translate}}"
                      ng-click="ctrl.vincularObraTCQiCities(obra)">
                 <i class="icon-IconCity2 icon-cities-size"></i>
              </button>

            </div>
          </div>
