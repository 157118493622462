(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name itecTcqihome.api.entitat.factory:EntitatFactory
   *
   * @description
   *
   */
  angular
    .module('itecTcqihome.api.entitat')
    .factory('EntitatFactory', EntitatFactory);

  function EntitatFactory(ApiLayoutConstant, DataFactory, $cookies, $http) {
    var api = ApiLayoutConstant.SERVER_PATH_TCQIHOME + 'entitats';

    var EntitatBase = {
      getActual: function() {
        var url = api + '/current';
        return DataFactory.get(url);
      },

      getAll: function() {
        var url = api + '/all_entitats';
        return DataFactory.get(url);
      },

      getEstadistiques: function() {
        var url = api + '/estadistiques_entitat';
        return DataFactory.get(url);
      }
      

    };

    return EntitatBase;
  }
}());
