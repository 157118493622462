(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name itecTcqihome.shared.tcqObraFormUpload.directive:tcqObraFormUpload
   * @restrict EA
   *
   */
  angular
    .module('itecTcqihome.shared.tcqObraFormUpload')
    .directive('tcqObraFormUpload', tcqObraFormUpload);

  function tcqObraFormUpload($cookies) {
    return {
      restrict: 'EA',
      scope: {
        chunkSize: '@?',
        selectFilesText: '@?',
        saveFilesText: '@',
        maxSize: '@?',
        maxSizeExceededText: '@',
        idTypeDocument: '=',
        listCategories: '=',
        onFinishFunction: '&?',
        serverPath: '@',
        serverVersion: '@'
      },
      templateUrl: 'shared/tcq-obra-form-upload/tcq-obra-form-upload-directive.tpl.html',
      replace: false,
      controllerAs: 'vm',
      controller: function ($scope, $rootScope, Notification, HelperFactory,
        UploadFactory, UploadFileConstants, $mdDialog, $document, DataFactory,
        SisquilloFactory, $timeout) {
        var vm = this;
        vm.name = 'tcqObraFormUpload';

        vm.uploadFile       = uploadFile;
        vm.selectFile       = selectFile;
        vm.cleanForm 		    = cleanForm;

        vm.size = 'min';
        vm.files = [];
        vm.errFiles = [];
        vm.errors = [];

        vm.chunkSize = angular.isUndefined($scope.chunkSize) ? UploadFileConstants.CHUNKSIZE : $scope.chunkSize;
        vm.maxSize = angular.isUndefined($scope.maxSize) ? UploadFileConstants.MAX_SIZE_UPLOAD_DEFAULT : $scope.maxSize;

        vm.onFinishFunction = $scope.onFinishFunction;
        vm.codiObra = '';
        vm.descripcioObra = '';
        vm.mostrarArbolTipologias = false;

        vm.fileName = '';
        vm.fileNameExtension = '';

        vm.iconLeft = {};
        vm.iconLeft.class = 'material-icons';
        vm.iconLeft.content = 'cloud_download';


        vm.tipologiaDialogController = TipologiaDialogController;
        vm.obra = {};
        vm.obra.tipologia = {nom : '', id : 0};

        vm.tipologies = [];
        vm.loadTipologies = loadTipologies;
        //vm.loadTipologies();


        /////////////////////////////////////////////////////
        //----------------- functions ---------------------//
        /////////////////////////////////////////////////////

        function selectFile() {
          //solo deberia haber un elemento en el array
          $mdDialog.show({
            controller: function DialogController($q, $http, ApiLayoutConstant,
              $rootScope, $scope, $mdDialog, parametres, SisquilloFactory,
              Notification, $translate, DataFactory) {
              var vm = this;
              vm.errors = [];
              vm.object = {};

              vm.backButton = {};
              vm.backButton.class = 'material-icons tcqi--button-icon-left';
              vm.backButton.content = 'arrow_back';

              vm.codi = parametres.codiObra;
              vm.descripcio = parametres.descripcioObra;
              vm.tipologies= parametres.tipologies;
              vm.schemaVersionScript = parametres.schemaVersionScript;
              vm.sisId = parametres.sisId;
              vm.obra = parametres.obra;

              /*
                Variable que se utiliza para alternar entre el formulario y el
                arbol de tipologias.
              */
              vm.mostrarOriginal = true;

              /*
                Variable que permite que se pueda visualizar el input y el botón
                de las tipologías.
              */
              vm.mostrarArbolTipologias = parametres.mostrarArbolTipologias;

              vm.cancelFunction = function() {
                vm.mostrarOriginal = true;
              }

              vm.confirmFunction = function(node) {
                vm.mostrarOriginal = true;
                vm.obra.tipologia = node;
              };

              $scope.accept = function() {
                $rootScope.$broadcast('$activateLoader');
                vm.object.idFile = {};
                vm.object.idFile.name = parametres.fileName;
                vm.object.idFile.extension = parametres.fileNameExtension;

                vm.object.codi = vm.codi;
                vm.object.descripcio = vm.descripcio;
                vm.object.idTipologia = vm.obra.tipologia.id;
                vm.object.schemaVersionScript = vm.schemaVersionScript;
                vm.object.sisId = vm.sisId;

                var url = parametres.serverPath + 'upload/extractFile';
                DataFactory.add(url, vm.object).then(function(response) {
                  if (angular.isDefined(parametres.onFinishFunction)) {
                      parametres.onFinishFunction()(response.data.sisId);
                  }
                }).catch(function(error){
                  onErrorUpload(vm.object, error);
                }).finally(function() {
                  $rootScope.$broadcast('$deactivateLoader');
                });
                $mdDialog.hide();
              };

              $scope.hide = function() {
                parametres.cleanForm();
                $mdDialog.cancel();
              };

              $scope.cancel = function() {
                parametres.cleanForm();
                $mdDialog.cancel();
              };

              vm.selectTipologia = function() {
                vm.mostrarOriginal = false;
              }

            },
            controllerAs: 'ctrl',
            templateUrl: 'obres/importar-obra/importar-obra-dialog.tpl.html',
            parent: angular.element($document.body),
            clickOutsideToClose:true,
            locals: {'parametres': {cleanForm:vm.cleanForm, codiObra:vm.codiObra, schemaVersionScript: vm.schemaVersionScript, sisId:vm.sisId,
                                    obra:vm.obra, tipologies:vm.tipologies,
                                    descripcioObra:vm.descripcioObra,
                                    mostrarArbolTipologias:vm.mostrarArbolTipologias,
                                    fileName:vm.fileName,
                                    fileNameExtension:vm.fileNameExtension,
                                    serverPath:$scope.serverPath,
                                    onFinishFunction:vm.onFinishFunction}}
          });
        }

        function loadTipologies() {
           SisquilloFactory.getTipologies().then(function(response) {
             vm.tipologies = response.data;
           }).catch(function(response){});
        }

        function TipologiaDialogController(obra, tipologies) {
            var ctrl = this;
            ctrl.obra = obra;
            ctrl.tipologies = [];
            ctrl.expanded = [];
        };

        function cleanForm() {
          vm.objFile = {};
          vm.files = [];
          vm.errFiles = [];
          vm.errors = [];
        }

        function onFinishUpload(objFile, response) {
          if (UploadFactory.checkFinalUpload(vm.files)) {
            cleanForm();
            if(response !=null && !angular.isUndefined(response) && response.data !=null && !angular.isUndefined(response.data) && response.data.data !=null && !angular.isUndefined(response.data.data)){
              vm.codiObra = response.data.data.codi;
              vm.descripcioObra = response.data.data.descripcio;
              vm.schemaVersionScript = response.data.data.schemaVersionScript;
              vm.sisId = response.data.data.sisId;
              vm.mostrarArbolTipologias = response.data.data.mostrarArbolTipologias;
              vm.fileName =  response.data.data.idFile.name;
              vm.fileNameExtension = response.data.data.idFile.extension;
            }
          }
          vm.selectFile();
        }

        function normalizeError(error) {
          //24931 degut a multiples causes i routes de l'error s'ha observat que poden arribar errors diferents, per exemple itec-api envia el .data del error, mentre que uploadFactory envia un nivell superior. Aqui identifiquem els diferents tipus per retornar sempre el mateix nivell d'anidament de l'error.
          return (error && error.config)? error.data : error;
        }

        function onErrorUpload(objFile, error) {
          error = normalizeError(error);
          Notification.processMessage(error);
          vm.errors = Notification.processError(error);
          console.log('Error durant la importació.');
          $rootScope.$broadcast('$deactivateLoader');
          vm.files = [];
        }

        function uploadFile() {
          SisquilloFactory.getTipologies().then(function(response) {
            vm.tipologies = response.data;
            vm.errors = [];
            if (vm.files.length > 0) {
              var file = vm.files[0];
              if (angular.isDefined(file) && file !== null) {

                $rootScope.$broadcast('$activateLoader');

                vm.objFile = file;
                vm.objFile.fields = {newFileId:HelperFactory.uniqueMoment()};
                vm.objFile.chunkSize = vm.chunkSize;
                vm.objFile.sizeUrl = 'ok.html';
                vm.objFile.baseUrl = '/api/' + $scope.serverPath;
                vm.objFile.controllerUrl = 'upload';
                vm.objFile.headers = { 'authsecurity' : $cookies.get('authsecurity'), 'x-api-version' : $scope.serverVersion };

                return UploadFactory.uploadFile(vm.objFile, onFinishUpload, onErrorUpload).then(function(response) {
                  //nothing
                  console.log("You shouldn't be seeing this");
                }).catch(function(error) {
                  onErrorUpload(vm.objFile, error);
                }).finally(function() {
                  $rootScope.$broadcast('$deactivateLoader');
                });

              }
            }
          }).catch(function(response){});
        }

      },
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint 'no-unused-vars': [2, {'args': 'none'}] */
      }
    };
  }
}());
