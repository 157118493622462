<div class="arbol-tipologias-scroll">
  <div class="mdl-cell mdl-cell--12-col mdl-grid mdl-grid--no-spacing">
    <itec-tree
      items="vm.tipologies"
      item-name="node"
      expanded-collection="vm.expanded"
      children="node.children"
      has-children="node.hasChildren"
      show-button="true"
      order-by="nom">
      <itec-checkbox
        ng-disabled="false"
        ng-model="node.checked"
        ng-click="vm.changeCheck(node)">
      </itec-checkbox>
      {{node.nom}}
    </itec-tree>
  </div>
</div>
<div class="arbol-tipologias-buttons mdl-cell mdl-cell--12-col tcqi--margin-top-20">
  <itec-button
    label="{{'DEFAULT_TORNAR' | translate}}"
    ng-click-function="vm.cancelFunction()"
    icon-left="vm.backButton">
  </itec-button>
  <itec-button
    label="{{'DIALOG_CONFIRM' | translate}}"
    ng-click-function="vm.guardarCanvisTipologia()">
  </itec-button>
</div>
